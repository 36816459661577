import { route } from '@md/routing';
import { applications } from '@md/bids/api.js';
import { receiveRoute } from '@sections/cabinet/components/views/utils';

const List = () => import('./views/List');
const Edit = () => import('./views/Edit');

const APPLICATIONS_PREFIX = 'representative:applications:';
const p = name => APPLICATIONS_PREFIX + name;
export const REPRESENTATIVE_APPLICATIONS_LIST = p('list');
export const REPRESENTATIVE_APPLICATIONS_EDIT = p('edit');
export const REPRESENTATIVE_APPLICATIONS_CREATE = p('create');

export function createRoutes() {
  return [
    route('list', List, REPRESENTATIVE_APPLICATIONS_LIST),
    receiveRoute(applications.retrieve, [
      route('edit/', Edit, REPRESENTATIVE_APPLICATIONS_EDIT),
      { path: '', redirect: { name: REPRESENTATIVE_APPLICATIONS_EDIT } },
    ]),
    { path: '', redirect: { name: REPRESENTATIVE_APPLICATIONS_LIST } },
  ];
}

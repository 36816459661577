import { route } from '@md/routing';
import { booking } from '@md/bids/api.js';
import { receiveRoute } from '@sections/cabinet/components/views/utils.js';

const List = () => import('./views/List');
const Create = () => import('./views/Create.vue');
const Edit = () => import('./views/Edit.vue');

const BOOKING_PREFIX = 'agent:booking:';
const p = name => BOOKING_PREFIX + name;

export const AGENT_BOOKING_LIST = p('list');
export const AGENT_BOOKING_CREATE = p('create');
export const AGENT_BOOKING_EDIT = p('edit');

export function createRoutes() {
  return [
    route('list/', List, AGENT_BOOKING_LIST, {}),
    route('create/', Create, AGENT_BOOKING_CREATE),
    receiveRoute(booking.retrieve, [
      route('edit/', Edit, AGENT_BOOKING_EDIT),
      { path: '', redirect: { name: AGENT_BOOKING_EDIT } },
    ]),

    { path: '', redirect: { name: AGENT_BOOKING_LIST } },

  ];
}

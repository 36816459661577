import { prefixRoutes } from '@md/routing.js';

import { createRoutes as createChatsRoutes } from './modules/chats/router.js';
import { createRoutes as createStatisticsRoutes } from './modules/statistics/router.js';
import { createRoutes as createTransactionsRoutes } from './modules/transactions/router.js';
import { createRoutes as createNotificationsRoutes } from './modules/notifications/router.js';

import { STAFF_CHATS } from './routes.js';

const Route404 = () => import('@sections/404.vue');

export function createRoutes(options) {
  return [
    prefixRoutes('chats/', createChatsRoutes(options)),
    prefixRoutes('statistics/', createStatisticsRoutes(options)),
    prefixRoutes('transactions/', createTransactionsRoutes(options)),
    prefixRoutes('notifications/', createNotificationsRoutes(options)),
    { path: '', redirect: { name: STAFF_CHATS } },

    { path: '*', component: Route404 },
  ];
}

import { route } from '@md/routing';

const List = () => import('./views/List');

export const HOTELIER_HOTEL_LOYALTY = 'hotelier:hotels:bonus:loyalty:list';

export function createRoutes() {
  return [
    route('list/', List, HOTELIER_HOTEL_LOYALTY),
    // { path: '/', redirect: { name: HOTELIER_HOTEL_LOYALTY } },
  ];
}

const messageGetter = x => x.message;

export function defaultValidatorErrorsParser(errors, initKey, accumulator = {}) {
  return Object.keys(errors).reduce((acc, x) => {
    const key = initKey ? `${initKey}:${x}` : x;
    if (Array.isArray(errors[x])) {
      if (Object.prototype.hasOwnProperty.call(errors[x][0], 'message')) {
        acc[key] = errors[x].map(messageGetter);
        if (errors[x][0].reason && 'confirm_email' === errors[x][0].reason) {
          acc[key].push(errors[x][0].reason);
        }

        return acc;
      }
      for (let i = 0, n = errors[x].length; i < n; i++) {
        defaultValidatorErrorsParser(errors[x][i], `${key}:${i}`, acc);
      }
      return acc;
    }
    if ('object' !== typeof errors[x]) return acc;
    return defaultValidatorErrorsParser(errors[x], key, acc);
  }, accumulator);
}

export const useScrollToFirstError = (errors, validator) => {
  console.log('scroll');
  let first;
  Object.entries(errors).some(([key, value]) => {
    if (value && value.length) first = key;
    return value && value.length;
  });
  if (
    first &&
      validator.refs[first] &&
      validator.refs[first].$el &&
      validator.refs[first].$el.scrollIntoView
  ) {
    validator.refs[first].$el.scrollIntoView({ behavior: 'smooth' });
    validator.refs[first].$el.scrollTop -= 10;
  }
};
export const useUpdateValidator = (errors, validator, isScroll) => {
  const parsed = defaultValidatorErrorsParser(errors);
  validator.setErrors(parsed);
  if (isScroll) {
    useScrollToFirstError(errors, validator);
  }
};

export const useCatchFormErrors = (promise, validator, scrollMe) => promise.catch(e => {
  const clientError = 400;
  const serverError = 500;
  if (!e.status || clientError > e.status || serverError <= e.status) {
    throw e;
  }

  if (!useUpdateValidator) {
    return e;
  }

  return e.json().then(body => {
    const errors = {};

    body.errors.forEach(error => {
      if ('request' === error.domain && error.state) {
        if (403 === e.status) {
          Object.assign(errors, { forbidden: [error.state] });
        } else {
          Object.assign(errors, error.state);
        }
      }
    });

    useUpdateValidator(errors, validator, scrollMe);

    return e;
  });
});

export const useSubmit = ({ form: data, loading, send, validator, isRequiredScrollToError: scrollMe }) => {
  loading.value = true;
  return useCatchFormErrors(send(data), validator, scrollMe)
    .finally(() => {
      // eslint-disable-next-line no-unused-vars
      loading.value = false;
    });
};

export default {
  data() {
    return {
      data: {},
      isRequiredScrollToError: true,
      resetErrorOnBlur: true,
      loading: false,
      nonFieldErrors: '',
    };
  },
  mounted() {
    // if (this.resetErrorOnBlur && this.$refs.validator && this.$refs.validator.$el) {
    //   this.$refs.validator.$el.addEventListener('blur', () => {
    //     if (this.$refs.validator) this.$refs.validator.reset();
    //     this.nonFieldErrors = '';
    //   }, true);
    // }
  },
  methods: {
    catchFormErrors(promise) {
      return promise.catch(e => {
        const clientError = 400;
        const serverError = 500;
        if (!e.status || clientError > e.status || serverError <= e.status) {
          throw e;
        }

        if (!this.updateValidator) {
          return e;
        }

        return e.json().then(body => {
          const errors = {};

          body.errors.forEach(error => {
            if ('request' === error.domain && error.state) {
              if (403 === e.status) {
                Object.assign(errors, { forbidden: [error.state] });
              } else {
                Object.assign(errors, error.state);
              }
            }
          });

          this.updateValidator(errors);

          return e;
        });
      });
    },
    submit(data) {
      this.loading = true;
      return this.catchFormErrors(this.send(data))
        .finally(() => {
          this.loading = false;
        });
    },
    scrollToFirstError(errors) {
      let first;
      Object.entries(errors).some(([key, value]) => {
        if (value && value.length) first = key;
        return value && value.length;
      });
      // eslint-disable-next-line no-unused-expressions
      if (
        first &&
        this.$refs.validator.refs[first] &&
        this.$refs.validator.refs[first].$el &&
        this.$refs.validator.refs[first].$el.scrollIntoView
      ) {
        this.$refs.validator.refs[first].$el.scrollIntoView({ behavior: 'smooth' });
        this.$refs.validator.refs[first].$el.scrollTop -= 10;
      }
    },
    async updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors);
      this.nonFieldErrors = parsed.nonFieldErrors;
      this.$refs.validator.setErrors(parsed);
      if (this.isRequiredScrollToError) {
        this.scrollToFirstError(errors);
      }
    },
  },
};

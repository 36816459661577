import { route } from '@md/routing';
import { booking } from '@md/bids/api.js';
import { receiveRoute } from '@sections/cabinet/components/views/utils.js';

const List = () => import('./views/List');
const Edit = () => import('./views/Edit.vue');

const BOOKING_PREFIX = 'dmc:booking:';
const p = name => BOOKING_PREFIX + name;

export const DMC_BOOKING_LIST = p('list');
export const DMC_BOOKING_EDIT = p('edit');

export function createRoutes() {
  return [
    route('list/', List, DMC_BOOKING_LIST, {}),
    receiveRoute(booking.retrieve, [
      route('edit/', Edit, DMC_BOOKING_EDIT),
      { path: '', redirect: { name: DMC_BOOKING_EDIT } },
    ]),

    { path: '', redirect: { name: DMC_BOOKING_LIST } },

  ];
}

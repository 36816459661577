import installer from '@aspectus/vue-route-installer';
import {
  route,
} from '@md/routing';
import { accessMeta } from '@aspectus/vue-router-permissions';
import {
  toBeAgent, toBeHotelier, toBeHotelierChain, toBeDMC, toBeRepresentative, toBeTouroperatorManager, toBeSuperuser,
} from '@md/users/permissions';

import { createRoutes as createAgentRoutes } from './modules/agent/router.js';
import { createRoutes as createRepresentativeRoutes } from './modules/representative/router.js';
import { createRoutes as createHotelierRoutes } from './modules/hotelier/router.js';
import { createRoutes as createHotelierChainRoutes } from './modules/hotelier_chain/router.js';
import { createRoutes as createDMCRoutes } from './modules/dmc/router.js';
import { createRoutes as createStaffRoutes } from './modules/staff/router.js';
import { createRoutes as createAdminRoutes } from './modules/admin/router.js';
import { createRoutes as createOperatorRoutes } from './modules/touroperator_manager/router.js';

// const Route404 = () => import('@sections/404.vue');
const Route403 = () => import('@sections/403.vue');
const CabinetInstaller = () => import('./components');
const Cabinet = () => import('./views/Cabinet.vue');

export function createRoutes(options) {
  const { Vue } = options;

  return [

    route('/permission-denied/', Route403, '403'),
    route('/representative/', installer(Cabinet, CabinetInstaller, Vue), null, {
      children: createRepresentativeRoutes(options),
      meta: {
        sidebar: 'representative-sidebar',
        ...accessMeta(toBeRepresentative, { path: '/403/', component: Route403 }, options),
      },
    }),
    route('/agent/', installer(Cabinet, CabinetInstaller, Vue), null, {
      children: createAgentRoutes(options),
      meta: {
        sidebar: 'agent-sidebar',
        ...accessMeta(toBeAgent, { path: '/403/', component: Route403 }, options),
      },
    }),
    route('/hotelier-chain/', installer(Cabinet, CabinetInstaller, Vue), null, {
      children: createHotelierChainRoutes(options),
      meta: {
        sidebar: 'hotelier-chain-sidebar',
        ...accessMeta(toBeHotelierChain, { path: '/403/', component: Route403 }, options),
      },
    }),
    route('/hotelier/', installer(Cabinet, CabinetInstaller, Vue), null, {
      children: createHotelierRoutes(options),
      meta: {
        sidebar: 'hotelier-sidebar',
        ...accessMeta(toBeHotelier, { path: '/403/', component: Route403 }, options),
      },
    }),
    route('/dmc/', installer(Cabinet, CabinetInstaller, Vue), null, {
      children: createDMCRoutes(options),
      meta: {
        sidebar: 'dmc-sidebar',
        ...accessMeta(toBeDMC, { path: '/403/', component: Route403 }, options),
      },
    }),
    route('/touroperator/', installer(Cabinet, CabinetInstaller, Vue), null, {
      children: createOperatorRoutes(options),
      meta: {
        sidebar: 'touroperator-sidebar',
        ...accessMeta(toBeTouroperatorManager, { path: '/403/', component: Route403 }, options),
      },
    }),
    route('/staff/', installer(Cabinet, CabinetInstaller, Vue), null, {
      children: createStaffRoutes(options),
      meta: { sidebar: 'staff-sidebar' },
    }),
    route('/adm/', installer(Cabinet, CabinetInstaller, Vue), null, {
      children: createAdminRoutes(options),
      meta: {
        sidebar: 'admin-sidebar',
        ...accessMeta(toBeSuperuser, { path: '/403/', component: Route403 }, options),
      },
    }),
  ];
}

import AuthPlugin from '@md/auth';

import STAFF_TOKEN from '@md/hotels/accessTokens.js';
import { TOKENS } from './accessTokens';
import Plugin from './components';

function install(Vue) {
  Vue.use(AuthPlugin);
  Vue.use(Plugin);

  Vue.prototype.$accessTokens = TOKENS;
  Vue.prototype.$staffAccessTokens = STAFF_TOKEN;
}

export default { install };

import { route } from '@md/routing';

const FaqDisplay = () => import('@md/faq/components/Display.vue');

export const HOTELIER_FAQ = 'hotelier:faq';

export function createRoutes() {
  return [
    route('', FaqDisplay, HOTELIER_FAQ),
  ];
}

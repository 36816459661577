import { route } from '@md/routing';

const Edit = () => import('./views/Edit');

const PROFILE_PREFIX = 'agent:profile:';
const p = name => PROFILE_PREFIX + name;
export const AGENT_PROFILE_EDIT = p('edit');

export function createRoutes() {
  return [
    route('edit/', Edit, AGENT_PROFILE_EDIT, {
    }),
    { path: '', redirect: { name: AGENT_PROFILE_EDIT } },
  ];
}

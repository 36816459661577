import { route } from '@md/routing.js';

const List = () => import('./views/List');

export const DMC_SERVICES_LIST = 'dmc:hotels:services:list';

export function createRoutes() {
  return [
    route('list/', List, DMC_SERVICES_LIST, {}),
  ];
}

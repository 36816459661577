<template lang="pug">
include /mixins
regional-referral-invite-form(
  :referral="referral",
)

</template>
<script>
const RegionalReferralForm = () => import('./Forms/RegionalReferralForm.vue');
export default {
  name: 'regional-referral-invite',
  componetns: {
    RegionalReferralForm,
  },
  props: {
    referral: {
      type: String,
      required: true,
    },
  },
};
</script>

import { prefixRoutes, route } from '@md/routing';

import { createRoutes as createProfileRoutes } from './modules/profile/router.js';
import { createRoutes as createAppealsRoutes } from './modules/appeals/router.js';
import { createRoutes as createTransactionsRoutes } from './modules/transactions/router.js';
import { createRoutes as createNotificationsRoutes } from './modules/notifications/router.js';
import { createRoutes as createFaqRoutes } from './modules/faq/router.js';
import { createRoutes as createChatsRoutes } from './modules/chats/router.js';
import { createRoutes as createReferralsRoutes } from './modules/referrals/router.js';
import { createRoutes as createFrozenPaymentsRoutes } from './modules/frozen-payments/router.js';
import { createRoutes as createApplicationRoutes } from './modules/applications/router.js';

const Home = () => import('./modules/home/View.vue');
const HotelCreate = () => import('./modules/home/HotelCreate.vue');
const HotelierCreate = () => import('./modules/hotelier/Create.vue');
const Route404 = () => import('@sections/404.vue');

export const REPRESENTATIVE_HOME = 'representative:home';
export const REPRESENTATIVE_HOTEL_CREATE = 'representative:hotel:create';
export const REPRESENTATIVE_HOTELIER_CREATE = 'representative:hotelier:create';

export function createRoutes(options) {
  return [
    route('home/', Home, REPRESENTATIVE_HOME),
    route('hotel-create/', HotelCreate, REPRESENTATIVE_HOTEL_CREATE),
    route('hotelier-create/', HotelierCreate, REPRESENTATIVE_HOTELIER_CREATE),

    prefixRoutes('profile/', createProfileRoutes(options)),
    prefixRoutes('appeals/', createAppealsRoutes(options)),
    prefixRoutes('faq/', createFaqRoutes(options)),
    prefixRoutes('chats/', createChatsRoutes(options)),
    prefixRoutes('referrals/', createReferralsRoutes()),

    prefixRoutes('transactions/', createTransactionsRoutes(options)),
    prefixRoutes('frozen-payments/', createFrozenPaymentsRoutes(options)),
    prefixRoutes('notifications/', createNotificationsRoutes(options)),
    prefixRoutes('applications/', createApplicationRoutes(options)),

    { path: '', redirect: { name: REPRESENTATIVE_HOME } },
    { path: '*', component: Route404 },
  ];
}

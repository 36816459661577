import { r, createSender, baseResource } from '@md/resources';
import { modelPrefix } from '@md/api.js';
import { prefixLanguage } from '@md/urls';

import {
  friendlyUrlGenerator,
} from '@aspectus/vue-friendly';

const MODEL = '/blog';
const MODULE = url => modelPrefix(url, MODEL);

const FILTERS_LIST_URL_POSTFIX = 'ajax/filters/';

const order = {
  path: [
    'prefix',
    'label',
    'category',
    'postfix',
    'filters',
  ],
  query: [
    'pagination_offset',
  ],
};

export const ajaxBlogList =
  friendlyUrlGenerator([prefixLanguage(MODEL), FILTERS_LIST_URL_POSTFIX], baseResource, order);

export const blog = {
  create: r(MODULE('/create/'), createSender),
  retrieve: r(MODULE('{/label}/retrieve/')),
  update: r(MODULE('{/id}/update/'), createSender).config('method', 'PATCH'),
  list: r(MODULE('/list/{?filters,order_by,limit,offset}')),
  filters: {
    public: {
      list: r(MODULE('/filters/public/list/')),
      available: r(MODULE('/filters/public/list/{?filters}')),
    },
    private: r(MODULE('/filters/private/list/{?filters}')),
  },
  delete: r(MODULE('{/id}/delete/'), createSender).config('method', 'DELETE'),

};

/* eslint-disable */
import { createHOCc } from 'vue-hoc';

import { now, comparableDate } from '../../../utils/date';
import { thresholdCheck } from '../../../utils/number';
import { StickScrollPosition } from '../../../utils/stick-scroll';
import { CursorPaginator } from '@webcase/paginator';
import LoadableMixin from '../../../utils/loadable/LoadableMixin';
import FeedEventsHandlerMixin from '../../../utils/mixins/FeedEventsHandlerMixin';
import MessagingInjectorMixin from '../../../utils/mixins/MessagingInjectorMixin';

export default createHOCc({
  mixins: [LoadableMixin, FeedEventsHandlerMixin, MessagingInjectorMixin],

  feedHandlers: [
    {
      event: 'chat:room:new',
      handler(event) {
        if (this.live) {
          this.data.data.unshift(event.body);
        }
      },
    },
  ],

  props: {
    preloadAmount: {
      type: Number,
      default: 40,
    },
    live: {
      type: Boolean,
      default: false,
    },
    bubble: {
      type: Boolean,
      default: false,
    },
    threshold: {
      type: Number,
      default: 2,
    },
    initialRoomId: {
      type: String,
      default: null,
    },
    initialParameters: {
      type: Object,
      default: () => ({}),
    },
    stopPaginate: {
      type: Boolean,
    },
  },

  watch: {
    feed: {
      immediate: true,
      handler(value) {
        if (value) {
          this.getInitialChats(this.initialParameters);
        }
      },
    },
  },

  data() {
    return {
      data: {
        data: [],
        meta: {},
      },
      scroller: new StickScrollPosition({
        direction: StickScrollPosition.CHANGED_ON_BOTTOM,
      }),
    };
  },

  computed: {
    items() {
      const chats = this.getLiveChats(this.data.data);

      if (!this.bubble) {
        return chats;
      }

      return chats.sort((a, b) => comparableDate(b.updatedAt) - comparableDate(a.updatedAt));
    },
  },

  methods: {
    getInitialChats(parameters) {
      this.pullChats({
        endCursor: now().toISOString(),
        limit: this.preloadAmount,
        ...parameters,
      }).then(() => {
        if (this.initialRoomId) {
          const chat = this.data.data.find(x => x.id === this.initialRoomId);
          if (chat) {
            this.$emit('input', chat);
          }
        }
      });
    },

    paginateChats(pagination) {
      this.pullChats({ ...this.data.meta, ...pagination });
    },

    pullChats(parameters) {
      return this.$load(this.feed.receiveChats(parameters)).then(({ data, meta }) => {
        this.data.meta = meta;
        this.data.paginator = new CursorPaginator({
          limit: meta.limit,
          hasNext: meta.hasNext,
          hasPrevious: meta.hasPrevious,
          startCursor: meta.startCursor,
          endCursor: meta.endCursor,
        });
        data.forEach(this.service.setChat);
        this.data.data = this.data.data.concat(data);

        return { data, meta };
      });
    },

    filterChats(parameters) {
      const pagination = {
        endCursor: now().toISOString(),
        limit: this.preloadAmount,
      }
      return this.$load(this.feed.receiveChats({...parameters, ...pagination })).then(({ data, meta }) => {
        this.data.meta = meta;
        this.data.paginator = new CursorPaginator({
          limit: meta.limit,
          hasNext: meta.hasNext,
          hasPrevious: meta.hasPrevious,
          startCursor: meta.startCursor,
          endCursor: meta.endCursor,
        });
        data.forEach(this.service.setChat);
        this.data.data = data;

        return { data, meta };
      });
    },

    scroll(event) {
      const element = event.target;
      const ideal = event.target.scrollHeight - event.target.offsetHeight
      if (
        thresholdCheck(this.threshold, ideal, element.scrollTop) &&
        (this.data.paginator && this.data.paginator.hasNext()) &&
        !this.isLoading
      ) {
        if (this.stopPaginate) return;
        this.paginateChats(this.data.paginator.next());
      }
    },
  },
}, {
  props: {
    chats() {
      return this.items;
    },

    loading() {
      return this.isLoading;
    },
    scrollerClass() {
      return this.scroller
    }
  },
  listeners: {
    scroll(arg){
      this.scroll(arg)
    },
    filter(parameters) {
      this.filterChats(parameters)
    }
  }
});

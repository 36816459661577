<template lang="pug">
include /mixins
.relative
  ui-loading(v-if="loading")
  row(appearance="spaced", space="sm")
    cell(
      cols="auto narrow-auto"
    )
      filter-ui(
        :disabled="loading",
        :value="prepared",
        :available="availableMap",
        :filters="filters",
        @input="handleInput",
        @filtrate="filtrate"
      )
        template(#append="scope" v-if="!hideFiltersControls")
          slot(name="append", v-bind="{ ...scope, all }")

          cell(v-if="!hideSearchButton")
            control-old-button(
              @click.prevent="filter",
              :disabled="loading",
              styling="brand-grey",
              size="md",
              variant="round",
              :aria-label="_('Search')"
            )
              ds-inliner(size="lg", aria-hidden="true")
                +b.icon--size_lg.icon-search
          cell(v-if="!hideClearButton")
            control-old-button(
              @click.prevent="clear",
              :disabled="loading",
              styling="brand-grey",
              size="md",
              variant="round",
              :aria-label="_('Reset')"
            )
              ds-inliner(size="lg", aria-hidden="true")
                +b.icon.icon-close
</template>

<script>
import { isEmpty } from '@aspectus/vue-utils';
import isShallowEqual from '@wordpress/is-shallow-equal';
import FilterUi from './Ui.vue';

function orderFilters(list, order) {
  const map = list.reduce((acc, x) => {
    acc[x.id] = x;
    return acc;
  }, {});

  return order.filter(x => !!map[x]).map(x => map[x]);
}

export default {
  inheritAttrs: false,
  props: [
    'loading',
    'value',
    'receive',
    'all',
    'available',
    'receiveAvailable',
    'displayed',
    'autoupdatable',
    'permanent',
    'hideFiltersControls',
    'hideSearchButton',
    'hideClearButton',
  ],

  components: {
    FilterUi,
  },

  data() {
    return {
      prepared: {},
    };
  },

  watch: {
    value: {
      immediate: true,
      handler({ filters = {} } = {}, { filters: oldFilters } = {}) {
        if (isShallowEqual(filters, oldFilters)) {
          return;
        }

        this.prepare(filters);
        this.receiveAvailable({ filters });
      },
    },
  },

  created() {
    this.receive(this.value);
    this.receiveAvailable(this.value);
  },

  computed: {
    empty() {
      return Object.values(this.prepared).every(isEmpty);
    },
    filters() {
      return this.all && this.all.length && this.displayed
        ? orderFilters(this.all, this.displayed)
        : [];
    },
    availableMap() {
      if (!this.available) {
        return {};
      }

      return this.available.reduce((acc, x) => {
        acc[x.id] = x;
        return acc;
      }, {});
    },
  },

  methods: {
    prepare(value) {
      this.prepared = value;
    },
    handleInputFilter(value) {
      this.handleInput(value);
      this.filter();
    },
    handleInput(value, key) {
      this.prepare(value);

      if (this.autoupdatable && this.autoupdatable.includes(key)) {
        this.receiveAvailable({ filters: this.prepared });
      }
    },
    filtrate(value) {
      this.prepared = value;
      this.filter();
    },
    filter() {
      this.$emit('input', { ...this.value, filters: this.prepared });
    },
    clear() {
      this.$emit('input', { ...this.value, filters: {}, ...this.permanent });
      this.prepare({ filters: {}, ...this.permanent });
    },
  },
};
</script>

import { route } from '@md/routing';

const List = () => import('./views/List');
const View = () => import('./views/Referral');

export const AGENT_REFERRALS_LIST = 'agent:referrals:list';
export const AGENT_REFERRALS_REFERRAL = 'agent:referrals:referral';

export function createRoutes() {
  return [
    route('list/', List, AGENT_REFERRALS_LIST),
    route(':id/detail/', View, AGENT_REFERRALS_REFERRAL),

    { path: '', redirect: { name: AGENT_REFERRALS_LIST } },
  ];
}

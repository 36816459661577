import { route } from '@md/routing';

const List = () => import('./views/List');

export const TOUROPERATOR_HOTEL_LOYALTY = 'touroperator:hotels:bonus:loyalty:list';

export function createRoutes() {
  return [
    route('list/', List, TOUROPERATOR_HOTEL_LOYALTY, {
      meta: {
        parentRoute: TOUROPERATOR_HOTEL_LOYALTY,
      },
    }),
  ];
}

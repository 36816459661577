<template lang="pug">
include /mixins.pug
+b.blog-post(
)
  //- tooltip menu
  v-popover.blog-post__actions(
    :key="`${post.canEdit}_${!post.hasComplaint}`"
    v-if="post.canEdit || !post.hasComplaint",
    :placement="'top'"
   )
    +b.BUTTON.tooltip-dots-button.round-button(
      type="button",
      :aria-label="_('Options for post')"
    )
      +e.icon(aria-hidden="true")
        span &#8226;
        span &#8226;
        span &#8226;

    template(slot='popover')
      +b.tooltip-menu
        template(
          v-if="post.canEdit",
        )
          modal-trigger(
            path="blog/components/Edit.vue",
            name="BlogPostEdit",
            :label="post.label"
            v-slot="{ open }"
          )
            +e.item.BUTTON(
              type="button",
              @click="open",
              v-close-popover,
            ) {{ _("Edit") }}
          +e.item.BUTTON(
            type="button",
            @click="confirmDelete",
            v-close-popover,
          ) {{ _("Delete") }}
        +e.item.BUTTON(
          v-if="!(post.hasComplaint\
              || post.canEdit\
              || '0003000-acquitted' === post.status\
            )"
          type="button",
          @click="modalOpener(getModalConfigComplaintCreate(post, _('Complain post'), 'post'))",
          v-close-popover,
        ) {{ _("Complain") }}

  //- SSR content
  slot(
    name="default"
  )
  //- comment section
  +b.ds-card--styling_default
    comments-section(
      v-bind="$attrs"
      :initial-comments-count="post.commentsCount",
      :post="post",
    )

</template>
<script>
import { blog } from '@md/blog/api.js';
import { CreateComplaintMixin } from '@md/complaints/mixins.js';
import { modalOpener } from '@utils/helpers.js';

const CommentsSection = () => import('@md/blog/components/CommentsSection.vue');

export default {
  name: 'blog-item',
  mixins: [CreateComplaintMixin],
  components: { CommentsSection },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isAuthenticated: window.IS_AUTHENT,
      post: this.item,
    };
  },
  methods: {
    modalOpener,
    deletePost(id) {
      return blog.delete.execute({ id })
        .then(() => {
          window.location.assign(window.location);
        });
    },
    confirmDelete() {
      this.$confirm(this.item.id, this.deletePost, this._('Delete post?'));
    },
  },
};
</script>

const AgentVideo = () => import('./AgentVideo.vue');

const HotelPublicVideo = () => import('./HotelPublicVideo.vue');

export function install(Vue) {
  Vue.component('hotel-public-video', HotelPublicVideo);
  Vue.component('agent-video', AgentVideo);
}

export default { install };

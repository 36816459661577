import { route } from '@md/routing';
import { DMC_HOTEL_PROFILE } from '@sections/cabinet/modules/dmc/modules/hotels/router';

const List = () => import('./views/List');

export const DMC_HOTEL_LOYALTY = 'dmc:hotels:bonus:loyalty:list';

export function createRoutes() {
  return [
    route('list/', List, DMC_HOTEL_LOYALTY, {
      meta: {
        parentRoute: DMC_HOTEL_PROFILE,
      },
    }),
  ];
}

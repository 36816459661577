<script>
import { appeals } from '@md/appeals/api.js';

// import pathOr from 'ramda/src/pathOr'
// import { masking } from '@utils/helpers'

import FormMixin from '@md/ui/forms/FormMixin';

import { composeTransformers } from '@utils/transformers';
import { simplifiers } from '@md/filter/transformers';

const transformFrom = composeTransformers(
  simplifiers.simple('name'),
  simplifiers.simple('email'),
  simplifiers.simple('phone'),
  simplifiers.simple('comment'),
  // simplifiers.simple('hotel'),
  // simplifiers.simple('user'),
  simplifiers.simple('theme'),
  simplifiers.simple('source'),
  simplifiers.multiselect('files'),
  simplifiers.select('topic')
);

export default {
  name: 'appeals-controller',
  mixins: [FormMixin],
  props: {
    user: {
      type: [String, Number],
      required: false,
      default: null,
    },
    source: {
      type: String,
      required: true,
      default: null,
    },
    // userInfo: {
    //   type: [Object],
    //   default: () => ({}),
    // },
    requiredTopic: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      nonFieldErrors: '',
      hasUnloadedFiles: false,
      topicList: [],
      labels: {
        name: this._('Ваше Имя и Фамилия'),
        theme: this._('Appeals theme'),
        topic: this._('Appeals topic'),
        phone: this._('Номер телефона'),
        email: this._('Е-mail'),
        comment: this._('Комментари'),
        text: this._('Appeals text'),
      },
      form: {
        name: '',
        email: '',
        phone: '',
        comment: '',
        source: this.source,
        // user: this.user,
        // hotel: this.hotel,
        topic: '',
        theme: '',
        files: [],
      },

    };
  },
  async mounted() {
    if (this.requiredTopic) {
      await this.getTopics();
    }
  },
  methods: {
    async getTopics() {
      const { data: { items = [] } } = (await appeals.addons.topic.list.execute({ limit: 99 }));
      this.topicList = items || [];
    },
    async prepareData() {
      this.$refs.validator.validate().then(valid => {
        if (!valid) return;
        if (this.$refs.uploader && this.$refs.uploader.hasUnloaded) {
          this.$refs.validator.setErrors({ uploader: [this._('Please, upload or delete the file!')] });
          return;
        }
        this.submit(this.form);
      });
    },
    send(data) {
      const transformed = transformFrom(data);

      return appeals.create.execute({}, transformed)
        .then(async res => {
          if (res.data.message || res.data.redirect) {
            const { AnswerMessage } = await import('@utils/submit.js');
            AnswerMessage(res.data, this);
          }
          return res;
        })
        .then(() => {
          // this.$emit('close')
          this.form = {
            name: '',
            email: '',
            phone: '',
            comment: '',
            source: this.source,
            // hotel: this.hotel,
            topic: '',
            theme: '',
            files: [],
          };
          this.$refs.validator.$el.reset();
          this.$refs.validator.reset();
        });
    },
    showRejectError() {
      this.$toast.error(this._('Not allowed'));
    },
  },
};

</script>

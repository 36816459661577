import { render, staticRenderFns } from "./Handler.vue?vue&type=template&id=0459ee8f&lang=pug&"
import script from "./component.js?vue&type=script&lang=js&"
export * from "./component.js?vue&type=script&lang=js&"
import style0 from "./Handler.vue?vue&type=style&index=0&id=0459ee8f&prod&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./Handler.vue?vue&type=custom&index=0&blockType=docs"
if (typeof block0 === 'function') block0(component)

export default component.exports
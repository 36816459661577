<template lang="pug">
include /mixins.pug

+b.DIV.d-flex
  template(v-for="(array, index) in dynamicArray")
    +b.DIV.d-flex.direction-column.mr-5_per(:key="index")
      template(v-for="(attribute, subindex) in array")
        +b.mb-05.g-cell(:key="subindex")
          +b.P.ds-caption--color_2.--size_sm.--size_md-xl
            +b.g-row--align_center.--appearance_nowrap.--space_sm(
              v-if="attribute.icon"
            )
              +b.g-cell.g-cols--narrow
                +b.SPAN.ds-inliner--size_4xl
                  +e.body
                    img(
                      :src="attribute.icon"
                      width="25"
                      height="25"
                    )
              +b.SPAN.ds-caption {{ attribute.title }}:
            +b.pl-25.SPAN.ds-caption(v-else) {{ attribute.title }}:
          +b.DIV.t-content.ds-panel__element--offset_bottom
            +b.UL.hotel-amenities.direction-column
              template(v-for="(value, valueIndex) in attribute.values")
                +b.LI.ds-caption--size_sm.t-content.pl-15(:key="valueIndex")
                  template(v-if="value.hint")
                    span {{ value.caption }}
                    +b.ml-5.icon.icon-help-circle-outline(
                      v-tooltip="{ content: value.hint }"
                    )
                  template(v-else)
                    span {{ value.caption }}
</template>

<script>
import helperMixin from '@md/filter/mixins/HelperMixin';

export default {
  name: 'amenities-services',
  mixins: [helperMixin],
  props: {
    attr: {},
  },
  data() {
    return {
      attributes: window.attributes || [],
      arrangedForDesktop: [],
      arrangedForMobile: [],
      arrangedForTablet: [],
    };
  },
  mounted() {
    // desktop, three columns
    const groupedAttributes = this.groupedByColumn(this.attributes);
    const sortedAttributes = this.sortedSubarrays(groupedAttributes);
    this.arrangedForDesktop = sortedAttributes;
    // mobile, one column
    const maxLength = Math.max(
      ...sortedAttributes.map(arr => arr.length)
    );
    this.arrangedForMobile = [this.ordered(maxLength, sortedAttributes)];
    // tablet, two columns
    const flatArray = this.arrangedForMobile.flat();
    this.arrangedForTablet = this.splitArray([...flatArray]);
  },
  computed: {
    dynamicArray() {
      const arrangements = {
        desktop: this.isDesktop && this.arrangedForDesktop,
        mobile: this.isMobile && this.arrangedForMobile,
        tablet: this.isTablet && this.arrangedForTablet,
      };

      return (
        arrangements.desktop ||
        arrangements.mobile ||
        arrangements.tablet ||
        []
      );
    },
  },
  methods: {
    groupedByColumn(attributes) {
      return attributes.reduce((acc, item) => {
        if (!acc[item.column]) {
          acc[item.column] = [];
        }
        acc[item.column].push(item);
        return acc;
      }, {});
    },
    sortedSubarrays(groupedAttributes) {
      return Object.keys(groupedAttributes)
        .sort((a, b) => a - b)
        .map(column => groupedAttributes[column].sort((a, b) => a.row - b.row));
    },
    ordered(maxLength, sortedAttributes) {
      const orderedArray = [];
      for (let i = 0; i < maxLength; i++) {
        for (let j = 0; j < sortedAttributes.length; j++) {
          if (i < sortedAttributes[j].length) {
            orderedArray.push(sortedAttributes[j][i]);
          }
        }
      }
      return orderedArray;
    },
    splitArray(arr) {
      return arr.reduce(
        (acc, val, index) => {
          acc[0 === index % 2 ? 0 : 1].push(val);
          return acc;
        },
        [[], []]
      );
    },
  },
};
</script>

<template lang="pug">
include /mixins.pug
+b.ds-section__element(
  v-if="items && items.length"
)
  +b.ds-card--styling_default
    +b.anchor-target-dummy#reviews

    row(
      appearance="spaced",
      justify="start",
      :space="['md', 'lg-md', 'xl-xl']"
    )
      cell(cols="12")
        splide.splide--variant_titles(
          :options="options"
        )
          template(#controls="scope")
            .splide__arrows
              +b.g-row--justify_between.--align_center
                +b.g-cell.g-cols
                  +b.H3.ds-caption--size_lg.--appearance_uppercase.--variant_strong
                    | {{ _(" Отзывы") }}
                  //- pre {{ scope }}
                +b.g-cell.g-cols
                  +b.g-row.--align_center
                    +b.g-cell.g-cols
                      +b.BUTTON.splide__arrow--prev(
                        type="button",
                        aria-controls="splide01-track",
                        :aria-label="_('Previous slide')",
                      )
                        svg(
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 40 40"
                          width="40"
                          height="40"
                          aria-hidden="true"
                        )
                          path(
                            d="m15.5 0.932-4.3 4.38 14.5 14.6-14.5 14.5 4.3 4.4 14.6-14.6 4.4-4.3-4.4-4.4-14.6-14.6z"
                          )
                    +b.g-cell.g-cols
                      +b.BUTTON.splide__arrow--next(
                        type="button",
                        aria-controls="splide01-track",
                        :aria-label="_('Next slide')",
                      )
                        svg(
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 40 40"
                          width="40"
                          height="40"
                          aria-hidden="true"
                        )
                          path(
                            d="m15.5 0.932-4.3 4.38 14.5 14.6-14.5 14.5 4.3 4.4 14.6-14.6 4.4-4.3-4.4-4.4-14.6-14.6z"
                          )

          splide-slide(
            v-for="(item, index) in items",
            :key="index",
          )
            public-preview(
              :item="item",
            )
        //- ui-slider.default-slider(
        //-   :options="sliderConfig",
        //-   :key="items.length"
        //- )
          template(#slide)
            public-preview(
              v-for="(item, index) in items",
              :key="index",
              :item="item",
            )
</template>
<script>

import { reviews } from '@md/reviews/api.js';
import LoadingStateMixin from '@aspectus/vue-loading-state-mixin';
import { Splide, SplideSlide } from '@splidejs/vue-splide';

import PublicPreview from './PublicPreview.vue';

export default {
  mixins: [LoadingStateMixin],
  components: {
    PublicPreview,
    Splide,
    SplideSlide,
  },
  props: {
    hotel: {
      type: [Number, String],
      required: false,
    },
    touroperator: {
      type: [Number, String],
      required: false,
    },
  },

  data() {
    return {
      items: [],
      options: {
        perPage: 3,
        gap: '16px',
        pagination: false,
        autoHeight: true,
        breakpoints: {
          564: {
            perPage: 1,
          },
          992: {
            perPage: 2,
          },
        },
      },
      // sliderConfig: {
      //   slidesToShow: 3,
      //   infinite: false,
      //   arrows: true,
      //   appendArrows: '.hotel-review-slider-controls',
      //   prevArrow: `<button class="slick-arrow slick-prev" aria-label=${ this._('Previos slide') }><span class="icon icon-arrow_left"></span></button>`,
      //   nextArrow: `<button class="slick-arrow slick-next" aria-label=${ this._('Next slide') }><span class="icon icon-arrow_left"></span></button>`,
      //   mobileFirst: false,
      //   responsive: [
      //     {
      //       breakpoint: 1199,
      //       settings: {
      //         arrows: true,
      //         slidesToShow: 2,
      //       },
      //     },
      //     {
      //       breakpoint: 767,
      //       settings: {
      //         arrows: true,
      //         slidesToShow: 1,
      //       },
      //     },
      //     {
      //       breakpoint: 563,
      //       settings: {
      //         arrows: false,
      //         slidesToShow: 1,
      //       },
      //     },
      //   ],
      // },
    };
  },
  async mounted() {
    if (this.hotel) await this.getReviews(this.hotel, reviews.hotelPublic);
    if (this.touroperator) await this.getReviews(this.touroperator, reviews.touroperatorPublic);
  },
  methods: {
    getReviews(id, resource) {
      const params = {
        id,
        limit: 99,
      };
      this.$load(resource.execute(params))
        .then(({ data: { items } }) => {
          this.items = items;
        });
    },

  },

};
</script>

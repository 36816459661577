<template lang="pug">
include /mixins.pug

ds-panel()
  ds-panel-element(
  )
    row(justify="between", align="center")
      cell(cols="narrow")
        share-post(
          :link="link"
        )
      ds-caption(
        tag='cell',
        cols="auto"
        size="2xs",
      ) {{ getRelativeTime(new Date(post.createdAt)) }}
      cell(cols="narrow")
        row(justify="end")
          cell(cols="narrow")
            toggle-like(
              :id="post.id",
              :initial-has-like="post.hasLike"
              :initial-likes-count="post.likesCount"
              :key-type="'blog'"
              v-slot="{ hasLike, likesCount, toggleLike }"
            )
              row(align="center")
                cell(
                  v-if="hasLike"
                  cols="narrow"
                )
                  +b.DS-CAPTION.ds-caption--medium.--color_7(
                    tag="span",
                    size="xs",
                  ) {{ _("You liked") }}
                cell(cols="narrow")
                  +b.BUTTON.ds-link--styling_inherit(
                    type="button",
                    @click="toggleLike"
                    :aria-label="hasLike ? _('Remove like from post') : _('Add like to post')"
                  ) {{ _("Like") + " (" + likesCount + ")" }}
          cell(cols="narrow")
            //+b.A.ds-link--styling_inherit(
            //  v-if="initial.relatedObject.hotel"
            //  :href="initial.relatedObject.hotel.absoluteUrl"
            //)
            +b.A.ds-link--styling_inherit(
              v-if="!showCommentsList"
              :href="link"
            ) {{ _("Comments") + " (" + commentsCount + ")" }}
            +b.SPAN(
              v-else
            ) {{ _("Comments") + " (" + commentsCount + ")" }}
  ds-panel(v-if="showCommentsList")
    ds-panel-element(offset="vertical")
      +b.hr-line
    ds-panel-element
      .relative
        ui-loading(v-if="loading")

        row(appearance="column_reverse")
          cell(
            cols="12"
            v-for="comment in commentsList"
            :key="comment.id"
          )
            comment-view(
              :initial-comment="comment"
              :parameters="parameters"
            )
          cell(
            v-if="hasMore"
            cols="12"
          )
            +b.BUTTON.ds-link--styling_inherit(
              type="button",
              @click="handlePaginate"
            )
              ds-caption.ds-caption--medium(size="xs", tag="span") {{ _("Show previous") }}

    ds-panel-element(offset="top")
      create-comment(
        :key="post.id"
        :initial="form"
        @add-comment="addComment"
      )
</template>
<script>
import LoadingStateMixin from '@aspectus/vue-loading-state-mixin';
import { CreateComplaintMixin } from '@md/complaints/mixins.js';

import { comments } from '@md/comments/api.js';
import { modalOpener, getRelativeTime } from '@utils/helpers.js';
import { COMMENTS_NATURAL_KEY } from '@md/comments/const.js';

const CreateComment = () => import('./CreateComment.vue');
const CommentView = () => import('./CommentView.vue');

const SharePost = () => import('@md/blog/components/SharePost.vue');
export default {
  name: 'blog-post-comments-list',
  mixins: [LoadingStateMixin, CreateComplaintMixin],
  components: { CreateComment, CommentView, SharePost },
  props: {
    link: String,
    showCommentsList: {
      type: Boolean,
      default: false,
    },
    initialCommentsCount: {
      type: [String, Number],
    },
    post: {},
  },
  data() {
    return {
      showReplyForm: true,
      commentsCount: +this.initialCommentsCount,
      commentsList: [],
      form: {
        instance: {
          type: COMMENTS_NATURAL_KEY.blog,
          id: this.post.id,
        },
        text: '',
      },
      parameters: {
        filters: JSON.stringify({
          type: COMMENTS_NATURAL_KEY.blog,
          id: this.post.id,
        }),
      },
      pagination: {
        limit: 0,
        offset: 0,
        total: +this.initialCommentsCount,
      },
      initialOffset: 1,
    };
  },
  computed: {
    hasMore() {
      return (this.pagination.offset || this.initialOffset) + this.pagination.limit < this.pagination.total;
    },
  },
  mounted() {
    if (this.showCommentsList) {
      this.handlePaginate();
    }
  },
  methods: {
    modalOpener,
    getRelativeTime,
    getComments(parameters) {
      this.$load(comments.list.execute(parameters))
        .then(({ data }) => {
          if (parameters.offset) {
            this.commentsList.push(...data.items);
          } else {
            this.commentsList = data.items;
          }
          this.pagination = data.pagination;
        });
    },
    addComment(form) {
      this.$load(comments.create.execute({}, form))
        .then(({ data: { item } }) => {
          this.commentsList.unshift(item);
          this.commentsCount++;
          this.form.text = '';
        });
    },
    handlePaginate() {
      if (!this.loading) {
        const parameters = {
          ...this.parameters,
          limit: this.pagination.limit,
          offset: this.pagination.offset + this.pagination.limit,
        };
        this.getComments(parameters);
      }
    },
  },
};
</script>

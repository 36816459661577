import { route } from '@md/routing';
// import { video } from '@md/video/api.js';
// import { accessHotelPermission } from '@md/users/permissions';
// import {
//   // VIDEO_CREATE,
//   // VIDEO_DELETE,
//   MANAGER_VIDEO_VIDEO_EDIT,
// } from '@md/video/accessTokens';

const List = () => import('./views/List');
// const Edit = () => import('./views/Edit/View');

const VIDEO_PREFIX = 'dmc:hotels:profile:video:';
const p = name => VIDEO_PREFIX + name;
export const DMC_VIDEO_LIST = p('list');
// export const DMC_VIDEO_EDIT = p('edit');
export const DMC_VIDEO_CREATE = p('create');

export function createRoutes(
  // options
) {
  return [
    route('list/', List, DMC_VIDEO_LIST, {
      // meta: accessHotelPermission(MANAGER_VIDEO_VIDEO_EDIT, options),
    }),
    // receiveRoute(video.retrieve, [
    //   route('edit/', Edit, DMC_VIDEO_EDIT, {
    //     meta: accessHotelPermission(VIDEO_VIEW, options),
    //   }),
    //   { path: '', redirect: { name: DMC_VIDEO_EDIT } },
    // ]),

    { path: '', redirect: { name: DMC_VIDEO_LIST } },
  ];
}

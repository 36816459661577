import { route, prefixRoutes, ImportedNamespace } from '@md/routing';
import {
  MANAGER_HOTEL_EDIT,
  MANAGER_LOYALTY_LOYALTY_PROGRAM_EDIT,
  MANAGER_SALES_SALE_ADD,
  MANAGER_BIDS_REVIEWS_ACCEPT,
} from '@md/hotels/accessTokens';
import { accessHotelPermission } from '@md/users/permissions.js';
import { createRoutes as createRoomsRoutes } from './modules/rooms/router.js';
import { createRoutes as createPromotionsRoutes } from './modules/promotions/router.js';
import { createRoutes as createLoyaltyRoutes } from './modules/loyalty/router.js';
import { createRoutes as createServicesRoutes } from './modules/services/router.js';

const Create = () => import('./views/Create.vue');
const Edit = () => import('./views/Edit.vue');
const Home = () => import('./views/Home.vue');
const Gallery = () => import('./views/HotelProfileGallery.vue');
const HotelStatistics = () => import('./views/Statistics.vue');

const DMC_HOTELS_PREFIX = 'dmc:hotels:';
const p = name => DMC_HOTELS_PREFIX + name;
export const DMC_HOTEL = 'dmc:hotels';
export const DMC_HOTEL_CREATE = p('create');
export const DMC_HOTEL_HOME_PAGE = p('home');
export const DMC_HOTEL_EDIT = p('edit');
export const DMC_HOTEL_PROFILE = p('profile');
export const DMC_HOTEL_GALLERY = p('gallery');
export const DMC_HOTEL_STATISTICS = p('analytics');
const type = '(\\d+)';
const prefixHotelId = `:hotel_id${type}`;

export function createRoutes(options) {
  return [
    route('create/', Create, DMC_HOTEL_CREATE),
    route(prefixHotelId, ImportedNamespace, DMC_HOTEL, {
      redirect: { name: DMC_HOTEL_HOME_PAGE },
      children: [
        route('profile/', ImportedNamespace, DMC_HOTEL_PROFILE, {
          children: [
            route('', Home, DMC_HOTEL_HOME_PAGE, {
              children: [
                route('edit/', Edit, DMC_HOTEL_EDIT, {
                  meta: {
                    ...accessHotelPermission(MANAGER_HOTEL_EDIT, options, DMC_HOTEL_GALLERY),
                  },
                }),
                route('edit/gallery/', Gallery, DMC_HOTEL_GALLERY, {
                  meta: {
                    ...accessHotelPermission(MANAGER_HOTEL_EDIT, options),
                    parentRoute: DMC_HOTEL_EDIT,
                  },
                }),

              ],
            }),
            prefixRoutes('loyalty/', createLoyaltyRoutes(options)),
          ],
        }),
        prefixRoutes('loyalty/', createLoyaltyRoutes(options), {
          meta: accessHotelPermission(MANAGER_LOYALTY_LOYALTY_PROGRAM_EDIT, options),
        }),
        prefixRoutes('services/', createServicesRoutes(options), {
          meta: accessHotelPermission(MANAGER_HOTEL_EDIT, options),
        }),
        prefixRoutes('rooms/', createRoomsRoutes(options), {
          meta: accessHotelPermission(MANAGER_HOTEL_EDIT, options),
        }),
        prefixRoutes('promotions/', createPromotionsRoutes(options), {
          meta: accessHotelPermission(MANAGER_SALES_SALE_ADD, options),
        }),
        route('statistics/', HotelStatistics, DMC_HOTEL_STATISTICS, {
          meta: accessHotelPermission(MANAGER_BIDS_REVIEWS_ACCEPT, options),
        }),
      ],
    }),

    { path: '', redirect: { name: DMC_HOTEL_CREATE } },
  ];
}

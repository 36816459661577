import { route } from '@md/routing';

const View = () => import('./View');

export const DMC_APPEALS = 'dmc:appeals';

export function createRoutes() {
  return [
    route('', View, DMC_APPEALS, {
    }),
    { path: '', redirect: { name: DMC_APPEALS } },
  ];
}

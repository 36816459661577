import { route } from '@md/routing';

const FaqDisplay = () => import('@md/faq/components/Display.vue');

export const HOTELIER_CHAIN_FAQ = 'hotelier_chain:faq';

export function createRoutes() {
  return [
    route('', FaqDisplay, HOTELIER_CHAIN_FAQ),
  ];
}

// import { VueAgile } from 'vue-agile'

import Slick from 'vue-slick';
import ModalTrigger from './ModalTrigger.vue';
import UiSlider from './UiSlider.vue';
import UiShareElement from './UiShareElement.vue';
import UiLoading from './UiLoading.vue';
import Icon from './Icon';
import ClickOutside from './ClickOutside.vue';
import TextAccordion from './TextAccordion.vue';

const AcceptSiteCookie = () => import('./AcceptSiteCookie.vue');

const Tab = () => import('./ResponsiveTabs/Tab');
const Tabs = () => import('./ResponsiveTabs/Tabs');

// const Loading = () => import('./Loading');
// const LoadingWrapper = () => import('./LoadingWrapper');

const MapView = () => import('./MapView.vue');
const ModalWithMap = () => import('./ModalWithMap.vue');
const Toggler = () => import('./Toggler.vue');
const Lightbox = () => import('./Lightbox.vue');
const PromotionMarks = () => import('./PromotionMark.vue');
const HotelStars = () => import('./HotelStars.vue');
const SocialShareMin = () => import('./SocialShareMin.vue');

const UiVideoView = () => import('./UiVideoView.vue');
const DynamicContentRenderer = () => import('./DynamicContentRenderer.js');
const FileTemplate = () => import('./FileTemplate.vue');
const FileUploader = () => import('./FileUploader.vue');
const ChunkedUploader = () => import('./ChunkedUploader.vue');
const DateFormat = () => import('./DateFormat.vue');

export function install(Vue) {
  Vue.component('accept-site-cookie', AcceptSiteCookie);
  Vue.component('rs-tabs', Tabs);
  Vue.component('rs-tab', Tab);
  Vue.component('ModalTrigger', ModalTrigger);
  Vue.component('Slick', Slick);
  Vue.component('TextAccordion', TextAccordion);
  Vue.component('UiSlider', UiSlider);
  Vue.component('UiLoading', UiLoading);
  // Vue.component('VueAgile', VueAgile)
  Vue.component('UiVideoView', UiVideoView);
  Vue.component('ui-toggler', Toggler);
  Vue.component('lightbox', Lightbox);
  Vue.component('promotion-marks', PromotionMarks);
  Vue.component('HotelStars', HotelStars);

  Vue.component('file-template', FileTemplate);
  Vue.component('file-uploader', FileUploader);
  Vue.component('chunked-uploader', ChunkedUploader);

  Vue.component('click-outside', ClickOutside);
  Vue.directive('click-outside', ClickOutside);
  Vue.component('map-view', MapView);
  Vue.component('modal-with-map', ModalWithMap);

  // Vue.component(Loading.name, Loading);
  // Vue.component(LoadingWrapper.name, LoadingWrapper);
  Vue.component('share-element', UiShareElement);
  Vue.component('social-share-min', SocialShareMin);
  Vue.component('dynamic-content-renderer', DynamicContentRenderer);
  Vue.component('icon', Icon);
  Vue.component('date-format', DateFormat);
}

export default { install };

import HotelRatingDetail from './HotelRatingDetail.vue';
import PublicReviewList from './PublicList.vue';

const CreateDelegatedReview = () => import('./CreateDelegatedReview.vue');

export default function install(Vue) {
  Vue.component('public-review-list', PublicReviewList);
  Vue.component('create-delegated-review', CreateDelegatedReview);
  Vue.component('hotel-rating-detail', HotelRatingDetail);
}

import Inputs from './inputs';
import Described from './described';
// import Select from './Select';
import FloatLabel from './FloatLabel';

function install(Vue) {
  Vue.use(Inputs);
  Vue.use(Described);

  // Vue.component(Select.name, Select);
  Vue.component('float-label', FloatLabel);
}

export default { install };

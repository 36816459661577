<script>
import { hotels } from '@md/hotels/api.js';

const Login = () => import('@md/auth/components/Login');

const SUCCESS_CODE = 200;

export default {
  name: 'toggle-favourite',
  props: {
    initial: {
      type: [Boolean],
      required: true,
    },
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      isAuth: window.IS_AUTHENT,
      isFavourite: this.initial,
    };
  },

  methods: {

    async toggle() {
      if (!this.isAuth) {
        this.$modal.show(
          Login,
          {
            next: window.location.pathname,
          }, {
            class: ['login-modal'],
            width: 690,
            height: 'auto',
            adaptive: true,
            scrollable: true,
          }
        );
        return;
      }
      const apiType = this.isFavourite ? 'remove' : 'add';
      const data = { hotel: this.id };
      hotels.favourite[apiType].execute({}, data)
        .then(res => {
          if (SUCCESS_CODE === res.code) {
            this.isFavourite = !this.isFavourite;
          }
        });
    },
  },
  render() {
    return this.$scopedSlots.default({
      toggleFavourite: this.toggle,
      isFavourite: this.isFavourite,
    });
  },
};

</script>

import { route } from '@md/routing.js';

const List = () => import('./views/List');

const NOTIFICATIONS_PREFIX = 'admin:notifications:';

const p = name => NOTIFICATIONS_PREFIX + name;

export const ADMIN_NOTIFICATIONS_LIST = p('list');

export function createRoutes() {
  return [
    route('list/', List, ADMIN_NOTIFICATIONS_LIST),
  ];
}

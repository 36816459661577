<template functional lang="pug">

include /mixins.pug

+b.TAG(
  v-bem:[props.blockName]="{ styling: props.styling, size: props.size, view: props.view, variant: props.variant, appearance: props.appearance }"
  v-state="{ disabled: props.disabled, readonly: props.readonly, invalid: props.invalid }"
  :class="[data.staticClass, data.class]"
  v-bind="{ 'aria-disabled': props.disabled , ...data.attrs }"
  v-on="data.on",
  :tag="props.disabled && 'a' == props.tag ? 'div' : props.tag",
  :readonly="props.readonly",
  :disabled="props.disabled",
)
  span(v-bem:[props.blockName].body="")

    slot(name="prepend")
    span(
      v-bem:[props.blockName].element="{ attachment: 'prepend' }",
      v-if="props.startIcon || scopedSlots.startIcon"
    )
      slot(name="startIcon")
        icon(:name="props.startIcon", :size="props.iconSize")
    +e.SPAN(
      v-bem:[props.blockName].element="{ primary: true }"
    )
      slot
    span(
      v-bem:[props.blockName].element="{ attachment: 'append' }",
      v-if="props.endIcon || scopedSlots.endIcon"
    )
      slot(name="endIcon")
        icon(:name="props.endIcon", :size="props.iconSize")
    slot(name="append")

</template>

<script>

export default {
  name: 'control-root-element',
  props: {
    blockName: String,
    innerElement: {},

    variant: {},
    size: { default: 'md' },
    styling: { default: 'default' },
    appearance: {},
    view: {},
    disabled: Boolean,
    readonly: Boolean,
    invalid: Boolean,
    tag: { default: 'div' },
    iconSize: { default: '2xl' },
    startIcon: {},
    endIcon: {},
  },
};

</script>

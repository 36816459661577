import { route, prefixRoutes, ImportedNamespace } from '@md/routing';
import {
  MANAGER_HOTEL_EDIT,
  // MANAGER_VIDEO_VIDEO_EDIT,
  MANAGER_LOYALTY_LOYALTY_PROGRAM_EDIT,
  MANAGER_SALES_SALE_ADD,
  MANAGER_BIDS_REVIEWS_ACCEPT,
} from '@md/hotels/accessTokens';
import { accessHotelPermission } from '@md/users/permissions.js';
import { createRoutes as createRoomsRoutes } from './modules/rooms/router.js';
import { createRoutes as createPromotionsRoutes } from './modules/promotions/router.js';
import { createRoutes as createLoyaltyRoutes } from './modules/loyalty/router.js';
import { createRoutes as createServicesRoutes } from './modules/services/router.js';
import { createRoutes as createFaqRoutes } from './modules/faq/router.js';

const Create = () => import('./views/Create.vue');
const Edit = () => import('./views/Edit.vue');
const Home = () => import('./views/Home.vue');
const Gallery = () => import('./views/HotelProfileGallery.vue');
const HotelStatistics = () => import('./views/Statistics.vue');

const HOTELIER_HOTELS_PREFIX = 'hotelier:hotels:';
const p = name => HOTELIER_HOTELS_PREFIX + name;
export const HOTELIER_HOTEL = 'hotelier:hotels';
export const HOTELIER_HOTEL_CREATE = p('create');
export const HOTELIER_HOTEL_HOME_PAGE = p('home');
export const HOTELIER_HOTEL_EDIT = p('edit');
export const HOTELIER_HOTEL_PROFILE = p('profile');
export const HOTELIER_HOTEL_GALLERY = p('gallery');
export const HOTELIER_HOTEL_STATISTICS = p('analytics');
const type = '(\\d+)';
const prefixHotelId = `:hotel_id${type}`;

export function createRoutes(options) {
  return [
    route('create/', Create, HOTELIER_HOTEL_CREATE),
    route(prefixHotelId, ImportedNamespace, HOTELIER_HOTEL, {

      redirect: { name: HOTELIER_HOTEL_HOME_PAGE },
      children: [
        route('profile/', ImportedNamespace, HOTELIER_HOTEL_PROFILE, {
          children: [
            route('', Home, HOTELIER_HOTEL_HOME_PAGE, {
              meta: {
              // ...accessHotelPermission([MANAGER_HOTEL_EDIT, MANAGER_HOTEL_EDIT], options),
              },
              children: [
                route('edit/', Edit, HOTELIER_HOTEL_EDIT, {
                  meta: {
                    ...accessHotelPermission(MANAGER_HOTEL_EDIT, options, HOTELIER_HOTEL_GALLERY),
                  },
                }),
                route('edit/gallery/', Gallery, HOTELIER_HOTEL_GALLERY, {
                  meta: {
                    ...accessHotelPermission(MANAGER_HOTEL_EDIT, options),
                    parentRoute: HOTELIER_HOTEL_EDIT,
                  },
                }),

              ],
            }),
            prefixRoutes('loyalty/', createLoyaltyRoutes(options), {
              meta: {
                ...accessHotelPermission(MANAGER_LOYALTY_LOYALTY_PROGRAM_EDIT, options),
                parentRoute: HOTELIER_HOTEL_PROFILE,
              },
            }),
            prefixRoutes('rooms/', createRoomsRoutes(options), {
              meta: {
                ...accessHotelPermission(MANAGER_HOTEL_EDIT, options),
                parentRoute: HOTELIER_HOTEL_PROFILE,
              },
            }),
            prefixRoutes('faq/', createFaqRoutes(options), {}),
          ],
        }),
        // prefixRoutes('video/', createVideoRoutes(options), {
        //   meta: accessHotelPermission(MANAGER_VIDEO_VIDEO_EDIT, options),
        // }),
        prefixRoutes('loyalty/', createLoyaltyRoutes(options), {
          meta: accessHotelPermission(MANAGER_LOYALTY_LOYALTY_PROGRAM_EDIT, options),
        }),
        prefixRoutes('services/', createServicesRoutes(options), {
          meta: accessHotelPermission(MANAGER_HOTEL_EDIT, options),
        }),
        prefixRoutes('rooms/', createRoomsRoutes(options), {
          meta: accessHotelPermission(MANAGER_HOTEL_EDIT, options),
        }),
        prefixRoutes('promotions/', createPromotionsRoutes(options), {
          meta: accessHotelPermission(MANAGER_SALES_SALE_ADD, options),
        }),
        route('statistics/', HotelStatistics, HOTELIER_HOTEL_STATISTICS, {
          meta: accessHotelPermission(MANAGER_BIDS_REVIEWS_ACCEPT, options),
        }),
      ],

    }),
    //    prefixRoutes(`${prefixHotelId}notifications/`, createNotificationsRoutes(options), {}),

    { path: '', redirect: { name: HOTELIER_HOTEL_CREATE } },
  ];
}

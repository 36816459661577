/* eslint-disable
  class-methods-use-this,
  max-classes-per-file
*/

import { Permission } from '@aspectus/permissions';

export function waitForPermissions(state, getter, timeout = 5000, tick = 10) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      let check = null;
      const rejector = () => (clearTimeout(check) || reject());
      const ticker = () => {
        const hotelState = getter(state);
        const { permissions, selectedHotelId } = hotelState || {};

        if (permissions && permissions.size) {
          if (permissions.has(selectedHotelId)) {
            resolve(permissions.get(selectedHotelId));
          } else {
            reject();
          }
          return;
        }

        check = setTimeout(ticker, tick);
      };

      setTimeout(rejector, timeout);
      ticker();
    }, 200);
  });
}

export class UserCheckerPermissionBase extends Permission {
  constructor({ getter, timeout = 5000, tick = 5 } = {}) {
    super();

    this.getter = getter;
    this.timeout = timeout;
    this.tick = tick;
  }

  getStore(vm, { pinia } = {}) {
    return vm && vm.$pinia || pinia;
  }

  waitForPermissions(vm, args) {
    const store = this.getStore(vm, args[0] || {});

    return waitForPermissions(
      store, this.getter, this.timeout, this.tick
    );
  }

  checkPermissions() {
    return false;
  }

  onHasAccess(vm, ...args) {
    return this.waitForPermissions(vm, args).then(permissions => {
      if (!this.checkPermissions(permissions, vm, ...args)) {
        throw permissions;
      }
    });
  }
}

export class UserCheckerPermission extends UserCheckerPermissionBase {
  constructor({ checker, ...rest }) {
    super(rest);
    this.checker = checker;
  }

  checkPermissions(...rest) {
    return this.checker.apply(null, rest);
  }
}

export function checkPresencePermissions(checker, config = {}, Base = UserCheckerPermission) {
  return new Base({ checker, ...config });
}

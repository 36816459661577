import { route } from '@md/routing';

const Edit = () => import('./views/Edit.vue');

export const TOUROPERATOR_MANAGER_PROFILE_EDIT = 'touroperator-manager:profile-edit';

export function createRoutes() {
  return [
    route('edit/', Edit, TOUROPERATOR_MANAGER_PROFILE_EDIT),
    { path: '', redirect: { name: TOUROPERATOR_MANAGER_PROFILE_EDIT } },
  ];
}

export default function text(message) {
  const element = document.createElement('div');
  if ('text' === message.type) {
    element.innerHTML = message.body.content;
  } else {
    element.innerHTML = 'file';
  }

  return element.innerText;
}

<template lang="pug">
include /mixins.pug

+b.VALIDATION-OBSERVER(
  ref="validator",
  v-slot="{ valid }",
  tag="row",
)
  slot(
    name="default",
    :has-like="hasLike",
    :likes-count="likesCount",
    :toggle-like="prepareData"
  )
    +b.BUTTON.favourite_toggler__button(
      type="button",
      @click.prevent="loading ? '' : prepareData(valid)"
      :aria-label="hasLike ? _('Remove like to video') : _('Add like to video')"
    )
      +b.icon(
        aria-hidden="true"
        :class="{'icon-heart icon--color_accent_20': hasLike, 'icon-heart_outline icon--color_2': !hasLike, }"
      )

    +b.favourite_toggler__count {{ likesCount }}

</template>
<script>
import { likes } from '@md/likes/api.js';
import { LIKES_NATURAL_KEY } from '@md/likes/const.js';
import FormMixin from '@md/ui/forms/FormMixin';

const Login = () => import('@md/auth/components/Login');

const SUCCESS_CODE = 200;

export default {
  name: 'toggle-like',
  mixins: [FormMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    initialHasLike: {
      type: Boolean,
      default: false,
    },
    keyType: {
      type: String,
      default: 'video',
    },
    initialLikesCount: [String, Number],
  },
  data() {
    return {
      isAuth: window.IS_AUTHENT,
      form: {
        type: LIKES_NATURAL_KEY[this.keyType],
        id: this.id,
      },
      hasLike: this.initialHasLike,
      likesCount: this.initialLikesCount,
    };
  },
  computed: {
    apiType() {
      return this.hasLike ? 'remove' : 'add';
    },
  },
  methods: {
    async prepareData(valid) {
      if (!this.isAuth) {
        this.$modal.show(
          Login,
          {
            next: window.location.pathname,
          },
          {
            class: ['login-modal'],
            width: 690,
            height: 'auto',
            adaptive: true,
            scrollable: true,
          }
        );
        return;
      }
      this.$refs.validator.validate();
      if (!valid) return;
      this.submit(this.form);
    },
    send(data) {
      return likes[this.apiType].execute({}, data)
        .then(res => {
          if (SUCCESS_CODE === res.code) {
            this.hasLike = !this.hasLike;
            if (this.hasLike) {
              this.likesCount++;
            } else {
              this.likesCount--;
            }
          }
        });
    },
  },
};

</script>

<template lang="pug">
include /mixins.pug
div
  row.pl-2(justify="start", align="center")
    span(
      v-if="isMenuHidden"
      v-tooltip="{ content: hint }"
    )
      icon.icon--color_8(name="icon-help-circle-outline", size="xl")
  row(justify="end", align="center")
    span(v-if="!isMenuHidden") {{ _('Professional') }}
    +b.control-old-input__element.switch.--green.INPUT(
      :checked="value"
      type="checkbox"
      :value="value"
      @change="changeMode"
    )

    span(v-if="!isMenuHidden") {{ _('Simple mode') }}

</template>

<script>
import storage from '@aspectus/json-local-storage';

export default {
  props: ['isMenuHidden'],
  data() {
    return {
      value: false,
      storageKey: 'simpleMode',
      hint: this.pgettext('cabinet:components:sidebar', 'Mode change'),
    };
  },
  mounted() {
    const simpleMode = storage.get('simpleMode');

    if (null === simpleMode) {
      this.value = true;
    } else {
      this.value = simpleMode;
    }
  },
  methods: {
    changeMode(event) {
      storage.set(this.storageKey, event.target.checked);

      window.location.reload();
    },
  },
};
</script>

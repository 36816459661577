import { route } from '@md/routing';

const List = () => import('./views/List');

const TRANSACTIONS_PREFIX = 'touroperator:transactions:';
const p = name => TRANSACTIONS_PREFIX + name;
export const TOUROPERATOR_TRANSACTIONS_LIST = p('list');

export function createRoutes() {
  return [
    route('list/', List, TOUROPERATOR_TRANSACTIONS_LIST, {
    }),

    { path: '', redirect: { name: TOUROPERATOR_TRANSACTIONS_LIST } },
  ];
}

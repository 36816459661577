import './public-path';

import Vue from 'vue';
import Router from 'vue-router';
import I18n from 'vue-i18n';

import { createPinia, PiniaVuePlugin } from 'pinia';
import {
  extend,
} from 'vee-validate';
import { validate as UrlValidate } from '@md/validation/url.js';
import {
  required, confirmed, email,
  min, min_value as minValue, max, max_value as maxValue,
  ext, size, integer,
} from 'vee-validate/dist/rules';
import { createApp } from '@/app.js';
import { install } from '@/install.js';

import './styles/index.sass';

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

Vue.config.productionTip = false;
Vue.use(PiniaVuePlugin);
Vue.use(install);
// Vue.config.devtools = true;

const { app, i18n, router } = createApp({
  Vue,
  Router,
  I18n,
  pinia,
});

export { router };

function _(...arg) {
  return i18n.t(...arg);
}

extend('required_map', {
  validate(value, keys) {
    const invalid = keys.some(x => !required.validate(value && value[x]).valid);

    return { valid: !invalid, required: min.required || max.required };
  },
  computesRequired: true,
  message: app._('This field is required.'),
});
extend('required', { ...required, message: _('This field is required.') });
extend('confirmed', { ...confirmed, message: _('Passwords must match.') });
extend('min', { ...min, message: _('Value is too short.') });
extend('min_value', { ...minValue, message: _('Minimum value is {min}.') });
extend('integer', { ...integer, message: _('Value must be an integer.') });
extend('max', { ...max, message: _('Value is too long.') });
extend('max_value', { ...maxValue, message: _('Maximum value is {max}.') });
extend('ext', { ...ext, message: _('Invalid file format') });
extend('email', { ...email, message: _('Email is invalid.') });
extend('size', { ...size, message: (e, a) => _('The file size must be less than ') + a.size / 1024 + _('Mb') });
extend('password', {
  validate(value) {
    if (7 > value.length) return false;
    const PASSWORD_REG = /^(?=.*?[A-ZА-Я])(?=.*?[a-zа-я])(?=.*?[0-9]).*$/;
    return PASSWORD_REG.test(value);
  },
  computesRequired: true,
  message: app._('The password must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number.'),
});

extend('only_latin', {
  validate(value) {
    const LATIN_CHARS = /^([A-Z\-'` ]*)$/;
    // const LATIN_CHARS = /^[\p{L}\p{N}\p{M}-]+$/u;
    // Regex for cylirric characters
    return LATIN_CHARS.test(value);
  },
  message: app._('Only latin characters'),
});
extend('phone', {
  validate(value) {
    const MOBILE_REG = /^\+?[\d ]+$/;
    // Regex for a international phone number
    return MOBILE_REG.test(value);
  },
  message: app._('Phone is not valid'),
});
extend('duration', {
  validate(value) {
    const DURATION_REG = /^(?:([0-9]{1,3}):(?=[0-9]{2}))?([0-5]?[0-9]):([0-5][0-9])$/;
    // Regex for a international phone number
    return DURATION_REG.test(value);
  },
  message: app._('Duration is not valid'),
});
extend('url', {
  validate: value => UrlValidate(value),
  message: app._('Set correct url'),
});

app.$mount('#app');

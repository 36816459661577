import { route } from '@md/routing.js';

const List = () => import('./views/List');

const HOTELIERS_PREFIX = 'admin:hoteliers:';
const p = name => HOTELIERS_PREFIX + name;

export const ADMIN_HOTELIERS_LIST = p('list');

export function createRoutes() {
  return [
    route('list/', List, ADMIN_HOTELIERS_LIST),
  ];
}

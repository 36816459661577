<template lang="pug">
include /mixins.pug
div
  light-box.light-box-wrapper(
    :show-gallery="true",
    :index="index"
    @chang="e => index = e"
    @close="index = null"
    :items="preparedImages"
    :toolbar="toolbar"
  )
    template(#icon-previous)
      +b.ICON.icon--color_1(name="icon-arrow-left", size="2xl")
    template(#icon-next)
      +b.ICON.icon--color_1(name="icon-arrow-right", size="2xl")

  slot(
    name="default"
    :open="open"
    :gallery="preparedImages"
  )

</template>
<script>

import { getVideoId } from '@utils/helpers.js';

export default {
  props: {
    images: {
      type: Array,
      default: () => ([]),
    },
    previewImage: {
      type: Object,
    },
  },

  data() {
    return {
      toolbar: ['counter', 'zoom', 'slide', 'rotate', 'gallery', 'close'],
      preparedImages: [],
      index: null,
    };
  },

  created() {
    this.prepareImages();
  },

  methods: {
    getVideoId,
    prepareImages() {
      if (this.previewImage?.src) {
        this.preparedImages.push(this.previewImage);
      }
      this.images.forEach(galleryItem => {
        let thumb = galleryItem.file?.thumbnailPreview || galleryItem.file?.thumbnail_preview || galleryItem.video_url || galleryItem.videoUrl;
        if (galleryItem.video_url || galleryItem.videoUrl) {
          const { preview } = this.getVideoId(galleryItem.video_url || galleryItem.videoUrl);
          thumb = preview;
        }
        this.preparedImages.push({
          src: galleryItem.file?.fullSize || galleryItem.file?.full_size || galleryItem.video_url || galleryItem.videoUrl,
          thumb,
          autoplay: true,
          controls: true,
        });
      });
    },
    open(index = 0) {
      this.index = index;
    },
  },

};
</script>
<style lang="sass">
.light-box-wrapper.cool-lightbox
  .cool-lightbox-navigation
    background-color: transparent
    color: #ffffff
  .cool-lightbox-thumbs
    &__
      &list
        overflow-x: auto

  &.cool-lightbox.cool-lightbox--thumbs-bottom .cool-lightbox-thumbs .cool-lightbox-thumbs__list
    justify-content: flex-start
    max-width: 99vw
    margin-left: auto
    margin-right: auto
  &.cool-lightbox.cool-lightbox--thumbs-bottom .cool-lightbox-thumbs .cool-lightbox__thumb
    min-width: 50px
    &:first-child
      margin-left: auto
    &:last-child
      margin-right: auto
</style>

<template lang='pug'>
include /mixins
div.relative
  div.dummy-scroll-catalog(ref='topOfContent')
  +b.catalog-content-container
    infinite-template-results-collector(
      :pagination="pagination",
      :items="items",
      :is-new-page="!isInfinityTemplate"
    )
      template(#ssr="{ isSSR }")
        template(v-if="isSSR")
          slot
      template(#default="{ items }")
        template(v-if="items")
          renderer(v-for="item in items", :key="item.key", :result="item.value")
  catalog-pagination(
    :start-page="isInfinityTemplate ? startPage : false",
    :scroll-paginate="scrollPaginate"
    :pagination="pagination",
    @input="handlePaginate",
    @load-more="handleLoadMore",
    :url="urlWithoutPage",
    v-bind="pagination"
  )
  //- ui-loading(v-if='loading')
</template>

<script>
import InfiniteTemplateResultsCollector from '@md/resources/components/InfiniteTemplateResultsCollector.js';

const CatalogPagination = () => import('@md/ui/catalog/Pagination.vue');
const Renderer = () => import('./Renderer.vue');

export default {
  components: {
    Renderer,
    InfiniteTemplateResultsCollector,
    CatalogPagination,
  },
  props: ['loading', 'items', 'pagination', 'isFiltered', 'scrollPaginate', 'urlWithoutPage'],
  data() {
    return {
      isInfinityTemplate: true,
      startPage: Math.floor(this.pagination.offset / this.pagination.limit) + 1,
    };
  },

  computed: {},

  watch: {
    isFiltered(val) {
      this.isInfinityTemplate = !val;
    },
  },
  methods: {
    scrollToTop() {
      this.$refs.topOfContent.scrollIntoView({ behavior: 'smooth' });
    },
    handleLoadMore({ offset }) {
      this.handlePaginate({ offset }, true);
    },
    handlePaginate({ offset }, isLoadMore = false) {
      if (this.loading) {
        return;
      }
      const init = 0;
      const { limit = init } = this.pagination;
      this.isInfinityTemplate = isLoadMore;
      if (!isLoadMore) {
        this.$nextTick(() => {
          this.startPage = Math.floor(offset / limit) + 1;
        });
        this.scrollToTop();
      }
      this.$emit('update:pagination', { limit, offset, page: Math.floor(offset / limit) + 1, infinite: isLoadMore });
    },
  },
};
</script>

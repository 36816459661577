import { route } from '@md/routing';

const View = () => import('./View');

export const ADMIN_CHATS = 'admin:chats';

export function createRoutes() {
  return [
    route('', View, ADMIN_CHATS),
  ];
}

import { route } from '@md/routing.js';

const List = () => import('./views/List');

const AGENCIES_PREFIX = 'admin:agencies:';
const p = name => AGENCIES_PREFIX + name;

export const ADMIN_AGENCIES_LIST = p('list');

export function createRoutes() {
  return [
    route('list/', List, ADMIN_AGENCIES_LIST),
  ];
}

import { defaultDataTransformer } from '@aspectus/resource-commons';
import { createFileSender, createResource, createSender } from '@md/resources';
import { prefixAPI } from '@md/api.js';
import { headersMiddleware } from '@aspectus/resource';

const chunkUploadMiddleware = headersMiddleware(parameters => {
  parameters['Tus-Resumable'] = '1.0.0';
  parameters['Content-Type'] = 'application/offset+octet-stream';
  return parameters;
});
export const FILES_UPLOAD_URL = prefixAPI('/files/upload/');
export const IMAGE_UPLOAD_URL = prefixAPI('/files/image/upload/');
export const IMAGE_DELETE_URL = prefixAPI('/files/image{/id}/delete/');
export const VIDEO_UPLOAD_URL = prefixAPI('/files/video/upload/');
export const CHUNK_UPLOAD_URL = '/upload/upload/{uploadUrl}';

export const chunkCreateResource = createResource(CHUNK_UPLOAD_URL)
  .config('method', 'POST');
export const chunkUploadResource = createResource(CHUNK_UPLOAD_URL)
  .middleware(chunkUploadMiddleware)
  .config('method', 'PATCH');

export const filesUploadResource = createFileSender(FILES_UPLOAD_URL)
  .transform(defaultDataTransformer);
export const imageUploadResource = createFileSender(IMAGE_UPLOAD_URL)
  .transform(defaultDataTransformer);

export const videoUploadResource = createSender(VIDEO_UPLOAD_URL)
  .transform(defaultDataTransformer);

export const imageDeleteResource = createSender(IMAGE_DELETE_URL)
  .transform(defaultDataTransformer).config('method', 'DELETE');
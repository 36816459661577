<template>
  <resource-loader-controller :resource="listResource" v-slot="allProps">
    <resource-loader-controller :resource="availableResource" v-slot="availableProps">
      <search-filter
        :value="filters"
        :permanent="permanent"
        @input="changeFilters"
        :allProps="allProps"
        :availableProps="availableProps"
        v-bind="$attrs"
        :hide-filters-controls="hideFiltersControls"
        :hide-search-button="hideSearchButton"
        :hide-clear-button="hideClearButton"
      >
        <template #append="scope">
          <slot name="append" v-bind="scope"/>
        </template>
      </search-filter>
    </resource-loader-controller>
  </resource-loader-controller>
</template>

<script>

import SearchFilter from './View.vue';

export default {
  components: { SearchFilter },
  inheritAttrs: false,
  props: [
    'filters', 'availableResource', 'listResource', 'permanent', 'hideSearchButton', 'hideFiltersControls', 'hideClearButton',
  ],

  methods: {
    changeFilters(value) {
      this.$emit('update:filters', value);
    },
  },
};

</script>

import { createSender, r } from '@md/resources';

export const userInfoResource = r('/profile/retrieve/');
export const userUpdateResource = r('/profile/update/', createSender).config('method', 'PATCH');

export const users = {
  list: r('/users/list/{?limit,offset,s}'),
};

export const auth = {
  registration: r('/auth{/user_type}/register/', createSender),
  quickRegistration: r('/auth{/user_type}/short-register/', createSender),
  login: r('/auth/login/', createSender),
  hotelierRegister: r('/auth/hotelier-register/', createSender),

  emailVerify: {
    registerSend: r('/auth/email-verify/register/send/', createSender),
    registerConfirm: r('/auth/email-verify/register/confirm/', createSender),
    socialSend: r('/auth/email-verify/social/send/', createSender),
    socialConfirm: r('/auth/email-verify/social/confirm/', createSender),
    changeSend: r('/auth/email-change/send/', createSender),
    changeConfirm: r('/auth/email-change/confirm/', createSender),
    sendLink: r('/auth/email-verify/register/send-link/', createSender),
  },

  user: {
    info: userInfoResource,
    update: userUpdateResource,
    // partialUpdate: userPartialUpdateResource,
  },
  password: {
    reset: r('/auth/password/reset/send/', createSender),
    confirm: r('/auth/password/reset/confirm/', createSender),
    change: r('/auth/password/change/', createSender),
  },
  moderators: r('/profile/moderators/list/'),
  addons: {
    accosts: r('/profile/addons/accosts/list/'),
  },
};

export const positions = {
  list: r('/positions/list/{?filters,limit,offset}'),
  signature: r('/positions/signature/list/'),
};

export const referrals = {
  accounts: {
    retrieve: r('/referrals/accounts/retrieve/'),
    update: r('/referrals/accounts/update/', createSender).config('method', 'PATCH'),
  },
  applications: {
    hotels: r('/referrals/applications/hotels/', createSender),
  },
  relations: {
    base: {
      list: r('/referrals/relations/base/list/{?filters,limit,offset,order_by}'),
    },
    availableHoteliers: {
      list: r('/referrals/relations/available-hoteliers/{?filters,limit,offset,order_by}'),
    },
    list: r('/referrals/relations/list/{?filters,limit,offset,order_by}'),
    filters: {
      list: r('/referrals/relations/filters/list/'),
      available: r('/referrals/relations/filters/list/{?filters}'),
    },
    retrieve: r('/referrals/relations{/id}/retrieve/'),
  },
  configs: {
    registerLinks: r('/referrals/configs/register-rewards/{?limit,offset}'),
    reward: r('/referrals/configs/reward/update/'),
  },
  payments: {
    filters: {
      available: r('/referrals/payments/filters/list/{?filters}'),
      list: r('/referrals/payments/filters/list/'),
    },
    list: r('/referrals/payments/list/{?filters,limit,offset}'),
    aggregate: r('/referrals/payments/aggregate/{?filters}'),
  },
  regional: r('/referrals/regional/accept/', createSender),
  update: r('/referrals/regional{/id}/update/', createSender).config('method', 'PATCH'),
};

<template lang="pug">
include /mixins.pug
password-reset-confirm-form(
  :sign="sign"
)
</template>
<script>
const PasswordResetConfirmForm = () => import('./Forms/PasswordResetConfirmForm.vue');
export default {
  name: 'password-reset-confirm',
  components: {
    PasswordResetConfirmForm,
  },
  props: {
    sign: {
      type: String,
      required: true,
    },
  },
};

</script>

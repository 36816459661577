import { defineStore } from 'pinia';
import {
  userInfoResource as receiveResource,
  userUpdateResource as patchResource,
} from '@md/users/api.js';
import storage from '@aspectus/json-local-storage';
import { User as UserClass } from './user/user';

export const useAuthStore = defineStore('authStore', {
  state: () => ({
    loading: false,
    user: new UserClass({}, false),
  }),

  getters: {
    isAuthenticated() {
      return this.user.isAuthenticated();
    },
    isDetermined() {
      return this.user.determined;
    },
    isSimpleMode() {
      const simpleMode = storage.get('simpleMode');

      if (null === simpleMode) {
        return true;
      }

      return simpleMode;
    },
  },

  actions: {

    setUser(newUser) {
      this.user = newUser;
    },
    updateUser(data) {
      this.user = new UserClass({ ...this.user.info, ...data });

      if (data.country) {
        window.USER_PROFILE.country = data.country;
      } else if (data.countries) {
        window.USER_PROFILE.country = data.countries;
      }
    },
    setLoading(value) {
      this.loading = value;
    },
    initialReceive() {
      if (this.user.determined) {
        return null;
      }
      return this.lockedReceive();
    },
    lockedReceive() {
      if (this.loading) {
        return null;
      }

      return this.receive();
    },
    receive() {
      this.setLoading(true);

      return receiveResource.execute()
        .then(({ data: { item = {} } = {} }) => {
          this.setUser(new UserClass(item));
        }).catch(error => {
          this.setUser(new UserClass({}));
          console.warn(error);
        }).finally(() => { this.setLoading(false); });
    },

    patch(payload) {
      return patchResource.execute({}, payload)
        .then(({ data: { item = {} } = {} }) => {
          this.updateUser(item);
        });
    },
  },

});

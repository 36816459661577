import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import Cookie from 'js-cookie';
import { pageHints } from './api';

export const usePageHintsStore = defineStore('pageHintsStore', () => {
  const hintsList = ref({});
  const hintsHash = Cookie.get('page_hints:state_hash');

  const getBlockHint = computed(() => key => hintsList.value[key] || null);

  function getHints(filters) {
    return pageHints.retrieve.execute({ filters })
      .then(({ data: { item } }) => {
        this.hintsList = item;
      });
  }

  return {
    hintsList,
    hintsHash,
    getBlockHint,
    getHints,
  };
});

import { route } from '@md/routing';

const Edit = () => import('./views/Edit.vue');
const HotelsList = () => import('./views/HotelsList.vue');

const PROFILE_PREFIX = 'hotelier_chain:profile:';
const p = name => PROFILE_PREFIX + name;
export const HOTELIER_CHAIN_PROFILE_EDIT = p('edit');
export const HOTELIER_CHAIN_PROFILE_HOTELS_LIST = p('hotels_list');

export function createRoutes() {
  return [
    route('hotels-list/', HotelsList, HOTELIER_CHAIN_PROFILE_HOTELS_LIST, {
    }),
    route('edit/', Edit, HOTELIER_CHAIN_PROFILE_EDIT, {
    }),
    { path: '', redirect: { name: HOTELIER_CHAIN_PROFILE_EDIT } },
    // route('create/', Create, AGENT_APPLICATIONS_CREATE, {
    //   meta: accessPermission(APPLICATIONS_CREATE, options),
    // }),
  ];
}

import { modalOpener } from '@utils/helpers.js';

export function authGuardConfig(config) {
  if (!window.IS_AUTHENT) {
    return {
      modal: () => import('@md/auth/components/Login.vue'),
      props: { next: window.location.pathname },
      config: { width: 690 },
    };
  }
  return config;
}
export function authGuardChecher(context) {
  if (!window.IS_AUTHENT) {
    modalOpener.call(context, {
      modal: () => import('@md/auth/components/Login.vue'),
      props: { next: window.location.pathname },
      config: { width: 690 },
      classes: ['login-modal'],
    });
  }
}

<template lang="pug">
  include /mixins.pug

  .relative
    +b.VALIDATION-OBSERVER(
      ref="validator",
      v-slot="{ valid, handleSubmit }",
      slim
    ): form(@submit.prevent='handleSubmit(prepareData)')
      +b.g-row--appearance_spaced.--space_xl
        +b.g-cell.g-cols--12
          d-old-input(
            :float-label="labels.name"
            rules="required",
            v-model="form.name"
            id="name",
            name="name",
          )
        +b.g-cell.g-cols--12
          d-old-input(
            :float-label="labels.theme"
            rules="required",
            v-model="form.theme"
            id="theme",
            name="theme",
          )
        +b.g-cell.g-cols--12.--6-lg
          d-old-input(
            rules="required|phone",
            :float-label="labels.phone"
            v-model="form.phone",
            ref="phone"
            name="phone"
            type="tel"
            :mask="['+## ### #######', '+## ### #######', '+## ### ########', '+## ### ########']"
            @focus="() => { form.phone = form.phone || '+'; }"
          )
        +b.g-cell.g-cols--12.--6-lg
          d-old-input(
            rules="required|email",
            :float-label="labels.email"
            v-model="form.email",
            name="email"
            type="email"
          )
        +b.g-cell.g-cols--12
          d-old-textarea(
            :float-label="labels.comment"
            rules="required",
            rows="5"
            v-model="form.comment",
            name="comment"
          )
      ds-panel(:space="['lg', '3xl-lg']"): ds-panel-element(offset="top")
        +b.g-row--justify_end.--appearance_spaced.--space_xl
          +b.g-cell.g-cols--6-lg
            control-old-button(
              :size="['md', 'lg-lg']"
              styling="brand-2",
              appearance="full"
              type="submit"
              :disabled="loading",
            ) {{ _("Отправить") }}
    ui-loading(v-if="loading")

</template>
<script>
import Controller from './Controller';

export default {
  name: 'create-appeals',
  mixins: [Controller],
};
</script>

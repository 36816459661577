import { defaultDataTransformer } from '@aspectus/resource-commons';
import { createReceiver, createSender } from '@md/resources';
import { prefixAPI } from '@md/api.js';

const r = (tpl, f = createReceiver) => f(prefixAPI(tpl)).transform(defaultDataTransformer);

export const chat = {
  rooms: {
    list: r('/chat/rooms/list/{?order,limit,cursor,created_till,filters}'),
    create: r('/chat/rooms/create/', createSender),
    filters: {
      list: r('/chat/rooms/filters/list/'),
      available: r('/chat/rooms/filters/list/{?filters}'),
    },
  },
  messages: {
    list: r('/chat/messages{/id}/list/{?order,limit,cursor,created_till}'),
    unread: r('/chat/messages/unread/count/'),
    create: r('/chat/messages/create/', createSender),
    delete: r('/chat/messages/create/', createSender),
    read: r('/chat/messages/read/', createSender),
    update: r('/chat/messages/update/', createSender).config('method', 'PATCH'),
  },
};
export const online = {
  set: createSender('/online/set/'),
};

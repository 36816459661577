import { defaultDataTransformer } from '@aspectus/resource-commons';
import { createReceiver, createSender } from '@md/resources';
import { prefixAPI } from '@md/api.js';

const r = (tpl, f = createReceiver) => f(prefixAPI(tpl)).transform(defaultDataTransformer);

export const appeals = {
  create: r('/appeals/create/', createSender),
  addons: {
    topic: {
      list: r('/appeals/addons/topic/list/{?limit,offset}'),
    },
  },
};

import throttle from 'lodash.throttle';

import { prefixLanguage } from '@md/urls.js';
// import Confirm from '@app/UiElements/Confirm'

// export function confirmWithPromise(question, $this) {
//   return new Promise((res, rej) => {
//     const pr = arg => new Promise((resolve, reject) => {
//       if (true === arg) {
//         resolve(arg)
//       } else {
//         reject(new Error('...'))
//       }
//     }).then(result => {
//       res(result)
//     }, () => {
//       rej()
//     })
//     $this.$modal.show(
//       Confirm,
//       {
//         question,
//         promise: pr,
//       }, {
//         height: 'auto',
//         width: '300px',
//         adaptive: true,
//       }
//     )
//   })
// }
export function getSelection() {
  const offset = 10;
  const selection = document.getSelection();
  const selectionText = selection.toString();
  if (window.getSelection && !window.getSelection().isCollapsed && selectionText) {
    const range = selection.getRangeAt(0);
    const preRange = document.createRange();
    const postRange = document.createRange();
    preRange.setStartBefore(range.startContainer);
    preRange.setEnd(range.startContainer, range.startOffset);
    postRange.setStart(range.endContainer, range.endOffset);
    postRange.setEndAfter(range.endContainer);
    const pre = preRange.toString().substr(-offset);
    const post = postRange.toString().substr(0, offset);
    const result = `${pre }<mark>${ selectionText }</mark>${ post}`;
    return {
      highlighted: result,
    };
  }
  return {};
}
export function headerSticky() {
  const { body } = document;
  const scrollUpClass = 'scroll-up';
  const scrollDownClass = 'scroll-down';
  let lastScroll = 0;
  window.addEventListener('scroll', () => {
    const currentScroll = window.pageYOffset;
    if (0 >= currentScroll) {
      body.classList.remove(scrollUpClass);
      return;
    }

    if (currentScroll > lastScroll && !body.classList.contains(scrollDownClass)) {
    // down
      body.classList.remove(scrollUpClass);
      body.classList.add(scrollDownClass);
    } else if (currentScroll < lastScroll && body.classList.contains(scrollDownClass)) {
    // up
      body.classList.remove(scrollDownClass);
      body.classList.add(scrollUpClass);
    }
    lastScroll = currentScroll;
  }, { passive: true });
}

const ModalBaseTemplate = () => import('@md/ui/general/ModalTemplate.vue');

export function modalOpener({
  modal = ModalBaseTemplate,
  props = {},
  reaction = () => {},
  value = undefined,
  classes = [],
  config = {},
  eventsListeners = {},
}) {
  const promise = arg => new Promise((resolve, reject) => {
    if (arg !== undefined) {
      resolve(arg);
      return;
    }
    reject();
  }).then(result => {
    reaction(value || result);
  }).catch(err => {
    console.warn('helpers.js', err);
  });

  this.$modal.show(
    modal,
    { ...props, promise }, {
      classes,
      ...config,
      focusTrap: false,
    }, {
      ...eventsListeners,
    }
  );
}

export function getVideoId(url) {
  const regExpYoutube = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const regExpVimeo = /^.*(?:vimeo.com)\/(?:channels\/|channels\/\w+\/|groups\/[^\/]*\/videos\/|album\/\d+\/video\/|video\/|)(\d+)(?:$|\/|\?)/;
  const matchY = url.match(regExpYoutube);
  const matchV = url.match(regExpVimeo);
  const youtubeIdLength = 11;
  let embedUrl;
  let preview;
  if (matchY && matchY[2].length === youtubeIdLength) {
    const id = matchY[2];
    embedUrl = `https://www.youtube.com/embed/${id}?modestbranding=1&rel=0&autoplay=1&mute=0&iv_load_policy=3`;
    // this.embedUrl = `https://www.youtube.com/embed/tgbNymZ7vqY?autoplay=1&mute=1"`;
    preview = `https://img.youtube.com/vi/${id}/hq720.jpg`;
    return { embedUrl, preview };
  }
  if (matchV && matchV[1]) {
    const id = matchV[1];
    embedUrl = `https://player.vimeo.com/video/${id}?title=0&byline=0&portrait=0&autoplay=1`;
    preview = `https://vumbnail.com/${id}.jpg`;
    return { embedUrl, preview };
  }
  throw new Error('error parsed link');
}
/* eslint-disable */
export function debounce(func, wait, immediate) {
  let timeout;
  return function (...rest) {
    const context = this;
    const args = rest;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export function toBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export function countOfPlur(count, keywords) {
  const pr = new Intl.PluralRules(window.language);
  const intlCount = pr.select(count);
  const collection = {
    one: `${count} ${keywords[0]}`,
    few: `${count} ${keywords[1]}`,
    many: `${count} ${keywords[2]}`,
  };
  return collection[intlCount] || collection.few;
}

export function changeLanguage(langCode) {
  const url = window.location.href;
  document.cookie = `CHOSEN_LANGUAGE=${langCode}; path=/;`;
  window.location.assign(decodeURIComponent(prefixLanguage(url, { language: langCode })));
}

export function disableBeforeDate({ date = new Date(), include = true }) {
  return d => d < new Date(date)
    .setHours(include ? 0 : 24, 0, 0, 0);
}

const DateTimeUnits = {
  year: 24 * 60 * 60 * 1000 * 365,
  month: 24 * 60 * 60 * 1000 * 365 / 12,
  day: 24 * 60 * 60 * 1000,
  hour: 60 * 60 * 1000,
  minute: 60 * 1000,
  second: 1000,
};

const rtf = new Intl.RelativeTimeFormat(window.language || 'en', { numeric: 'auto' });

export const getRelativeTime = (d1, d2 = new Date()) => {
  const elapsed = d1 - d2;

  // eslint-disable-next-line no-restricted-syntax
  for (const u in DateTimeUnits) {
    if (Math.abs(elapsed) > DateTimeUnits[u] || 'second' === u) {
      return rtf.format(Math.round(elapsed / DateTimeUnits[u]), u);
    }
  }
  return new Intl.DateTimeFormat(window.language, {
    timeStyle: 'medium',
    dateStyle: 'short',
  }).format(d1);
};

export function mainPageScrollMenu(sections = [], links = [], linksContainer) {
  let current;
  window.document.addEventListener('scroll', throttle((e) => {
    sections.forEach(el => {
      const top = el.offsetTop;
      const bottom = top + el.offsetHeight;
      const scroll = window.pageYOffset + (window.innerHeight / 3);
      const { id } = el.dataset;
      if (scroll > top && scroll < bottom) {
        if (current !== id) {
          current = id;
          links.forEach(element => {
            element.classList.remove('is-active');
          });

          links.forEach(link => {
            if (id === link.dataset.href) {
              link.classList.add('is-active');
              if (linksContainer) {
                const { activeElement, activeElement: { parentElement } } = document;
                if (parentElement.dataset.href || activeElement.dataset.go_to_top) {
                  setTimeout(() => {
                    activeElement.blur();
                  }, 3000);
                } else if (!parentElement.dataset.href) {
                    linksContainer.scrollTop = (link.offsetTop + link.clientHeight) - linksContainer.clientHeight / 2;
                }
              }
            }
          });
        }
      } else {
        links.forEach(link => {
          if (id === link.dataset.href) {
            link.classList.remove('is-active');
          }
        });
      }
    });
  }), 500);
}

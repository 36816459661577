import { formatTranslation } from '@utils/strings';

/**
 * Returns the valid date object(date, or Invalid date)
 * @param {Date object} date
 * @returns {Date object}
 */
export const normalizeDate = date => (
  date instanceof Date && !Number.isNaN(date) && date || new Date()
);
export const parseDate = date => normalizeDate(new Date(date));
export const getInitialsFromName = ([firstName = '', lastName = ''] = []) => `${firstName[0] || ''}${lastName[0] || ''}`;

export const install = Vue => {
  Vue.filter('normalizeDate', normalizeDate);
  Vue.filter('parseDate', parseDate);
  Vue.filter('format', formatTranslation);

  Vue.filter('splitPhoneNumber', value => value && value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' '));
  Vue.filter('getAvatarLabel', getInitialsFromName);
};

export default { install };

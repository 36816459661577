import { renderSlim } from '@aspectus/vue-utils';
import { pick, omit } from 'ramda';
import { AggregateLoaderMixin } from './mixins';

export function orderingTo(ordering) {
  if (!(ordering || ordering.length)) {
    return undefined;
  }
  if ('object' === typeof ordering) {
    return ('desc' === ordering.direction ? '-' : '') + ordering.field;
  }

  return ordering.map(x => ('desc' === x.direction ? '-' : '') + x.field).filter(x => !!x).join(',');
}

export function parseOrdering(field) {
  const desc = field.startsWith('-');

  if (desc) {
    return { field: field.slice(1), direction: 'desc' };
  }

  return { field, direction: 'asc' };
}

export function orderingFrom(ordering) {
  return (ordering || '').split(',').map(parseOrdering);
}

export default {
  name: 'catalog-pagination-controller',
  mixins: [AggregateLoaderMixin],
  props: {
    immediate: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    if (this.immediate) this.initialize();
  },

  computed: {
    ordering() {
      return orderingFrom(this.parameters?.order_by || null);
    },
  },
  methods: {
    resetToFirstPage() {
      this.pagination.offset = 0;
      this.receive(this.parameters);
    },
    initialize() {
      this.receive(this.parameters);
      if (this.aggregateResource) this.receiveAggregate(this.parameters);
    },
    changeOrdering(ordering) {
      this.parameters.order_by = orderingTo(ordering);
      this.parametersUpdate(omit(this.filterPaginationDropKeys, {
        ...this.filters,
        ...pick(this.paginationKeys, this.pagination),
        ...pick(this.onlyParametersKeys, this.parameters),
      }));
    },
  },
  render(h) {
    if (!this.$scopedSlots.default) {
      return null;
    }

    const nodes = this.$scopedSlots.default(
      {
        parameters: this.parameters,
        ordering: this.ordering,
        filters: this.filters,
        changeFilters: this.changeFilters,
        pagination: this.pagination,
        resetToFirstPage: this.resetToFirstPage,
        changePagination: this.changePagination,
        loadMore: this.loadMore,
        changeOrdering: this.changeOrdering,
        receive: this.receive,
        receiveAggregate: this.receiveAggregate,
        loading: this.loading,
        result: this.result,
        aggregate: this.aggregate,
      }
    );

    return renderSlim(Array.isArray(nodes) ? nodes : [], h, 'tag');
  },
};

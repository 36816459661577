<template lang="pug">
include /mixins
+b.slide--review.ds-card--variant_rounded_sm.--appearance_dark
  ds-panel(space="2xl")
    ds-panel-element(offset="full")
      row(
        appearance="spaced",
        align="center",
      )
        cell(cols="auto")
          ds-panel
            ds-panel-element
              ds-caption(
                size="lg"
              ) {{ item.agent.displayName }}
            ds-panel-element
              ds-caption(
                size="xs"
              ) {{ $d(new Date(item.createdAt), 'date') }}
        cell(cols="narrow")
          +b.mention-card--variant_line-right(
            :style="{ '--line-color': color }"
          )
            +e.content
              +e.rating {{ item.rating }}
        cell(cols="12")
          ui-toggler(
            v-slot="{ active, toggleActive }",
          ): div

            row(justify="end", align="center")
              cell(cols="narrow")
                +b.BUTTON.ds-link--styling_inherit(
                  type="button",
                  :aria-label="_('See detail')"
                  @click.prevent="toggleActive"
                )
                  row(justify="end", align="center")
                    cell(cols="narrow")
                      ds-caption(

                      ) {{ active ? _("Hide") : _("Show more ")}}
                    cell(cols="narrow")
                      ds-inliner(size="md", aria-hidden="true")
                        +b.icon--size_4xs.icon-chevron_up(
                          :class="{ 'is_rotate_180': !active}"
                        )
            transition(name='slidedown'): .slidedown-will-change(v-show="active")

              row(
                appearance="spaced"
              )
                cell(
                  v-for="(category, index) in item.categoryRatingItems",
                  :key="index",
                  cols="12"
                )
                  rating-input(
                    :category="category.category",
                    :key="`${category.id}_${index}`",
                    v-model="category.rating"
                    :disabled="true"
                  )
                cell(cols="12")
        cell(cols="12")
          ds-panel: ds-panel-element(offset="top")
            ds-caption(
              size="sm"
            ) {{ item.text }}
</template>
<script>

import { ReviewColors } from '@md/reviews/const.js';

import RatingInput from '@md/reviews/components/RatingInput.vue';

export default {
  components: { RatingInput },
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    this.colorsMap = ReviewColors;
    return {

    };
  },
  computed: {
    color() {
      const rating = +(this.item?.rating);
      return this.colorsMap[rating.toFixed(0)] || 'transparent';
    },
  },
};
</script>

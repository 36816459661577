import { route } from '@md/routing';

const View = () => import('./View');

export const HOTELIER_CHAIN_CHATS = 'hotelier_chain:chats';

export function createRoutes() {
  return [
    route('', View, HOTELIER_CHAIN_CHATS),
  ];
}

import { route } from '@md/routing';

const FaqDisplay = () => import('@md/faq/components/Display.vue');

export const AGENT_FAQ = 'agent:faq';

export function createRoutes() {
  return [
    route('', FaqDisplay, AGENT_FAQ),
  ];
}

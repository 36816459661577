<template lang="pug">
include /mixins.pug
div
  +b.text-accordion(
    :style="{ maxHeight: `${contentHeight}px`,  }",
    :class="[isShowText || !isShowBtn ? 'is-active' : '', ...classes]"
    ref="text"
  )
    slot
  slot(
    v-if="isShowBtn"
    name="toggle-button"
    :is-show-text="isShowText"
    :set-height="setHeight"
  )
    +b.BUTTON.ds-link--styling_brand-2(
      type="button",
      @click="setHeight",
    ) {{ isShowText ?  _('Свернуть') : _(' Показать ') }}
</template>

<script>
export default {
  name: 'text-accordion',
  props: {
    visibleHeight: {
      default: 0,
    },
    classes: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      isShowBtn: false,
      isShowText: false,
      contentHeight: this.visibleHeight,
      scrollHeight: 0,
    };
  },
  created() {
    this.innerHeight();
  },
  methods: {
    innerHeight() {
      const delay = 500;
      setTimeout(() => {
        if (this.$refs.text.scrollHeight > this.visibleHeight) {
          this.scrollHeight = this.$refs.text.scrollHeight;
          this.$refs.text.style.maxHeight = `${this.visibleHeight}px`;
          this.isShowBtn = true;
        }
      }, delay);
    },
    setHeight() {
      this.isShowText = !this.isShowText;
      if (this.isShowText) {
        this.contentHeight = this.scrollHeight;
        return;
      }
      this.contentHeight = this.visibleHeight;
    },
  },
};
</script>

import aos from 'aos';

const mediaQueryMotion = window.matchMedia('(prefers-reduced-motion: reduce)');

export function aosInitiate(options = {}) {
  aos.init({
    once: true,
    disable: mediaQueryMotion.matches,
    ...options,
  });
}

<template lang="pug">
  wds-chat-provider(:feed='feed')
    div
      wds-chat-audio(
        url="https://back.files.design/media/1586",
        ref="messageAudio"
        :on="['chat:message:new']"
      )
      slot(:feed="feed", :chats-count="counter" :chat-id="chatId")
</template>
<script>

import { chat, online } from '@md/chat/api.js';
import {
  mapState,
} from 'pinia';
import { useAuthStore } from '@md/auth/store.js';
import {
  MESSAGE_NEW,
  // MESSAGE_RECEIVED,
  // MESSAGE_UPDATED,
  // TYPING_STARTED,
  // TYPING_STOPPED,
  // ROOM_NEW,
  ROOM_UPDATED,
  // ROOM_REMOVED,
  // AUTHOR_UPDATED,
  GLOBAL_UNREAD,
} from './DataFeed/const.js';

import Feed from './DataFeed/DataFeed.js';

const { SEND_ONLINE_INTERVAL = 3 } = window.CONFIG;

export default {
  props: {
    reversed: {
      type: Boolean,
      default: window.USER_REVERSED,
    },
  },
  provide() {
    return {
      chatsCounter: this.counter,
      setRoomFocus: this.setRoomFocus,
    };
  },
  data() {
    return {
      feed: new Feed(),
      counter: {
        count: 0,
      },
      focusedRooms: {},
      chatId: 0,
    };
  },
  computed: {
    ...mapState(useAuthStore, [
      'user',
    ]),
    userId() {
      return this.user.info.auth;
    },
  },

  created() {
    this.feed.emitter.on(MESSAGE_NEW, this.handleNewMessage);
    this.feed.emitter.on(GLOBAL_UNREAD, this.handleChatsCount);
    this.feed.emitter.on(ROOM_UPDATED, this.updateRoom);
    if (window.IS_AUTHENT) {
      this.sendOnline();
      const ping = setInterval(this.sendOnline, SEND_ONLINE_INTERVAL);
      this.$once('hook:beforeDestroy', () => { clearInterval(ping); });
      chat.messages.unread
        .execute()
        .then(({ count }) => { this.counter.count = count; })
        .catch(() => null);
    }
  },

  methods: {
    sendOnline() {
      return online.set.execute();
    },
    updateRoom(event) {
      const { id = null } = event?.body;
      this.chatId = id || Date.now();
      this.$nextTick().then(() => {
        this.chatId = 0;
      });
    },
    setRoomFocus(id, value) {
      this.focusedRooms = { [id]: value };
    },
    handleNewMessage(event) {
      const {
        body: { room, authorId },
      } = event;

      if (
        !this.focusedRooms[room] &&
        authorId !== this.userId
      ) {
        this.$toast.success(this._('New message'));
        this.playSound();
        return;
      }
      this.playSound();
    },
    playSound() {
      if (this.$refs.messageAudio) {
        this.$refs.messageAudio.sound();
      }
    },
    handleChatsCount(event) {
      this.counter.count = event.body.count;
    },
  },
};

// const data = {
//   method: 'message',
//   body: {
//     uid: '4dv1dNlhTUlr2k66CGDZRm',
//     channel: '$user_channel_1',
//     data: {
//       message: '',
//       channel: '$user_channel_1',
//       event_class: 'room:update',
//       data: {
//         id: 'c914292c-5492-4a97-8a88-107e0c6a48a3',
//         userFrom: {
//           id: 1,
//           username: 'admin',
//           displayName: 'Marian Chorney 2008',
//           avatar:
//             'https://medbirzha.webcase-dev.com/uploads/b13dbdbd-91f1-4816-a9ee-98c37f712f44.jpg',
//         },
//         userTo: {
//           id: 21,
//           username: 'Kate766',
//           displayName: 'Webcase',
//           avatar: null,
//         },
//         job: {
//           id: 30,
//           title:
//             '\u041c\u0435\u0434\u0441\u0435\u0441\u0442\u0440\u0430
// \u0434\u043b\u044f \u0440\u0430\u0431\u043e\u0442\u044b \u0432 \u043d\u043e\u0447\u043d\u044b\u0435 \u0441\u043c\u0435\u043d\u044b',
//           authorId: 21,
//         },
//         isStraight: false,
//         unreadMessagesCount: 8,
//       },
//       site: 'MedicalPortal',
//     },
//   },
// };
</script>

<template lang='pug'>
  url-parameters-controller(
    @history-change="manualHistoryChangeHandler"
    :initial="initial"
    :base-url='baseUrl'
    :to="parametersTo"
    :from="parametersFrom"
    :drop-page-url="dropPageUrl"
    v-slot="{ parameters, changeParameters, urlWithoutPage }"
  )
    paginated-resource-controller(
      :resource="productsResource"
      :parameters="parameters"
      :changeParameters="changeParameters"
      :label='label',
      v-slot="paginatedProps"
    )
      controller(
        ref="controller"
        :url-without-page="urlWithoutPage",
        :filter-controller="filterController",
        :attributes-resource='attributesResource'
        :available-attributes-resource='availableAttributesResource'
        v-bind="paginatedProps",
        :need-filters='needFilters',
        :need-sorting='needSorting'
        :need-search='needSearch'
        :has-recommendedTags="hasRecommendedTags"
        :recommended-tags="recommendedTags",
        :label='label',
        :sortlist='sortlist || undefined'
        :scroll-paginate="scrollPaginate"
      )
        slot
</template>

<script>
import { mapActions } from 'pinia';

import {
  friendlyUrlParser,
} from '@md/filter/transformers';
import { useSearchStore } from '@md/Search/store.js';
import Controller from '@md/filtersList/Controller.vue';

export default {
  components: { Controller },
  props: {
    recommendedTags: {},
    initial: {

    },
    baseUrl: {},
    productsResource: {},
    attributesResource: {},
    availableAttributesResource: {},
    needFilters: {},
    needSorting: {},
    needSearch: {},
    scrollPaginate: {},
    hasRecommendedTags: {},
    label: {},
    sortlist: {

    },
    dropPageUrl: {
      type: Boolean,
    },
    filterController: {},
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
    ...mapActions(useSearchStore, { setStoreSearch: 'setSearch' }),
    parametersTo({ filters, offset, ...rest }) {
      return {
        ...rest,
        filters: filters || undefined,
        label: this.label,
      };
    },
    parametersFrom({ ...rest }) {
      const data = friendlyUrlParser(`${this.baseUrl}filters/`);
      if (data.search) {
        this.setStoreSearch(data.search[0]);
      }

      return {
        ...rest,
        filters: data,
      };
    },

    manualHistoryChangeHandler() {
      if (this.$refs.controller) {
        this.$nextTick(this.$refs.controller.search);
      }
    },
  },
};

</script>

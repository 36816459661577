import { route } from '@md/routing';

const List = () => import('./views/List');

export const TOUROPERATOR_REVIEWS_LIST = ':touroperator:reviews:list';

export function createRoutes() {
  return [
    route('list/', List, TOUROPERATOR_REVIEWS_LIST),
    { path: '', redirect: { name: TOUROPERATOR_REVIEWS_LIST } },
  ];
}

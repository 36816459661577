<template lang="pug">

include /mixins.pug

+b.cabinet-sidebar.--variant_default.d-flex.direction-column.justify-between(
  v-bem:cabinet-sidebar="{ condensed: condensed }"
)
  +e.header(v-if="$scopedSlots.header"): slot(name="header")
  +e.content(:class="contentClasses"): slot
  +e.simple-mode(v-if="isSimpleMode", :class="{'mobile': !isDesktop}")
    simple-mode(:is-menu-hidden="condensed")
  +e.footer(v-if="$scopedSlots.footer"): slot(name="footer")

</template>

<script>
import helperMixin from '@md/filter/mixins/HelperMixin';
import SimpleMode from './SimpleMode.vue';

export default {
  components: { SimpleMode },
  mixins: [helperMixin],
  props: {
    condensed: Boolean,
    isSimpleMode: Boolean,
    contentClasses: String,
  },
};

</script>

<style lang="sass">
  .mobile
    padding-bottom: 7rem
</style>

<script>

import { video } from '@md/video/api.js';

export default {
  props: {
    link: String,
    id: {
      type: [String, Number],
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
    },
  },
  data() {
    this.facebookAppId = window.CONFIG.FACEBOOK_APP_ID || 1;
    return {
      shareMessangerLink: `https://www.facebook.com/dialog/send?app_id=${this.facebookAppId}&link=${this.link}&redirect_uri=${this.link}`,
      isMobileDeviceType: false,
    };
  },
  mounted() {
    this.isMobileDeviceType = (!!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));
  },
  methods: {
    shareVideoStats(id) {
      return video.share.execute({ id });
    },
    async navigatorShare() {
      try {
        await navigator.share({ url: this.link, title: this.title, text: this.description });
      } catch {
        this.isMobileDeviceType = false;
      }
    },
  },
  render() {
    return this.$scopedSlots.default({
      shareMessangerLink: this.shareMessangerLink,
      shareVideoStats: this.shareVideoStats,
      link: this.link,
      isMobileDeviceType: this.isMobileDeviceType,
      navigatorShare: this.navigatorShare,
      item: { id: this.id, title: this.title, description: this.description },
    });
  },
};
</script>

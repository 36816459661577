<template lang="pug">
include /mixins
v-popover(
  :placement="tooltipPlacement",
  :delay="{ show: 100}",
)
  slot(
    :get-content="getDetailRating"
  )

  template(#popover="{ isOpen}" v-show="!loading")
    +b.hotel-rating-detail
      row(
        :appearance="['spaced']",
        :space="['none', 'md-sm']"
      )
        cell(
          v-for="(category, index) in items",
          :key="index",
          cols="12 6-sm",
        )
          row(justify="around"): cell(cols="12 11-sm")
            rating-input(
              :variant="'compact'",
              :category="category.category",
              :key="`${category.id}_${index}`",
              v-model="category.rating"
              :disabled="true"
            )

</template>
<script>
import LoadingStateMixin from '@aspectus/vue-loading-state-mixin';
import { reviews } from '@md/reviews/api.js';

const RatingInput = () => import('@md/reviews/components/RatingInput.vue');

export default {
  name: 'hotel-rating-detail',
  mixins: [LoadingStateMixin],
  components: { RatingInput },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
    tooltipPlacement: {
      type: String,
      default: 'top',
    },
  },

  data() {
    return {
      items: [],
      tooltip: {
        content: '',
        html: true,
        loadingContent: 'Please wait...',
        trigger: 'click, hover',
      },
    };
  },
  mounted() {
    // this.getDetailRating(this.id);
  },
  methods: {
    getDetailRating(hotel = this.id) {
      if (!this.loading && !this.items.length) {
        this.$load(reviews.hotelStatistics.execute({ hotel })).then(
          ({ data: { items } }) => {
            this.items = items;
          }
        );
      }
    },
  },
};
</script>

<template lang="pug">

include /mixins.pug

+b.cabinet-sidebar-menu-section(
  v-state="{ condensed: condensed }"
)
  +e.caption.TAG(
    :tag='to ? "router-link" : "div" '
    :to='to'
    v-if="label"
  )
    +e.icon(
      v-if="icon"
    )
      slot(
        name="icon",
        :icon="icon"
      ): span(
        :class="`icon-${icon}`",
        size="lg"
      )
    +e.label(
      v-if="label"
    )
      slot(
        name="label",
        :label="label"
      ) {{ label }}

  +e.content
    slot
      +e.UL.menu(v-if="menu")
        +e.LI.element(v-for="(element, i) in menu")
          slot(name="menu-element")
            cabinet-sidebar-menu-element(
              :condensed="condensed",
              :drop='element.drop'
              :initial-state='element.initialDropped'
              :active='active'
              :key="element.key",
              :slug="element.key",
              @open='changed(element.key)',
              v-bind="element",
            )

</template>

<script>
export default {
  name: 'cabinet-sidebar-menu-section',
  props: ['icon', 'to', 'condensed', 'label', 'menu', 'drop', 'activeState'],
  data() {
    return {
      active: '',
    };
  },
  methods: {
    changed($event) {
      this.active = $event;
    },
  },
};
</script>

<template lang="pug">

router-view(
  :result="result", :loadingResult="loading",
  :updateResult="update", :changeResult="change"
)

</template>

<script>

import { ResourceLoaderMixin } from '@md/ui/catalog/mixins';
import { prefixLanguage } from '@md/urls.js';

export default {
  mixins: [ResourceLoaderMixin],
  created() {
    this.update();
  },
  methods: {
    async applyError(result, promise) {
      if (this.$options.promise !== promise) {
        return;
      }

      if (404 === result.status) {
        window.location.assign(prefixLanguage('/404/', { language: window.language }));
        return;
      }

      if (403 === result.status) {
        window.location.assign(prefixLanguage('/403/', { language: window.language }));
        return;
      }

      this.$emit('error', result);
      throw result;
    },
    update() {
      this.receive({ id: this.$route.params.id });
    },
    change(data) {
      this.result = data;
    },
  },
};

</script>

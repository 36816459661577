import { route } from '@md/routing';

const View = () => import('./View');

export const REPRESENTATIVE_APPEALS = 'representative:appeals';

export function createRoutes() {
  return [
    route('', View, REPRESENTATIVE_APPEALS, {
    }),
    { path: '', redirect: { name: REPRESENTATIVE_APPEALS } },
  ];
}

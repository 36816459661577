import { route, prefixRoutes } from '@md/routing';

import { createRoutes as createBonusProgramRoutes } from './modules/bonus-programs/router.js';
import { createRoutes as createProfileRoutes } from './modules/profile/router.js';
import { createRoutes as createChatsRoutes } from './modules/chats/router.js';
import { createRoutes as createHotelsRoutes } from './modules/hotels/router.js';
import { createRoutes as createAppealsRoutes } from './modules/appeals/router.js';
import { createRoutes as createFaqRoutes } from './modules/faq/router.js';
import { createRoutes as createNotificationsRoutes } from './modules/notifications/router.js';
import { createRoutes as createTransactionsRoutes } from './modules/transactions/router.js';
import { createRoutes as createApplicationsRoutes } from './modules/applications/router.js';
import { createRoutes as createBookingRoutes } from './modules/booking/router.js';
import { createRoutes as createReviewsRoutes } from './modules/reviews/router.js';

const Route404 = () => import('@sections/404.vue');
const Home = () => import('./modules/home/views/View.vue');
const Statistics = () => import('./modules/statistics/Views.vue');

export const DMC_HOME = 'dmc:home';
export const DMC_STATISTICS = 'dmc:statistics';

export function createRoutes(options) {
  return [
    route('home/', Home, DMC_HOME),
    route('statistics/', Statistics, DMC_STATISTICS),

    prefixRoutes('profile/', createProfileRoutes(options)),
    prefixRoutes('bonus-program/', createBonusProgramRoutes(options)),
    prefixRoutes('chats/', createChatsRoutes(options)),
    prefixRoutes('notifications/', createNotificationsRoutes(options), {}),
    prefixRoutes('hotels/', createHotelsRoutes(options)),
    prefixRoutes('booking/', createBookingRoutes(options), {}),
    prefixRoutes('reviews/', createReviewsRoutes(options), {}),
    prefixRoutes('applications/', createApplicationsRoutes(options), {}),
    prefixRoutes('transactions/', createTransactionsRoutes(options)),
    prefixRoutes('appeals/', createAppealsRoutes(options)),
    prefixRoutes('faq/', createFaqRoutes()),
    { path: '', redirect: 'home/' },
    { path: '*', component: Route404 },
  ];
}

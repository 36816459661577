import { defineStore } from 'pinia';

export const useSearchStore = defineStore('search', {
  state: () => ({
    global_search: '',
  }),
  actions: {
    setSearch(value) {
      this.global_search = value;
    },
  },
});

/* eslint-disable */
import BemComponentMixin from '../../utils/bem/BemComponentMixin';

/**
 * Chat list display component.
 *
 * HOCs:
 *
 * - `feedChatListHandler` :: HOC to load chats from provider's feed.
 *  Also live updates contained chats on their modification.
 *
 * @version 1.0.0
 * @author [Alex Tkachenko](https://github.com/preusx)
 * @example ./Readme.md
 */
export default {
  name: 'WdsChatList',

  mixins: [BemComponentMixin],

  bem: {
    block: 'wds-chat-list',
    modifierProps: [],
  },

  props: {
    /**
     * Determines whether data is loading.
     */
    loading: {
      type: Boolean,
      default: false,
    },

    /**
     * Current user identifier.
     */
    userId: [Number, String],

    /**
     * Chat list to display.
     */
    chats: {
      type: Array,
    },

    /**
     * Currently selected chat.
     */
    activeId: {
      type: [Number, String],
      default: null,
    },
    initial: {
      type: String,
      default: null,
    },
    scrollerClass: {
      default: null,
    },
  },

  data() {
    return {
      search: null,
      chatList: [],
      labels: {
        search: this.pgettext('packages:vue-chat', 'Search')
      }
    };
  },

  mounted() {
    this.scrollerClass && this.scrollerClass.bind(this.$refs['chat-list-wrapper']);
    this.scrollerClass && this.scrollerClass.toBottom();
  },

  watch: {
    chats(val) {
      this.chatList = val;
    },
  },
  methods: {
    async filterSearch() {
      const params = this.search ? {
        filters: JSON.stringify({
          search: this.search,
        })
      } : {};

      this.$emit('filter', params);
    },

    resetSearch() {
      this.search = null;

      this.filterSearch();
    },

    isActive(chat) {
      if (null === this.activeId) return false;

      return this.activeId === chat.id;
    },

    select(chat) {
      this.$emit('input', chat);
    },

    filter(parameters) {
      this.$emit('filter', parameters);
    },

    scroll(event) {
      this.$emit('scroll', event);
    },
  },
};

import { route } from '@md/routing';

const List = () => import('./views/List');

export const HOTELIER_CHAIN_REVIEWS_LIST = ':hotelier_chain:reviews:list';

export function createRoutes() {
  return [
    route('list/', List, HOTELIER_CHAIN_REVIEWS_LIST),
    { path: '', redirect: { name: HOTELIER_CHAIN_REVIEWS_LIST } },
  ];
}

import { route } from '@md/routing.js';

const List = () => import('./views/List');

const HOTELS_PREFIX = 'admin:questions-answers:';
const p = name => HOTELS_PREFIX + name;

export const ADMIN_QUESTIONS_ANSWERS_LIST = p('list');

export function createRoutes() {
  return [
    route('list/', List, ADMIN_QUESTIONS_ANSWERS_LIST),
  ];
}

<template functional>
  <tag
    v-bind="data.attrs"
    v-on="data.on"
  >
      <div :class="props.eclass('prepend')">
        <slot name="prepend" />
      </div>

    <div
      v-bind="data.attrs"
      v-on="data.on"
      :class="[data.class, data.staticClass, props.bclass()]"
    >
      <div :class="props.eclass('content')">
        <slot name="content" />
      </div>

      <div
        :class="props.eclass('backdrop')"
        @click.prevent="data.on && data.on['backdrop-click']($event)"
      >
        <slot name="backdrop" />
      </div>

      <div :class="props.eclass('element')">
        <slot name="element" />
      </div>
    </div>
  </tag>
</template>

<script>

import { Tag } from '@aspectus/vue-tag';
import {
  createStylingFunctionalMixin, mapSame, BLOCK_MODIFIERS, BLOCK_STATES,
} from '@aspectus/vue-bem-styling';

const StylingMixin = createStylingFunctionalMixin({
  modifiers: { fullWidth: 'full-width', ...BLOCK_MODIFIERS },
  states: Object.assign(mapSame('opened', 'condensed', 'condensable'), BLOCK_STATES),
});

export default {
  name: 'drawer-layout',
  mixins: [StylingMixin],
  components: { Tag },
  props: {
    blockName: { type: String, default: 'drawer' },
    align: { type: String, default: 'right' },
    fullWidth: Boolean,
    condensable: Boolean,
    condensed: Boolean,
    opened: Boolean,
  },
};

</script>

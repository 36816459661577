<template lang="pug">
manager-confirm-form(
  v-bind="$attrs"
)
</template>
<script>
const ManagerConfirmForm = () => import('./Forms/ManagerConfirmForm.vue');
export default {
  name: 'manager-confirm',
  components: {
    ManagerConfirmForm,
  },

};

</script>

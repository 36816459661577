import { route } from '@md/routing';

const View = () => import('./View');

export const TOUROPERATOR_MANAGER_CHATS = 'touroperator-manager:chats';

export function createRoutes() {
  return [
    route('', View, TOUROPERATOR_MANAGER_CHATS),
  ];
}

import { route } from '@md/routing';

const View = () => import('./View');

export const HOTELIER_CHAIN_APPEALS = 'hotelier_chain:appeals';

export function createRoutes() {
  return [
    route('', View, HOTELIER_CHAIN_APPEALS, {
    }),
    { path: '', redirect: { name: HOTELIER_CHAIN_APPEALS } },
  ];
}

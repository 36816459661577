import { route } from '@md/routing';

const List = () => import('./views/List/index.vue');

export const HOTELIER_CHAIN_NOTIFICATIONS_LIST = 'hotelier_chain:hotel:notifications:list';

export function createRoutes() {
  return [
    route('list/', List, HOTELIER_CHAIN_NOTIFICATIONS_LIST),
    { path: '', redirect: { name: HOTELIER_CHAIN_NOTIFICATIONS_LIST } },
  ];
}

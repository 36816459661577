import { route } from '@md/routing.js';

const List = () => import('./views/List');

const NOTIFICATIONS_PREFIX = 'staff:notifications:';

const p = name => NOTIFICATIONS_PREFIX + name;

export const STAFF_NOTIFICATIONS_LIST = p('list');

export function createRoutes() {
  return [
    route('list/', List, STAFF_NOTIFICATIONS_LIST),
  ];
}

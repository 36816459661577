import { route } from '@md/routing';

const View = () => import('./views/View.vue');
const BaseConditions = () => import('./views/BasicConditions/View.vue');
const BonusGroups = () => import('./views/BonusGroups/View.vue');
const ReceivingRules = () => import('./views/ReceivingRules/View.vue');

const PROFILE_PREFIX = 'hotelier:bonus_program:';
const p = name => PROFILE_PREFIX + name;
export const HOTELIER_BONUS_PROGRAMS = p('view');
export const HOTELIER_BASIC_CONDITIONS = p('base_conditions');
export const HOTELIER_BONUS_GROUPS = p('bonus_groups');
export const HOTELIER_RECEIVING_RULES = p('receiving_rules');

export function createRoutes() {
  return [
    route('', View, HOTELIER_BONUS_PROGRAMS, {
      meta: {
        parentRoute: HOTELIER_BONUS_PROGRAMS,
      },
    }),
    route('basic-conditions/', BaseConditions, HOTELIER_BASIC_CONDITIONS, {
      meta: {
        parentRoute: HOTELIER_BONUS_PROGRAMS,
      },
    }),
    route('bonus-groups/', BonusGroups, HOTELIER_BONUS_GROUPS, {
      meta: {
        parentRoute: HOTELIER_BONUS_PROGRAMS,
      },
    }),
    route('receiving-rules/', ReceivingRules, HOTELIER_RECEIVING_RULES, {
      meta: {
        parentRoute: HOTELIER_BONUS_PROGRAMS,
      },
    }),
  ];
}

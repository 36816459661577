<template lang="pug">

component(
  :is="orderingControllerComponent",
  :multiple="multiOrderable",
  :value="ordering",
  @input="$emit('update:ordering', $event)",
  v-slot="controller"
)
  component(
    :is="tableComponent", v-bind="$attrs", v-on="$listeners"
  )
    template(v-for="key in slotKeys", v-slot:[key]="scope")
      slot(:name="key", v-bind="scope")

    template(v-slot:head-cell="scope", v-if="orderingComponent && orderableFields && orderableFields.length")
      template(v-if="orderableFields.includes(scope.field.key)")
        component(
          :is="orderingComponent", :field="scope.field",
          :multiple="multiOrderable",
          :controller="controller",
        )
          slot(name="head-cell", v-bind="scope")
            | {{ scope.field.label }}

      template(v-else="")
        slot(name="head-cell", v-bind="scope")
          | {{ scope.field.label }}

</template>

<script>

import Table from './Table';
import Ordering from './Ordering';

export default {
  name: 'vue-orderable-table',
  inheritAttrs: false,
  props: {
    ordering: {},
    orderableFields: Array,
    multiOrderable: Boolean,
    tableComponent: {
      default: () => Table,
    },
    orderingControllerComponent: {
      default: 'vue-ordering-controller',
    },
    orderingComponent: {
      default: () => Ordering,
    },
  },
  computed: {
    slotKeys() {
      const EXCLUDED_SLOT_KEYS = {
        'head-cell': !!(this.orderingComponent && this.orderableFields && this.orderableFields.length),
      };

      return Object.keys(this.$scopedSlots).filter(x => !EXCLUDED_SLOT_KEYS[x]);
    },
  },
};

</script>

import {
  // toHasPermission,
  toHasOwnPermission,
  toHasStaffPermission,
} from '../permissions';

export default {
  name: 'user-access-state',
  props: {
    permissions: Array,
    permission: String,
    own: Boolean,
    staff: Boolean,
  },

  computed: {
    checkers() {
      // const checker = this.own ? toHasOwnPermission : toHasPermission;
      const checker = this.staff ? toHasStaffPermission : toHasOwnPermission;
      if (this.permission) {
        return [checker(this.permission)];
      }

      return this.permissions && this.permissions.map(checker) || [];
    },
  },

  render(h) {
    return h(
      'permissions-access-state',
      {
        props: {
          permissions: this.checkers,
        },
        scopedSlots: {
          default: props => [this.$scopedSlots.default(props)],
        },
      }
    );
  },
};

import { withDefaultProps } from '@aspectus/vue-utils';
import { Descriptor, NoneController } from '@aspectus/vee-control-descriptor';
import {
  CInput, CTextArea, COldInput, COldTextArea, CDatePicker, CSelect, COldSelect,
} from './inputs';
// import Select from './Select';
import AddressInput from './AddressInput';
import AddressInputOld from './AddressInputOld';

const dt = tag => withDefaultProps({
  element: () => tag,
  controllerComponent: () => NoneController,
})(Descriptor);

export const DInput = dt(CInput);
export const DTextArea = dt(CTextArea);
export const DOldInput = dt(COldInput);
export const DOldTextArea = dt(COldTextArea);
export const DDatePicker = dt(CDatePicker);
export const DSelect = dt(CSelect);
export const DOldSelect = dt(COldSelect);
export const DAddressInput = dt(AddressInput);
export const DOldAddressInput = dt(AddressInputOld);

function install(Vue) {
  Vue.component('d-input', DInput);
  Vue.component('d-textarea', DTextArea);
  Vue.component('d-old-input', DOldInput);
  Vue.component('d-old-textarea', DOldTextArea);
  Vue.component('d-date-picker', DDatePicker);
  Vue.component('d-select', DSelect);
  Vue.component('d-old-select', DOldSelect);
  Vue.component('d-address-input', DAddressInput);
  Vue.component('d-old-address-input', DOldAddressInput);
}

export default { install };

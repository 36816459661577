/*
  eslint-disable
    max-classes-per-file,
    prefer-spread,
    prefer-rest-params,
    class-methods-use-this,
    prefer-object-spread
*/

import {
  path,
  curry,
} from 'ramda';
// import { Or } from '@aspectus/permissions';
import { accessMeta } from '@aspectus/vue-router-permissions';
import { checkAuth } from '@md/auth/permissions';
import { checkPresencePermissions } from '@md/hotels/permissions';
import {
  MAP,
  AGENT_TYPE,
  REPRESENTATIVE_TYPE,
  HOTELIER_TYPE,
  HOTELIER_CHAIN_TYPE,
  MODERATOR_TYPE,
  SUPERUSER_TYPE,
  TOUROPERATOR_MANAGER_TYPE,
  DMC_TYPE,
} from './accessTokens.js';

const Route403 = () => import('@sections/403.vue');

const userStateGetter = path(['state', 'value', 'authStore']);

export function checkUser(checker, config = {}, ...args) {
  return checkAuth(
    checker, Object.assign({ getter: userStateGetter }, config), ...args
  );
}
const hotelsStateGetter = path(['state', 'value', 'hotelsStore']);
export function checkPermissionsPresence(checker, config = {}, ...args) {
  return checkPresencePermissions(
    checker, Object.assign({ getter: hotelsStateGetter }, config), ...args
  );
}

// export const isSuperuser = user => user.info.isSuperuser;
export const isType = curry((type, user) => user.info.userType === type);
export const isPermitted = curry(
  (permission, user) => !!(MAP[user.info.userType] && MAP[user.info.userType][permission])
);
export const isStaffPermitted = curry(
  (permission, MAP_PERMISSIONS) => !!(MAP_PERMISSIONS[permission])
);

export const toBe = type => checkUser(isType(type));
export const toBeNotAuthenticated = checkUser(user => !user.isAuthenticated());
export const toBeAuthenticated = checkUser(user => user.isAuthenticated());

export const toBeModerator = toBe(MODERATOR_TYPE);
export const toBeSuperuser = toBe(SUPERUSER_TYPE);
export const toBeAgent = toBe(AGENT_TYPE);
export const toBeRepresentative = toBe(REPRESENTATIVE_TYPE);
export const toBeHotelier = toBe(HOTELIER_TYPE);
export const toBeHotelierChain = toBe(HOTELIER_CHAIN_TYPE);
export const toBeDMC = toBe(DMC_TYPE);
export const toBeTouroperatorManager = toBe(TOUROPERATOR_MANAGER_TYPE);

export const toHasOwnPermission = permission => checkUser(isPermitted(permission));
export const toHasStaffPermission = permission => checkPermissionsPresence(isStaffPermitted(permission));
// export const toHasPermission = permission => new Or(
//   // toBeSuperuser,
//   toHasStaffPermission(permission)
// );

export const accessPermission = (permission, options) => accessMeta(
  toHasOwnPermission(permission), { path: '/permission-denied/', component: Route403 }, options
);

export const accessHotelPermission = (permission, options, name) => accessMeta(
  toHasStaffPermission(permission), name ? { name } : { path: '/permission-denied/', component: Route403 }, options
);

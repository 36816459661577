import { route } from '@md/routing';

const View = () => import('./View');

export const REPRESENTATIVE_CHATS = 'representative:chats';

export function createRoutes() {
  return [
    route('', View, REPRESENTATIVE_CHATS),
  ];
}

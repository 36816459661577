<template lang="pug">
touroperator-public-list(
  v-bind="$attrs"
)
</template>
<script>

const TouroperatorPublicList = () => import('./TouroperatorPublicList.vue');

export default {
  components: { TouroperatorPublicList },

};
</script>

import { route, prefixRoutes } from '@md/routing';

const CoinsList = () => import('./views/coins/List');
const NightsList = () => import('./views/nights/List');
const NightsDetailList = () => import('./views/nights/DetailList');

export const STAFF_STATISTICS_COINS = 'staff:statistics:coins';
export const STAFF_STATISTICS_NIGHTS = 'staff:statistics:nights';
export const STAFF_STATISTICS_NIGHTS_DETAIL = 'staff:statistics:nights:detail';

export function createRoutes() {
  return [
    route('coins/', CoinsList, STAFF_STATISTICS_COINS),
    prefixRoutes('nights/', [
      route('', NightsList, STAFF_STATISTICS_NIGHTS),
      route(':id/detail/', NightsDetailList, STAFF_STATISTICS_NIGHTS_DETAIL),

    ]),
  ];
}

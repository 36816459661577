import { route } from '@md/routing.js';

const List = () => import('./views/List');

const TRANSACTIONS_PREFIX = 'admin:transactions:';
const p = name => TRANSACTIONS_PREFIX + name;

export const ADMIN_TRANSACTIONS_LIST = p('list');

export function createRoutes() {
  return [
    route('list/', List, ADMIN_TRANSACTIONS_LIST),
  ];
}

export const ReviewColors = {
  1: 'var(--theme-accent-50)',
  2: 'var(--theme-accent-50)',
  3: 'var(--theme-accent-50)',
  4: 'var(--theme-warn-50)',
  5: 'var(--theme-warn-50)',
  6: 'var(--theme-warn-50)',
  7: 'var(--theme-brand-4)',
  8: 'var(--theme-brand-4)',
  9: 'var(--theme-success-50)',
  10: 'var(--theme-success-50)',
};

<template lang='pug'>
include /mixins
catalog-sidebar.bg-color-variant_relief_2(
  v-if="needFilters"
  :filter-controller="filterController"
  space="xl"
  :filters="filters"
  @update:filters="event => { filtersChenged = true; changeFilters(event) }"
  :pagination="pagination",
  :attributes-resource='attributesResource',
  :available-attributes-resource='availableAttributesResource'
  :need-filters='needFilters',
  :need-sorting='needSorting'
  :recommended-tags='recommendedTags',
  :sortlist='sortlist'
  :need-search='needSearch'
  :label='label'
)
  template(#content="")
    catalog-content(
      :is-filtered="filtersChenged"
      :scroll-paginate="scrollPaginate"
        :url-without-page="urlWithoutPage",
      :loading="loading"
      :items="result && result.data"
      :pagination="pagination"
      @update:pagination="parameters => { changePagination(parameters); filtersChenged = !parameters.infinite }"
    )
      slot
div.bg-color-variant_relief_2(
  v-else
)
  +b.g-container--fit_xs-xs.--fit_sm-sm.--fit_md-md.--fit_lg-lg.--fit_xl-xl
    row.relative(
      :justify='"between-xl"'
    )
      .cell-content
        +b.info-row
          template(
            v-if="!needFilters",
          )
            +e.cell: div
              +b.P.ds-caption--size_xs.--color_2
                | {{ _("Всего результатов: ") }} {{ pagination.total }}

        catalog-content(
          :is-filtered="filtersChenged"
          :scroll-paginate="scrollPaginate"
          :url-without-page="urlWithoutPage",
          :loading="loading"
          :items="result && result.data"
          :pagination="pagination"
          @update:pagination="parameters => { changePagination(parameters); filtersChenged = !parameters.infinite }"
        )
          slot

</template>

<script>

import CatalogSidebar from './Sidebar.vue';
import CatalogContent from './Content.vue';

export default {
  name: 'CatalogController',
  components: { CatalogSidebar, CatalogContent },
  props: [
    'result',
    'loading',
    'receive',
    'parameters',
    'filters',
    'changeFilters',
    'pagination',
    'changePagination',
    'attributesResource',
    'availableAttributesResource',
    'needFilters',
    'needSorting',
    'needSearch',
    'recommendedTags',
    'hasRecommendedTags',
    'urlWithoutPage',
    'scrollPaginate',
    'label',
    'sortlist',
    'filterController',
  ],

  data() {
    return {
      filtersChenged: false,
    };
  },

  methods: {
    // search() {
    //   this.receive(this.parameters)
    // },
  },
};

</script>

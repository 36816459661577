<template lang="pug">
include /mixins.pug
div.relative
  ui-loading(v-if="loading")
  light-box.light-box-wrapper(
    :show-gallery="true",
    :index="index"
    @chang="e => index = e"
    @close="index = null"
    :items="preparedImages"
    :toolbar="toolbar"
  )
    template(#icon-previous)
      +b.ICON.icon--color_1(name="icon-arrow-left", size="2xl")
    template(#icon-next)
      +b.ICON.icon--color_1(name="icon-arrow-right", size="2xl")

  slot(
    name="default"
    :open="open"
    :gallery="preparedImages"
  )

</template>
<script>
import LoadingStateMixin from '@aspectus/vue-loading-state-mixin';
import { gallery } from '@md/gallery/api.js';

export default {
  mixins: [LoadingStateMixin],
  props: {
    previewImage: {
      type: Object,
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      toolbar: ['counter', 'zoom', 'slide', 'rotate', 'gallery', 'close'],
      images: [],
      preparedImages: [],
      index: null,
    };
  },

  created() {
    this.getGalleryItem(this.filters);
  },

  methods: {
    getGalleryItem(filters = {}) {
      const params = { filters: JSON.stringify(filters), limit: 99 };
      this.$load(gallery.hotels.publicList.execute(params)).then(({ data: { items } }) => {
        this.images = items;
        this.prepareImages();
      });
    },
    prepareImages() {
      if (this.previewImage?.src) {
        this.preparedImages.push(this.previewImage);
      }
      this.images.forEach(galleryItem => {
        const thumb = galleryItem.photo?.image?.image?.thumbnailPreview;
        this.preparedImages.push({
          src: galleryItem.photo?.image.image?.fullSize,
          // title: galleryItem.photo.title,
          description: galleryItem?.photo?.description,
          thumb,
          autoplay: true,
          controls: true,
        });
      });
    },
    open(index = 0) {
      this.index = index;
    },
  },

};
</script>
<style lang="sass">
.light-box-wrapper.cool-lightbox
  .cool-lightbox-navigation
    background-color: transparent
    color: #ffffff
  .cool-lightbox-thumbs
    &__
      &list
        overflow-x: auto

  &.cool-lightbox.cool-lightbox--thumbs-bottom .cool-lightbox-thumbs .cool-lightbox-thumbs__list
    justify-content: flex-start
    max-width: 99vw
    margin-left: auto
    margin-right: auto
  &.cool-lightbox.cool-lightbox--thumbs-bottom .cool-lightbox-thumbs .cool-lightbox__thumb
    min-width: 50px
    &:first-child
      margin-left: auto
    &:last-child
      margin-right: auto

  .caption-description
    background: #6c6c6c
    border-radius: 20px
    display: inline-block
    padding: 10px 20px
</style>

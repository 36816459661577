import { route } from '@md/routing';
import { applications } from '@md/bids/api.js';
import { receiveRoute } from '@sections/cabinet/components/views/utils';

const List = () => import('./views/List');
const Edit = () => import('./views/Edit');

const APPLICATIONS_PREFIX = 'hotelier_chain::applications:';
const p = name => APPLICATIONS_PREFIX + name;
export const HOTELIER_CHAIN_APPLICATIONS_LIST = p('list');
export const HOTELIER_CHAIN_APPLICATIONS_EDIT = p('edit');

// export function createRoutes(options) {
export function createRoutes() {
  return [
    route('list/', List, HOTELIER_CHAIN_APPLICATIONS_LIST, {
    }),
    receiveRoute(applications.retrieve, [
      route('edit/', Edit, HOTELIER_CHAIN_APPLICATIONS_EDIT, {
        meta: {
          parentRoute: HOTELIER_CHAIN_APPLICATIONS_LIST,
        },
      }),
      { path: '*', redirect: { name: HOTELIER_CHAIN_APPLICATIONS_EDIT } },
    ]),

    { path: '', redirect: { name: HOTELIER_CHAIN_APPLICATIONS_LIST } },
  ];
}

import { authGuardConfig } from '@md/auth/mixins/AuthProtectionMixin.js';

const CreateComplaint = () => import('@md/complaints/components/Complaint.vue');
export const CreateComplaintMixin = {

  methods: {
    getModalConfigComplaintCreate(item, title, reasonsType) {
      return authGuardConfig({
        props: {
          initial: {
            item,
          },
          title,
          reasonsType,
          isComponent: CreateComplaint,
        },
        reaction: () => { item.hasComplaint = true; },

      });
    },
  },

};

import { route } from '@md/routing';

const View = () => import('./views/View.vue');
const BaseConditions = () => import('./views/BasicConditions/View.vue');
const BonusGroups = () => import('./views/BonusGroups/View.vue');
const ReceivingRules = () => import('./views/ReceivingRules/View.vue');
const ParticipantsHotels = () => import('./views/ParticipantsHotels/View.vue');

const PREFIX = 'hotelier_chain:bonus_program:';
const p = name => PREFIX + name;
export const HOTELIER_CHAIN_BONUS_PROGRAMS = p('view');
export const HOTELIER_CHAIN_BASIC_CONDITIONS = p('base_conditions');
export const HOTELIER_CHAIN_BONUS_GROUPS = p('bonus_groups');
export const HOTELIER_CHAIN_RECEIVING_RULES = p('receiving_rules');
export const HOTELIER_CHAIN_PARTICIPANTS_HOTELS = p('participants_hotels');

export function createRoutes() {
  return [
    route('', View, HOTELIER_CHAIN_BONUS_PROGRAMS),
    route('basic-conditions/', BaseConditions, HOTELIER_CHAIN_BASIC_CONDITIONS, {
      meta: {
        parentRoute: HOTELIER_CHAIN_BONUS_PROGRAMS,
      },
    }),
    route('bonus-groups/', BonusGroups, HOTELIER_CHAIN_BONUS_GROUPS, {
      meta: {
        parentRoute: HOTELIER_CHAIN_BONUS_PROGRAMS,
      },
    }),
    route('receiving-rules/', ReceivingRules, HOTELIER_CHAIN_RECEIVING_RULES, {
      meta: {
        parentRoute: HOTELIER_CHAIN_BONUS_PROGRAMS,
      },
    }),
    route('participants-hotels/', ParticipantsHotels, HOTELIER_CHAIN_PARTICIPANTS_HOTELS, {
      meta: {
        parentRoute: HOTELIER_CHAIN_BONUS_PROGRAMS,
      },
    }),
  ];
}

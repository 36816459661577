import { route } from '@md/routing';

const List = () => import('./views/List');

const TRANSACTIONS_PREFIX = 'hotelier_chain:transactions:';
const p = name => TRANSACTIONS_PREFIX + name;
export const HOTELIER_CHAIN_TRANSACTIONS_LIST = p('list');

export function createRoutes() {
  return [
    route('list/', List, HOTELIER_CHAIN_TRANSACTIONS_LIST, {
    }),

    { path: '', redirect: { name: HOTELIER_CHAIN_TRANSACTIONS_LIST } },
  ];
}

import { route } from '@md/routing';

const List = () => import('./views/List');

export const AGENT_PROMOTIONS_LIST = ':agent:promotions:list';

export function createRoutes() {
  return [
    route('list/', List, AGENT_PROMOTIONS_LIST),
    { path: '', redirect: AGENT_PROMOTIONS_LIST },
  ];
}

<template lang="pug" functional>

include /mixins.pug

+b.cabinet-sidebar-menu(
  v-state="{ condensed: props.condensed }"
)
  +e.UL.elements
    slot(name="elements")
      slot(name="prepend")

      +e.LI.section(v-for="section in props.sections")
        cabinet-sidebar-menu-section(
          v-bind="section",
          :condensed="props.condensed",
          :drop='props.dropdownMenu'
        )

      slot(name="append")

</template>

<script>

import Section from './Section';

export default {
  components: {
    'menu-section': Section,
  },
};

</script>

import { route } from '@md/routing';
import { applications } from '@md/bids/api.js';
import { receiveRoute } from '@sections/cabinet/components/views/utils';

const List = () => import('./views/List');
const Edit = () => import('./views/Edit');
const Create = () => import('./views/Create');

const APPLICATIONS_PREFIX = 'agent:applications:';
const p = name => APPLICATIONS_PREFIX + name;
export const AGENT_APPLICATIONS_LIST = p('list');
export const AGENT_APPLICATIONS_EDIT = p('edit');
export const AGENT_APPLICATIONS_CREATE = p('create');

// export function createRoutes(options) {
export function createRoutes() {
  return [
    route('list', List, AGENT_APPLICATIONS_LIST, {
      // meta: accessPermission(APPLICATIONS_LIST_VIEW, options),
    }),
    route('create/', Create, AGENT_APPLICATIONS_CREATE, {
      // meta: accessPermission(APPLICATIONS_CREATE, options),
    }),
    receiveRoute(applications.retrieve, [
      route('edit/', Edit, AGENT_APPLICATIONS_EDIT, {
        // meta: accessPermission(APPLICATIONS_VIEW, options),
      }),
      { path: '', redirect: { name: AGENT_APPLICATIONS_EDIT } },
    ]),

    { path: '', redirect: { name: AGENT_APPLICATIONS_LIST } },
  ];
}

import { route } from '@md/routing';

const List = () => import('./views/List');
const View = () => import('./views/Referral');

export const REPRESENTATIVE_REFERRALS_LIST = 'representative:referrals:list';
export const REPRESENTATIVE_REFERRALS_REFERRAL = 'representative:referrals:referral';

export function createRoutes() {
  return [
    route('list/', List, REPRESENTATIVE_REFERRALS_LIST),
    route(':id/detail/', View, REPRESENTATIVE_REFERRALS_REFERRAL),

    { path: '', redirect: { name: REPRESENTATIVE_REFERRALS_LIST } },
  ];
}

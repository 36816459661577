<template lang="pug">
include /mixins
+b.room-info
  +b.room-bonus.pb-1
    template(
      v-for="item in bonusList"
    )
      +b.pb-05.ds-caption--size_3sm.--appearance_italic.--medium
        | {{ _('* TRC') }} {{ item.bonus }}
        | {{ _('will be credited to your account when booking') }}
        | {{ item.nights }}+
        | {{ _('nights') }}

  template(v-if="mealType")
    +b.room-daily-price.g-row--justify_between.--align_end
      +e.CELL(cols="5").meal-type-select
        ds-caption.pb-1.ds-caption--appearance_line-height_sm(size="sm", variant="strong") {{ _("Select meal type") }}

        control-select.room-meal-select.control-select--style_1(
          :options="mealTypesList"
          v-model="mealType"
          name="mealType"
          track-by="id"
          label="compoundName"
          size="sm"
          :preselect-first="true"
          :max-height="300"
          :allow-empty="false"
        )

      +e.CELL(cols="5 narrow").booking
        ds-caption.ds-caption--appearance_line-height_sm(size="xs", appearance="right")
          span.ds-caption--heavy.ds-caption--size_md TRC {{ mealType.trcPrice }}
          span.ds-caption--color_7.ds-caption--size_xs /{{ _("night") }}

        ds-caption(size="xs", appearance="right").ds-caption--color_7
          | {{ mealType.euroPrice }} {{ _("euro") }}

        control-old-button.A.control-old-button--styling_brand-7(
          tag="router-link",
          appearance="full",
          :to="{ name: 'agent:booking:create', query: { hotel: hotelId }}"
          :size="['2xs']",
          type="button"
        )
          ds-caption.ds-caption--appearance_line-height_sm(size="2sm")
            | {{ _("Reserve") }}

</template>
<script>
import { hotelsParticipants, dailyPrices } from '@md/nights/api.js';
import { AGENT_TYPE } from '@md/users/accessTokens';

export default {
  name: 'room-daily-price',
  props: {
    roomId: {
      type: [String, Number],
      required: true,
    },
    hotelId: {
      type: [String, Number],
      required: true,
    },
  },
  async mounted() {
    if (window.IS_AUTHENT && AGENT_TYPE === window.USER_TYPE) {
      await this.getMealTypesList();
      await this.getRoomBonus();
    }
  },
  data() {
    return {
      labels: {
        mealType: this.pgettext('modules:rooms', 'Meal type'),
      },
      bonusList: [],
      mealTypesList: [],
      mealType: null,
    };
  },
  methods: {
    async getMealTypesList() {
      const parameters = {
        filters: JSON.stringify({
          room: this.roomId,
          hotel: this.hotelId,
        }),
        limit: 100,
      };

      let items = [];
      try {
        const { data } = await dailyPrices.list.execute(parameters);
        items = data.items;
        // eslint-disable-next-line no-empty
      } catch (err) { }

      // remove daily prices with no allocation 1+0
      const dPrices1plus0Only = items.filter(
        item => '' !== item.allocationPrices['1+0']
      );

      // group daily prices with the same meal type
      const groupedByMealType = dPrices1plus0Only.reduce((acc, cur) => {
        if (!acc[cur.mealType.title]) {
          acc[cur.mealType.title] = [];
        }
        acc[cur.mealType.title].push(cur);
        return acc;
      }, {});

      // get daily prices with the smallest price
      // if there are several with the same meal type and allocation 1+0
      const dailyPricesList = Object.values(groupedByMealType).map(group => {
        const smallestPrice = Math.min(...group.map(
          item => parseFloat(item.allocationPrices['1+0'])
        ));
        return group.filter(
          item => parseFloat(item.allocationPrices['1+0']) === smallestPrice
        );
      }).flat();

      // add new name for label, add price in euro, and price in TRC
      this.mealTypesList = dailyPricesList.map(obj => ({
        ...obj.mealType,
        compoundName: `${obj.mealType.title} ${obj.mealType.name}`,
        trcPrice: parseFloat(obj.allocationPrices['1+0']).toFixed(2),
        euroPrice: parseFloat(obj.allocationPrices['1+0'] / 10).toFixed(2),
      }));

      // set initial meal type
      [this.mealType] = this.mealTypesList;
    },
    async getRoomBonus() {
      const parameters = {
        filters: JSON.stringify({
          hotel: this.hotelId,
          room_type__list_rooms: this.roomId,
        }),
      };

      let items = [];
      try {
        const { data } = await hotelsParticipants.list.execute(parameters);
        items = data.items;
        // eslint-disable-next-line no-empty
      } catch (err) { }

      const bonusList = items.map(item => ({
        bonus: item.bonus,
        nights: item.minNights,
      }));

      this.bonusList = bonusList;
    },
  },
};
</script>

<style lang="sass">
.room-meal-select
  .multiselect__tags
    .multiselect__single
      font-size: .75rem
      font-weight: bold
      text-align: center
      padding-left: .6rem
      text-align: left
.room-meal-select.control-select
  .multiselect__select::before
    padding: 4px
    border-color: var(--theme-brand-4)
</style>

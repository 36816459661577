import { route } from '@md/routing';

const List = () => import('./views/List');

const VIDEO_PREFIX = 'hotelier_chain:hotels:profile:video:';
const p = name => VIDEO_PREFIX + name;
export const HOTELIER_CHAIN_VIDEO_LIST = p('list');

export function createRoutes(
  // options
) {
  return [
    route('list/', List, HOTELIER_CHAIN_VIDEO_LIST, {
      // meta: accessHotelPermission(MANAGER_VIDEO_VIDEO_EDIT, options),
    }),
    { path: '', redirect: { name: HOTELIER_CHAIN_VIDEO_LIST } },
  ];
}

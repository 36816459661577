import AuthProvider from './AuthProvider.js';
import PasswordResetConfirm from './PasswordResetConfirm.vue';
// import SocialVerifyEmail from './SocialVerifyEmail.vue';
import ManagerConfirm from './ManagerConfirm.vue';
import RegionalReferral from './RegionalReferral.vue';

const Registration = () => import('./Registration.vue');
const QuickRegistration = () => import('./QuickRegistration.vue');

export function install(Vue) {
  Vue.component(RegionalReferral.name, RegionalReferral);
  Vue.component(AuthProvider.name, AuthProvider);
  Vue.component(PasswordResetConfirm.name, PasswordResetConfirm);
  // Vue.component(SocialVerifyEmail.name, SocialVerifyEmail);
  Vue.component(ManagerConfirm.name, ManagerConfirm);
  Vue.component('user-registration', Registration);
  Vue.component('quick-registration', QuickRegistration);
}

export default { install };

<template lang="pug">
include /mixins.pug
+b.pagination-wrap(v-if="total")
  template(
    v-if="scrollPaginate"
  )
    template(
      v-if="hasNext"
    )
      div.observer(
        v-observe-visibility=`{
          callback: handleScroll,
          intersection: {
            rootMargin: '0px 0px 400px 0px'
          },
        }`
      )
  row(
    v-else
    align="center",
    justify="center"
  )
    cell(v-if="hasNext")
      +b.IMG.img-block(
        width="45",
        height="45",
        src="/static/svg/uEA79-refresh.svg",
        loading="lazy",
        decoding="async",
        @click.prevent="loadMore"
      )
    cell(v-if="hasNext")
      ds-link.show-more(
        tag="button",
        type="button",
        styling="brand-grey"
        @click.prevent="loadMore"
      ) {{ _("Show more") }}
    cell
      +b("ul").pagination(v-if="range.max > 1")
        template(v-if="hasPrevious")
          +e.LI.item: +e.A.link(
                :href=" url ? `${url}page/${range.min}/` : '#'", @click.prevent="select(range.min)", :class="{ 'is-active': startPage == range.min }"
              ) {{ range.min }}
        +e.LI.item(v-if="gaps.left"): +e.link--dots(:class="{ 'is-active': startPage }") ...
        +e.LI.item(v-for="page in fills.left"): +e.A.link(
          :class="{ 'is-active': startPage }"
          :href=" url ? `${url}page/${page}/` : '#'",
          @click.prevent="select(page)"
        ) {{ page }}
        +e.LI.item.is-active: +e.link.is-active(v-text="getPageNumber()")
        +e.LI.item(v-for="page in fills.right"): +e.A.link(:href=" url ? `${url}page/${page}/` : '#'", @click.prevent="select(page)") {{ page }}
        +e.LI.item(v-if="gaps.right"): +e.link ...

        template(v-if="hasNext")
          +e.LI.item: +e.A.link(
                :href=" url ? `${url}page/${range.max}/` : '#'", @click.prevent="select(range.max)"
              ) {{ range.max }}
</template>
<script>

const PAGE_SHIFT = 1;

export default {
  name: 'catalog-pagination',
  props: {
    scrollPaginate: Boolean,
    startPage: { default: false },
    shift: { default: PAGE_SHIFT },
    total: {},
    limit: {},
    offset: {},
    extra: { default: 2 },
    url: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      range: {
        min: 0,
        max: Infinity,
      },
      gaps: {
        left: false,
        right: false,
      },
      fills: {
        left: [],
        right: [],
      },
    };
  },

  computed: {
    hasNext() {
      return this.offset + this.limit < this.total;
    },

    hasPrevious() {
      return 0 < this.offset;
    },

  },

  watch: {
    total: { handler: 'recalculateRange' },
    limit: { handler: 'recalculateRange' },
    offset: { handler: 'recalculateRange' },
  },

  created() {
    if (this.total) this.recalculateRange();
  },

  methods: {

    getPrevious() {
      return this.getPageNumber() - this.shift;
    },

    getNext() {
      return this.getPageNumber() + this.shift;
    },

    getPageNumber(offset = this.offset, shift = this.shift) {
      return Math.floor(offset / this.limit) + shift;
    },

    getPageRange(distance = null, current = this.getPageNumber()) {
      const min = 1;
      const max = Math.max(min, Math.ceil(this.total / this.limit));

      if (null === distance || Infinity === distance) {
        return [min, max];
      }

      return [
        Math.max(min, current - distance),
        Math.min(max, current + distance),
      ];
    },

    recalculateRange() {
      const [min, max] = this.getPageRange();
      const current = this.getPageNumber();
      const [start, end] = this.getPageRange(this.extra, current);
      // Some javascript magic going on here:
      // Filling up array with page numbers.
      // const range1 = new Array(...new Array(end - start + 1))
      //   .map((x, i) => i + start);
      // eslint-disable-next-line prefer-spread
      const range = Array
        .apply(null, { length: end - start + 1 })
        .map((x, i) => i + start);
      const currentIndex = range.indexOf(current);

      if (-1 !== currentIndex) {
        this.fills.left = range.slice(0, currentIndex).filter(x => x !== min);
        this.fills.right = range.slice(currentIndex + 1).filter(x => x !== max);
      } else {
        this.fills.left = [];
        this.fills.right = [];
      }

      this.gaps.left = (
        this.fills.left.length &&
        this.fills.left[0] !== min + 1
      );
      this.gaps.right = (
        this.fills.right.length &&
        this.fills.right[this.fills.right.length - 1] !== max - 1
      );
      this.range.min = min;
      this.range.max = max;
    },

    getParameters(number = null) {
      let page = 1;

      if (null !== number) {
        const [min, max] = this.getPageRange(Infinity, number);

        page = Math.min(max, Math.max(min, number));
      } else {
        page = this.getPageNumber();
      }

      return {
        offset: this.limit * (page - this.shift),
        limit: this.limit,
      };
    },

    handleScroll(isVisible) {
      if (isVisible) {
        this.loadMore();
      }
    },

    loadMore() {
      this.$emit('load-more', this.getParameters(this.getPageNumber() + this.shift));
    },

    select(number) {
      this.$emit('input', this.getParameters(number));
    },

    next() {
      this.$emit('input', this.getParameters(this.getPageNumber() + this.shift));
    },

    previous() {
      this.$emit('input', this.getParameters(this.getPageNumber() - this.shift));
    },
  },
};
</script>

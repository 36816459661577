import { pick, omit, path } from 'ramda';
import LoadingStateMixin from '@aspectus/vue-loading-state-mixin';

const PAGINATION_KEYS = ['limit', 'offset', 'total'];
const FILTER_PAGINATION_DROP_KEYS = ['offset'];
const ONLY_PARAMETERS_KEYS = ['order_by'];

const createChecker = (callback, promise) => value => callback(value, promise);
const getPaginationParameters = path(['pagination']);

export const ResourceLoaderMixin = {
  mixins: [LoadingStateMixin],

  props: {
    resource: {},
    // lazyLoad: {
    //   type: Boolean,
    //   default: false,
    // },
  },

  data() {
    return {
      result: null,
      lazyLoad: false,
    };
  },

  methods: {
    applyResult(result, promise) {
      if (this.$options.promise !== promise) {
        return;
      }
      if (result.code) {
        if (this.result && this.lazyLoad) {
          this.result.items = [...this.result.items, ...result.data.items];
          this.result.pagination = result.data.pagination;
          this.lazyLoad = false;
        } else {
          this.result = result.data;
        }
        this.$emit('result', this.result);
        return;
      }
      this.result = result;
      this.$emit('result', this.result);
    },

    applyError(result, promise) {
      if (this.$options.promise !== promise) {
        return;
      }

      this.$emit('error', result);
      throw result;
    },

    receive(parameters) {
      if (!this.resource) return null;

      if (this.$options.promise && this.$options.promise.cancel) {
        this.$options.promise.cancel();
      }

      const promise = this.resource.execute(parameters);
      this.$options.promise = promise;

      return this.$load(
        promise
          .then(createChecker(this.applyResult, promise))
          .catch(createChecker(this.applyError, promise))
      );
    },
  },
};

export const PaginationControllerMixin = {
  mixins: [ResourceLoaderMixin],
  props: {
    parameters: Object,
    resultPaginationReceiver: {
      type: Function, default: getPaginationParameters,
    },
    paginationKeys: {
      type: Array, default: () => PAGINATION_KEYS,
    },
    filterPaginationDropKeys: {
      type: Array, default: () => FILTER_PAGINATION_DROP_KEYS,
    },
    onlyParametersKeys: {
      type: Array, default: () => ONLY_PARAMETERS_KEYS,
    },
  },

  data() {
    return {
      filters: {},
      pagination: {},
    };
  },

  watch: {
    parameters: { immediate: true, handler: 'handleParametersChange' },
  },

  created() {
    this.$on('result', this.handlePaginationResult);
  },

  methods: {
    parametersUpdate(parameters) {
      this.$emit('update:parameters', parameters);
      this.receive(parameters);
    },

    handleParametersChange(value = {}) {
      const filters = omit(this.paginationKeys, omit(this.onlyParametersKeys, value));
      const pagination = {
        ...this.pagination,
        ...pick(this.paginationKeys, value),
      };

      this.filters = filters;
      this.pagination = pagination;
    },

    handlePaginationResult(result) {
      this.pagination = { ...this.pagination, ...this.resultPaginationReceiver(result) };

      return result;
    },

    changeFilters(filters) {
      this.parametersUpdate(omit(this.filterPaginationDropKeys, {
        ...filters,
        ...pick(this.paginationKeys, this.pagination),
        ...pick(this.onlyParametersKeys, this.parameters),
      }));
    },

    loadMore(pagination) {
      this.lazyLoad = true;

      this.parametersUpdate({
        ...pick(this.paginationKeys, pagination),
        ...this.filters,
        ...pick(this.onlyParametersKeys, this.parameters),
      });
    },

    changePagination(pagination) {
      this.parametersUpdate({
        ...pick(this.paginationKeys, pagination),
        ...this.filters,
        ...pick(this.onlyParametersKeys, this.parameters),
      });
    },
  },
};

export const AggregateLoaderMixin = {
  mixins: [PaginationControllerMixin],

  props: {
    aggregateResource: {},
  },

  data() {
    return {
      aggregate: null,
    };
  },

  methods: {
    parametersUpdate(parameters) {
      this.$emit('update:parameters', parameters);
      this.receive(parameters);
      if (this.aggregateResource) {
        this.receiveAggregate(parameters);
      }
    },

    applyAggregate(result, promise) {
      if (this.$options.promiseAggregate !== promise) {
        return;
      }

      this.aggregate = result && result.data;
      this.$emit('result', result && result.data);
    },

    receiveAggregate(parameters) {
      if (this.$options.promiseAggregate && this.$options.promiseAggregate.cancel) {
        this.$options.promiseAggregate.cancel();
      }

      const promise = this.aggregateResource.execute(parameters);
      this.$options.promiseAggregate = promise;

      return this.$load(
        promise
          .then(createChecker(this.applyAggregate, promise))
          .catch(createChecker(this.applyError, promise))
      );
    },
  },
};

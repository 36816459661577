<script>
import LoadingStateMixins from '@aspectus/vue-loading-state-mixin';
import { mapState } from 'pinia';
import { useAuthStore } from '@md/auth/store.js';
import { renderSlim } from '@aspectus/vue-utils';
import { modalOpener } from '@utils/helpers.js';

const ConversationForm = () => import('./ConversationForm.vue');

export default {
  name: 'start-conversation',
  mixins: [LoadingStateMixins],
  props: {
    interlocutor: {
      type: [String, Number],
      required: false,
    },
    meta: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },

  data() {
    return {
      roomId: null,
    };
  },

  computed: {
    ...mapState(useAuthStore, ['user']),
    userId() {
      return this.user.info.auth;
    },
    ConversationModalConfig() {
      return {
        props: {
          isComponent: ConversationForm,
          title: this._('Start conversation'),
          initial: {
            users: this.interlocutor ? [this.interlocutor, this.userId] : null,
            title: '',
            meta: this.meta,
            message: {
              type: 'text',
              body: { content: '' },
              meta: {},
            },
          },
        },
      };
    },
  },

  mounted() {
  },
  methods: {
    modalOpener,
    openConversationModal() {
      return this.modalOpener(this.ConversationModalConfig);
    },
  },
  render(h) {
    return renderSlim(this.$scopedSlots.default({
      open: this.openConversationModal,
    }), h, 'tag');
  },

};
</script>

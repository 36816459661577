// import { withDefaultProps } from '@aspectus/vue-utils'
import DrawerLayout from './Layout';
import DrawerContainerStateles from './Container';
import DrawerReceiver from './Receiver';
import { LocalStorageControllerMixin, withLocalStorageController } from './LocalStorageController';

export const DrawerContainer = withLocalStorageController(DrawerContainerStateles);

export {
  DrawerContainerStateles,
  DrawerReceiver,
  DrawerLayout,

  LocalStorageControllerMixin,
  withLocalStorageController,
};

function install(Vue, {
  drawerContainerName = DrawerContainerStateles.name,
  drawerReceiverName = DrawerReceiver.name,
  drawerLayoutName = DrawerLayout.name,
} = {}) {
  Vue.component(drawerContainerName, DrawerContainer);
  Vue.component(drawerReceiverName, DrawerReceiver);
  Vue.component(drawerLayoutName, DrawerLayout);
}

export default {
  install,

  DrawerContainerStateles,
  DrawerContainer,
  DrawerReceiver,
  DrawerLayout,

  LocalStorageControllerMixin,
  withLocalStorageController,
};

<template lang="pug">

include /mixins.pug

+b.control-input(
  v-bem:control-input="{ styling, size, type }"
  v-state="{ readonly, invalid }"
)
  +e.body
    +e.INPUT.element--primary(
      type=""
      :type="type",
      v-model="internal",
      :readonly="readonly",
      @keydown.enter.prevent="",
      placeholder=""
      ref="input",
      v-bind="$attrs",
      @blur="blur"
    )
    +e.element.--attachment_append(v-if="icon || $scopedSlots.icon")
      slot(name="icon")
        icon(:name="icon")
  float-label(
    v-if="floatLabel"
    :label-text="floatLabel",
    :value="internal"
    :is-required="required"
    v-bind="$attrs",
  )

</template>

<script>

import { maps } from '@md/api.js';

export default {
  name: 'control-address-input',
  props: {
    value: { },
    floatLabel: undefined,
    size: { default: 'md' },
    styling: { default: 'default' },
    required: {},
    type: { default: 'text' },
    readonly: Boolean,
    invalid: Boolean,
    map: {
      type: Boolean,
      default: false,
    },
    icon: {},
  },

  data() {
    this.autocomplete = null;

    return {
      internal: '',
      position: { lat: 0, lng: 0 },
      firstRender: false,
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(value, old) {
        if (value !== old) {
          this.internal = value && value.address || '';
          this.position = value;
        }
      },
    },
  },

  async created() {
    await maps().then(this.bindToInput);
  },

  methods: {
    // findAddressComponent(components, type) {
    //   return components.find(
    //     component => component.types.some((x, i) => type[i] === x)
    //   );
    // },
    async bindToInput(googleMaps) {
      this.autocomplete = await new googleMaps.places.Autocomplete(
        this.$refs.input,
        {
          types: ['establishment', 'geocode'],
          language: window.language,
        }
      );

      setTimeout(async () => {
        if (this.map && this.value && !this.firstRender) {
          const mapSelector = document.querySelector('#map');
          if (mapSelector) {
            const position = { lat: +this.value.lat, lng: +this.value.lng };
            this.renderMap(googleMaps, mapSelector, position);
          }
          this.firstRender = true;
        }
      }, 500);

      this.autocomplete.addListener('place_changed', async () => {
        const place = await this.autocomplete.getPlace();
        this.internal = await this.$refs.input.value;
        const { lat, lng } = await place.geometry.location.toJSON();

        this.input({
          address: place.formatted_address,
          lat,
          lng,
        });

        const mapSelector = document.querySelector('#map');
        if (mapSelector) {
          const position = { lat, lng };
          this.renderMap(googleMaps, mapSelector, position);
        }
      });
    },
    async renderMap(googleMaps, mapSelector, position) {
      const map = await new googleMaps.Map(mapSelector, {
        center: position,
        zoom: 15,
        language: window.language,
      });
      let marker;
      const setMarker = async latLng => {
        if (null == marker) {
          marker = await new googleMaps.Marker({
            position: latLng,
            icon: '/static/img/map_marker.png',
            map,
            language: window.language,
          });
        } else {
          marker.setPosition(latLng);
        }
      };
      setMarker(position);
      window.setTimeout(() => {
        map.addListener('click', async e => {
          let address = this.internal;
          const geocoder = new googleMaps.Geocoder({
            language: window.language,
          });
          await geocoder
            .geocode({ location: e.latLng }, (results, status) => {
              if ('OK' === status) {
                address = results[0].formatted_address;
                setMarker(e.latLng);
                this.input({
                  address,
                  ...e.latLng.toJSON(),
                });
              }
            });
        });
      }, 300);
    },
    blur() {
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.value && this.internal !== this.value.address) {
            this.input(null);
          }
        }, 400);
      });
    },
    input(value) {
      this.$emit('input', value);
    },
  },
};

</script>

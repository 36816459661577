import { route } from '@md/routing';

const List = () => import('./views/List');

const FROZEN_PAYMENTS_PREFIX = 'representative:frozen-payments:';
const p = name => FROZEN_PAYMENTS_PREFIX + name;
export const REPRESENTATIVE_FROZEN_PAYMENTS_LIST = p('list');

// export function createRoutes(options) {
export function createRoutes() {
  return [
    route('list/', List, REPRESENTATIVE_FROZEN_PAYMENTS_LIST, {
      // meta: accessPermission(TRANSACTIONS_VIEW_LIST, options),
    }),

    { path: '', redirect: { name: REPRESENTATIVE_FROZEN_PAYMENTS_LIST } },
  ];
}

import { defaultDataTransformer } from '@aspectus/resource-commons';
import { createReceiver, createSender } from '@md/resources';
import { prefixAPI } from '@md/api.js';

const r = (tpl, f = createReceiver) => f(prefixAPI(tpl)).transform(defaultDataTransformer);

export const likes = {
  add: r('/likes/add/', createSender),
  remove: r('/likes/remove/', createSender),
};

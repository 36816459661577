import { renderSlim } from '@aspectus/vue-utils';
import { ResourceLoaderMixin } from '@md/ui/catalog/mixins.js';

export default {
  name: 'resource-loader-controller',
  mixins: [ResourceLoaderMixin],

  render(h) {
    return renderSlim(this.$scopedSlots.default({
      loading: this.loading,
      result: this.result,
      receive: this.receive,
    }), h, 'tag');
  },
};

import { modalOpener, changeLanguage, headerSticky, mainPageScrollMenu, getSelection } from '@utils/helpers.js';
// import { defaultValidatorErrorsParser } from '@md/ui/forms/FormMixin.js';
import { aosInitiate } from '@utils/aos.js';
import { usePageHintsStore } from '@md/page_hints/store.js';
import { mapActions, mapState } from 'pinia';
import Cookie from 'js-cookie';
import { createI18n, i18nDateTimeFormats } from '@/i18n.js';
import { createRouter } from '@/router';

const SendTypoForm = () => import('@md/bids/components/typo/TypoErrorForm.vue');
const { log: print } = console;

const desktopDivision = 1200;

aosInitiate({
  disable: ((window.innerWidth < desktopDivision) || window.matchMedia('(prefers-reduced-motion: reduce)')),
});

export function createApp({ Vue, Router, I18n, pinia }) {
  const i18n = createI18n({ Vue, I18n });
  const router = createRouter({ Vue, Router, pinia });

  Vue.prototype.$body = document.body;
  Vue.prototype.$goToTop = () => document.body.scrollIntoView({ block: 'start', behavior: 'smooth' });
  Vue.prototype.$scrollToRootRef = function scrollToRootRef(to) {
    if (this.$root.$refs[to]) this.$root.$refs[to].scrollIntoView({ behavior: 'smooth' });
  };
  Vue.prototype.$dateTimeFormats = i18nDateTimeFormats;
  Vue.prototype.$d = (date, format) => new Intl.DateTimeFormat('ru', i18nDateTimeFormats[format]).format(date);

  Vue.prototype.$log = print;

  Vue.prototype.$CHANGE_LANGUAGE = changeLanguage;
  Vue.prototype.$copyToClipboard = function copyToClipboard(value) {
    // HTTPS ок localhost
    navigator.clipboard.writeText(value)
      .then(() => {
        this.$toast.success(this._('Текст скопирован.'));
      })
      .catch(() => {
        this.$toast.success(this._('Something went wrong.'));
      });
  };

  const laptopDivisionWidth = 1200;

  const app = new Vue({
    i18n,
    router,
    pinia,
    delimiters: ['[[', ']]'],
    data() {
      return {
        True: true,
        False: false,
        None: undefined,
      };
    },
    computed: {
      ...mapState(usePageHintsStore, ['hintsHash']),
    },
    watch: {
      $route: {
        handler() {
          if (Cookie.get('page_hints:state_hash') !== this.hintsHash) {
            this.getHints();
          }
        },
      },
    },
    async created() {
      if (this.sizeChecker(window.innerWidth)) {
        window.addEventListener('keydown', this.typoEventListener);
        this.$once('hook:beforeDestroy', () => {
          window.removeEventListener('keydown', this.typoEventListener);
        });
        headerSticky();
      }
    },
    mounted() {
      // required event-loop
      setTimeout(() => {
        const sections = Array.from(document.getElementsByClassName('js-scroll-section'));
        if (sections && sections.length) {
          const sectionsLinks = Array.from(document.getElementsByClassName('js-nav-section-link'));
          const [linksContainer] = Array.from(document.getElementsByClassName('js-nav-links-container'));
          mainPageScrollMenu(sections, sectionsLinks, linksContainer);
        }
      }, 5000);
    },

    methods: {
      ...mapActions(usePageHintsStore, ['getHints']),
      modalOpener,
      sizeChecker: size => laptopDivisionWidth <= size,
      typoEventListener(event) {
        const enterCode = 13;
        if (enterCode === event.keyCode && event.ctrlKey) {
          const selected = getSelection();
          if (selected.highlighted) {
            this.modalOpener({
              props: {
                initial: selected,
                title: this._('Send typo error'),
                isComponent: SendTypoForm,
              },
            });
          }
        }
      },

    },
  });

  return { app, router, i18n, pinia };
}

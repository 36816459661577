import { accessGuard } from '@aspectus/vue-router-permissions';
import { prefixLanguage } from '@md/urls.js';
import { createRoutes as createCabinetRoutes } from '@sections/cabinet/router';

export function scrollBehavior(to, from, savedPosition) {
  if (savedPosition) {
    return savedPosition;
  }

  if (to.hash) {
    setTimeout(() => {
      document.getElementById(to.hash.substring(1))?.scrollIntoView({
        behavior: 'smooth',
      });
    }, 500);
  }

  return {
    x: 0,
    y: 0,
  };
}

const CabinetRouter = {
  template:
    `<div v-cloak>
      <slot name="header" v-if="showHeaderSlot"/>
      <cabinet-header :static-header="true" @header-mounted="hideSlot" />
      <slot v-if="!isRoute"></slot>
    </div>`,
  data() {
    return {
      isRoute: false,
      showHeaderSlot: true,
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(value) {
        if (value.name) {
          this.isRoute = true;
        } else {
          this.isRoute = false;
        }
      },
    },
  },
  methods: {
    hideSlot() {
      this.showHeaderSlot = false;
    },
  },
};

export function createRouter(options = {}) {
  const { Router, Vue, pinia } = options;
  const router = new Router({
    mode: 'history',
    base: prefixLanguage('/'),
    scrollBehavior,
    routes: window.IS_AUTHENT ? [
      ...createCabinetRoutes({ Router, Vue, pinia }),
      {
        path: '*',
        components: {
          default: CabinetRouter,
        },
      },
    ] : [],
  });

  router.beforeEach(accessGuard(options));

  return router;
}

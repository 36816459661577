import { flatten } from 'ramda';
import { prefixLanguage } from '@md/urls.js';
import { defineStore } from 'pinia';

import { hotels } from './api.js';

export const useHotelsStore = defineStore('hotelsStore', {
  state: () => ({
    selectedHotel: null,
    selectedHotelId: undefined,
    hotelsList: window.HOTELS,
    permissions: new Map(),
    loading: false,
  }),

  getters: {
    managedHotelsLength() {
      const [, { hotels: managedHotelsList = [] }] = this.hotelsList;
      return managedHotelsList.length;
    },
    ownHotelsLength() {
      const [{ hotels: ownHotelsList = [] }] = this.hotelsList;
      return ownHotelsList.length;
    },
    hasHotelPermission() {
      return this.permissions.has(this.selectedHotel && this.selectedHotel.id);
    },
    isLoadingPermission() {
      return this.loading;
    },
  },

  actions: {
    setSelectedHotel(selectedHotel) {
      this.selectedHotel = selectedHotel;
    },
    setSelectedHotelId(selectedHotel) {
      this.selectedHotelId = selectedHotel.id;
    },
    setPermissions([permissions, hotelId]) {
      this.permissions.set(hotelId, permissions);
    },
    setLoading(value) {
      this.loading = value;
    },
    updateHotelierList(hotel) {
      this.hotelsList[0].hotels.push(hotel);
    },

    updateHotel(hotel) {
      const { id: hotelId } = hotel;
      let index = null;
      index = this.hotelsList[0].hotels.findIndex(({ id }) => hotelId === id);
      if (-1 !== index) {
        this.hotelsList[0].hotels[index] = { ...this.hotelsList[0].hotels[index], ...hotel };
        this.setSelectedHotel(this.hotelsList[0].hotels[index]);
        return null;
      }
      if (this.hotelsList[1]) {
        index = this.hotelsList[1].hotels.findIndex(({ id }) => hotelId === id);
        if (-1 !== index) {
          this.hotelsList[1].hotels[index] = { ...this.hotelsList[1].hotels[index], ...hotel };
          this.setSelectedHotel(this.hotelsList[1].hotels[index]);
        }
      }
      return null;
    },
    receivePermission(payload) {
      if (this.permissions.has(payload) || this.loading) return null;
      this.setLoading(true);
      const parameters = { id: payload };
      return hotels.permissions.execute(parameters)
        .then(({ data: { item: permissions = [] } = {} }) => {
          this.setPermissions([permissions, +payload]);
        }).catch(error => {
          this.setPermissions([]);
          console.warn(error);
        }).finally(() => this.setLoading(false));
    },

    initSelectedHotel() {
      if (this.selectedHotel && this.selectedHotel.id) {
        return null;
      }
      return this.parseRouteParams();
    },
    parseRouteParams() {
      const path = window.location.pathname.split('/');
      const pathArray = path.filter(el => el);
      const hotelId = pathArray.find((el, index, arr) => 'hotelier' === arr[index - 2] && 'hotels' === arr[index - 1]);
      if (hotelId && !Number.isNaN(+hotelId)) {
        const selectedHotel = flatten(this.hotelsList.map(x => x.hotels))
          .find(x => x.id === +hotelId);
        if (selectedHotel && -1 !== selectedHotel) {
          this.receivePermission(selectedHotel.id);
          this.setSelectedHotel(selectedHotel);
          this.setSelectedHotelId(selectedHotel);
        } else {
          window.location.assign(prefixLanguage('/403/', { language: window.language }));
        }
      } else {
        const selectedHotel = this.hotelsList[0].hotels[0] ||
          this.hotelsList[1].hotels[0] ||
          null;
        if (selectedHotel) {
          this.receivePermission(selectedHotel.id);
          this.setSelectedHotelId(selectedHotel);
        }
        this.setSelectedHotel(selectedHotel);
      }
    },
  },

});

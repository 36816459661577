import {
  SidebarMenu,
  SidebarMenuElement,
  SidebarMenuLink,
  SidebarMenuNested,
  SidebarMenuSection,
} from './menu';

import Sidebar from './Container';
import SimpleMode from './SimpleMode.vue';

export default function install(Vue, { prefix = 'cabinet-' } = {}) {
  Vue.component(`${prefix}sidebar`, Sidebar);
  Vue.component(`${prefix}sidebar-menu`, SidebarMenu);
  Vue.component(`${prefix}sidebar-menu-element`, SidebarMenuElement);
  Vue.component(`${prefix}sidebar-menu-link`, SidebarMenuLink);
  Vue.component(`${prefix}sidebar-menu-section`, SidebarMenuSection);
  Vue.component(`${prefix}sidebar-menu-nested`, SidebarMenuNested);
  Vue.component('simple-mode', SimpleMode);
}

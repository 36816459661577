import { route } from '@md/routing';
// import { accessPermission } from '@md/users/permissions';
// import {
//   TRANSACTIONS_VIEW_LIST,
// } from '@md/transactions/accessTokens';

const List = () => import('./views/List');

const TRANSACTIONS_PREFIX = 'dmc:transactions:';
const p = name => TRANSACTIONS_PREFIX + name;
export const DMC_TRANSACTIONS_LIST = p('list');

// export function createRoutes(options) {
export function createRoutes() {
  return [
    route('list/', List, DMC_TRANSACTIONS_LIST, {
      // meta: accessPermission(TRANSACTIONS_VIEW_LIST, options),
    }),

    { path: '', redirect: { name: DMC_TRANSACTIONS_LIST } },
  ];
}

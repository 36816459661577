import { route } from '@md/routing';
import { HOTELIER_CHAIN_HOTEL_PROFILE } from '@sections/cabinet/modules/hotelier_chain/modules/hotels/router';

const List = () => import('./views/List');

export const HOTELIER_CHAIN_HOTEL_LOYALTY = 'hotelier_chain:hotels:bonus:loyalty:list';

export function createRoutes() {
  return [
    route('list/', List, HOTELIER_CHAIN_HOTEL_LOYALTY, {
      meta: {
        parentRoute: HOTELIER_CHAIN_HOTEL_PROFILE,
      },
    }),
  ];
}

<template lang="pug">
include /mixins.pug
+b.ds-section__element(v-if="isMobile")
  +b.ds-card
    +b.ds-panel
      control-old-button(
        type="button",
        appearance="full",
        size="lg-xl",
        @click="toggle"
        styling="brand-2",
      ) {{ buttonText }}
</template>

<script>
import helperMixin from '@md/filter/mixins/HelperMixin';

export default {
  name: 'toggle-button',
  mixins: [helperMixin],
  props: {
    targetId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isVisible: true,
      openText: this.pgettext('modules:hotels:services', 'Show more services'),
      closeText: this.pgettext('modules:hotels:services', 'Show less'),
    };
  },
  mounted() {
    if (this.isMobile) {
      const targetElement = document.getElementById(this.targetId);
      if (targetElement) {
        this.isVisible = false;
        targetElement.style.display = 'none';
        this.buttonText = this.openText;
      }
    }
  },
  computed: {
    buttonText() {
      return this.isVisible ? this.closeText : this.openText;
    },
  },
  methods: {
    toggle() {
      const targetElement = document.getElementById(this.targetId);
      if (targetElement) {
        this.isVisible = !this.isVisible;
        targetElement.style.display = this.isVisible ? 'block' : 'none';
      }
    },
  },
};
</script>

import { COMMENTS_NATURAL_KEY } from '@md/comments/const.js';
import { authGuardConfig } from '@md/auth/mixins/AuthProtectionMixin.js';

const CreateComment = () => import('@md/comments/components/Create.vue');

export const CommentCreateMixin = {

  methods: {
    getModalConfigCommentCreate({ id, keyType, reaction }) {
      return authGuardConfig({
        props: {
          initial: {
            id,
            type: COMMENTS_NATURAL_KEY[keyType],
          },
          keyType,
          isComponent: CreateComment,
        },
        reaction,
      });
    },
  },
};

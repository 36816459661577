import { route, prefixRoutes } from '@md/routing';

import { createRoutes as createBonusProgramRoutes } from '@sections/cabinet/modules/hotelier/modules/bonus-programs/router';
import { createRoutes as createProfileRoutes } from './modules/profile/router.js';
import { createRoutes as createChatsRoutes } from './modules/chats/router.js';
import { createRoutes as createHotelsRoutes } from './modules/hotels/router.js';
import { createRoutes as createAppealsRoutes } from './modules/appeals/router.js';
import { createRoutes as createFaqRoutes } from './modules/faq/router.js';
import { createRoutes as createNotificationsRoutes } from './modules/notifications/router.js';
import { createRoutes as createTransactionsRoutes } from './modules/transactions/router.js';
import { createRoutes as createApplicationsRoutes } from './modules/applications/router.js';
import { createRoutes as createBookingRoutes } from './modules/booking/router.js';
import { createRoutes as createReviewsRoutes } from './modules/reviews/router.js';

const Route404 = () => import('@sections/404.vue');
const Home = () => import('./modules/home/views/View.vue');
const List = () => import('./modules/managers/views/List');
const Statistics = () => import('./modules/statistics/Views.vue');

export const HOTELIER_HOME = 'hotelier:home';
export const HOTELIER_MANAGERS = 'hotelier:managers';
export const HOTELIER_STATISTICS = 'hotelier:statistics';
export const HOTELIER_BONUS_PROGRAMS = 'hotelier:bonus_programs';

export function createRoutes(options) {
  return [
    route('home/', Home, HOTELIER_HOME),
    route('managers/', List, HOTELIER_MANAGERS),
    route('statistics/', Statistics, HOTELIER_STATISTICS),

    prefixRoutes('bonus-program/', createBonusProgramRoutes(options)),
    prefixRoutes('profile/', createProfileRoutes(options)),
    prefixRoutes('chats/', createChatsRoutes(options)),
    prefixRoutes('notifications/', createNotificationsRoutes(options), {}),
    prefixRoutes('hotels/', createHotelsRoutes(options)),
    prefixRoutes('booking/', createBookingRoutes(options), {}),
    prefixRoutes('reviews/', createReviewsRoutes(options), {}),
    prefixRoutes('applications/', createApplicationsRoutes(options), {}),
    prefixRoutes('transactions/', createTransactionsRoutes(options)),
    prefixRoutes('appeals/', createAppealsRoutes(options)),
    prefixRoutes('faq/', createFaqRoutes()),
    { path: '', redirect: 'home/' },
    { path: '*', component: Route404 },
  ];
}

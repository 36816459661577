import { route } from '@md/routing';

const List = () => import('./views/Participants');

export const AGENT_NIGHTS_LIST = 'agent:nights:list';

export function createRoutes() {
  return [
    route('list/', List, AGENT_NIGHTS_LIST),
    { path: '', redirect: { name: AGENT_NIGHTS_LIST } },
  ];
}

<template lang='pug'>
  resource-loader-controller(
    :resource="attributesResource"
    v-slot="allProps"
  )
    resource-loader-controller(
      v-if="availableAttributesResource"
      :resource="availableAttributesResource"
      v-slot="availableProps"
    )
      search-filter(
        :value="filters"
        :filter-controller="filterController"
        @input="changeFilters"
        :all-props="allProps",
        :available-props="availableProps",
        :pagination='pagination'
        :need-filters='needFilters',
        :need-sorting='needSorting',
        :needSearch='needSearch',
        :recommendedTags='recommendedTags',
        :label='label',
        :sortlist='sortlist',
      )
        template(#content="props")
          slot(name="content" v-bind="props")
    search-filter(
      v-else
      :filter-controller="filterController"
      :value="filters"
      @input="changeFilters"
      :all-props="allProps",
      :pagination='pagination'
      :need-filters='needFilters',
      :need-sorting='needSorting',
      :needSearch='needSearch',
      :recommendedTags='recommendedTags',
      :label='label',
      :sortlist='sortlist',
    )
      template(#content="props")
        slot(name="content" v-bind="props")

</template>
<script>

import SearchFilter from '@md/filter';

export default {
  components: { SearchFilter },
  props: [
    'filterController',
    'space',
    'filters',
    'pagination',
    'attributesResource',
    'availableAttributesResource',
    'needFilters',
    'needSorting',
    'needSearch',
    'recommendedTags',
    'label',
    'sortlist',
  ],
  methods: {
    selectTags() {

    },
    changeFilters(value) {
      this.$emit('update:filters', value);
    },
  },
};

</script>

import { createReceiver, createSender } from '@md/resources';
import { defaultDataTransformer } from '@aspectus/resource-commons';
import { prefixAPI } from '@md/api.js';

const r = (tpl, f = createReceiver) => f(prefixAPI(tpl)).transform(defaultDataTransformer);

export const nightsBalance = {
  hotel: r('/nights-balance/hotel{/id}/retrieve/'),
  purchase: r('/nights-balance/purchase/', createSender),
  purchaseCalculate: r('/nights-balance/purchase/calculate/', createSender),
  exchange: r('/nights-balance/exchange/', createSender),
  exchangeCalculate: r('/nights-balance/exchange/calculate/', createSender),
  return: r('/nights-balance/return/', createSender),
  returnCalculate: r('/nights-balance/return/calculate/', createSender),
};

export const nightsSelling = {
  list: r('/nights-selling/list/{?filters,limit,offset}'),
  filters: {
    list: r('/nights-selling/filters/list/'),
    available: r('/nights-selling/filters/list/{?filters}'),
  },
  purchase: r('/nights-selling/purchase/', createSender),
  purchaseCalculate: r('/nights-selling/purchase/calculate/', createSender),
  create: r('/nights-selling/create/', createSender).config('method', 'POST'),
  cancel: r('/nights-selling{/id}/cancel/', createSender).config('method', 'POST'),
};

export const hotelsParticipants = {
  list: r('/hotels/participants/receiving/list/{?filters,limit,offset,order_by}'),
  listAgent: r('/hotels/participants/receiving/list/agent/{?filters,limit,offset,order_by}'),
  filters: {
    list: r('/hotels/participants/receiving/filters/list/'),
    available: r('/hotels/participants/receiving/filters/list/{?filters}'),
  },
};

export const nightsStock = {
  list: r('/nights-stock/list/{?filters,limit,offset}'),
  filters: {
    list: r('/nights-stock/filters/list/'),
    available: r('/nights-stock/filters/list/{?filters}'),
  },
};

export const dailyPrices = {
  list: r('/hotels/participants/daily-price/list/{?filters,limit,offset}'),
  filters: {
    list: r('/hotels/participants/daily-price/filters/list/'),
    available: r('/hotels/participants/daily-price/filters/list/{?filters}'),
  },
};

<template lang="pug">
include /mixins
+b.relative
  ui-loading(v-if="loading")
  +b.ds-panel
    +b.g-row--appearance_spaced.--align_center.--space_xs.--space_md-md
      +b.g-cell.g-cols
        ds-caption(size="md") {{ _("Room allocation:")}}
      +b.g-cell.g-cols
        vue-selection-controller(
          v-model="parameters.filters.type__allocations",
          :options="typeAllocations || []"
          :key-getter='(value) => value.id'
          :multiple="true"
          v-slot="controller",
        )
          +b.g-row--appearance_spaced.--align_center.--space_xs.--space_md-md
            template(v-for="(option, index) in controller.options")
              +b.g-cell.g-cols(
                v-if="availableAllocationsIds.includes(option.value.id)"
                :key="index"
              )
                +b.hashtag-button--size_md(
                  :class="{'is-selected': option.selected}"
                  @click='controller.change(option.value)'
                ) {{ option.value.caption }}
    +e.element--offset_vertical(
      v-if="!items.length && !loading"
    )
      +b.P.ds-caption--size_md.--size_xl-xl.--color_2 {{ _("Rooms list empty") }}
    +e.element--offset_top(
      v-else
    )
      row(
        appearance="spaced",
        space="lg-xl",
        justify="start",
      )
        cell(
          v-for="(room, index) in items",
          :key="`${room.id}_${index}`",
          cols="12 6-sm 4-md",
        )
          room-preview(
            :key="`${room.id}_${index}`",
            :object="room"
          )
      row(
        appearance="spaced",
        justify="center"
      )
        cell(
          v-if="hasMore"
          cols="narrow"
        )
          control-old-button(
            styling="secondary",
            type="button",
            :disabled="loading || loadingMoreContent || !hasMore"
            @click="paginate",
          ) {{ _("Load more") }}
        cell(
          v-if="pagination.offset >= pagination.limit"
          cols="narrow"
        )
          control-old-button(
            styling="secondary",
            type="button",
            :disabled="loading || loadingMoreContent"
            @click="resetToFirstLoad",
          ) {{ _("Hide") }}

</template>
<script>
import { clone } from 'ramda';
import { rooms } from '@md/hotels/api.js';
import LoadingStateMixin from '@aspectus/vue-loading-state-mixin';
import { RoomStatus } from '@md/hotels/const.js';

const RoomPreview = () => import('./RoomPreview.vue');

export default {
  name: 'public-rooms-list',
  mixins: [LoadingStateMixin],
  components: {
    RoomPreview,
  },
  props: {
    hotelId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      items: [],
      typeAllocations: [],
      availableAllocationsIds: [],
      parameters: {
        filters: {
          type__allocations: [],
          hotel: this.hotelId,
          status: RoomStatus.PUBLISHED,
        },
        limit: 9,

      },
      initial: {},
      pagination: {
        offset: null,
        limit: null,
        total: null,
      },
      loadingMoreContent: false,
    };
  },
  computed: {
    hasMore() {
      return this.pagination.offset + this.pagination.limit < this.pagination.total;
    },
  },
  watch: {
    'parameters.filters.type__allocations': {
      handler(val, old) {
        if (val && val[0] || null === (val && val[0]) || undefined !== old) {
          this.getItems(this.parameters);
        }
      },
    },
  },
  created() {
    this.getItems(this.parameters);
    this.getFilters();
    this.getAvailableFilters(this.parameters);
  },
  methods: {
    resetToFirstLoad() {
      this.items.splice(this.pagination.limit);
      this.pagination.offset = 0;
    },
    getFilters() {
      this.$load(rooms.filters.list.execute())
        .then(({ data }) => {
          this.typeAllocations = (0 < data.items?.length ? data.items?.find(({ id }) => 'type__allocations' === id)?.props?.items : []);
        });
    },
    getAvailableFilters(parameters) {
      const transformed = clone(parameters);
      transformed.filters.type__allocations = transformed.filters.type__allocations[0] ?
        transformed.filters.type__allocations.map(el => el && el.id || undefined) || undefined : undefined;
      transformed.filters = JSON.stringify(transformed.filters);

      this.$load(rooms.filters.available.execute(transformed))
        .then(({ data }) => {
          this.availableAllocationsIds = (0 < data.items?.length ? data.items?.find(({ id }) => 'type__allocations' === id)?.props?.id : null);
        });
    },
    getItems(parameters) {
      const transformed = clone(parameters);
      transformed.filters.type__allocations = transformed.filters.type__allocations && transformed.filters.type__allocations[0] ?
        transformed.filters.type__allocations.map(el => el && el.id || undefined) || undefined : undefined;
      transformed.filters = JSON.stringify(transformed.filters);

      this.$load(rooms.publicList.execute(transformed))
        .then(({ data }) => {
          if (parameters.offset) {
            this.items.push(...data.items);
          } else {
            this.items = data.items;
          }
          this.pagination = data.pagination;
          this.loadingMoreContent = false;
        });
    },
    paginate(isVisible) {
      if (this.loading || !isVisible) {
        return;
      }
      this.loadingMoreContent = true;
      const parameters = {
        ...this.parameters,
        limit: this.pagination.limit,
        offset: this.pagination.offset + this.pagination.limit,
      };
      this.getItems(parameters);
    },

  },
};
</script>

export default {
  data() {
    return {
      initialSelected: [],
    };
  },
  computed: {
    isDesktop() {
      return 768 <= window.innerWidth;
    },
    isTablet() {
      return 810 >= window.innerWidth;
    },
    isMobile() {
      return 564 > window.innerWidth;
    },
  },
  methods: {
    expandAll(ctrl, event) {
      if (event.target.checked) {
        this.initialSelected = ctrl.result.items;
      } else {
        this.initialSelected = [];
      }
    },
    dateFormat(date) {
      const year = new Date(date).getFullYear().toString().substr(-2);
      const day = new Date(date).getDate().toString().padStart(2, '0');
      const month = (new Date(date).getMonth() + 1).toString().padStart(2, '0');

      return `${day}.${month}.${year}`;
    },
    timeFormat(date) {
      const hours = (`0${ new Date(date).getHours()}`).slice(-2);
      const minutes = (`0${ new Date(date).getMinutes()}`).slice(-2);

      return ` ${hours}:${minutes}`;
    },
    fixTransactionDescriptionHrefs(text, role) {
      /*
        Replaces the hotelier href prefix in text with specific role, to prevent access errors.
        */
      if ('hotelier' === role) return text;

      return text
        // replace /hotelier/hotels/:hotel_id/applications/:application_id/edit/
        // with    /$role/applications/:application_id/edit/
        .replace(/\/hotelier\/hotels\/(\d+)\/applications\/(\d+)\/edit\//g, `/${role}/applications/$2/edit/`)
        // replace /hotelier/hotels/:hotel_id/booking/:booking_id/edit/
        // with    /$role/booking/:booking_id/edit/
        .replace(/\/hotelier\/hotels\/(\d+)\/booking\/(\d+)\/edit\//g, `/${role}/booking/$2/edit/`);
    },
  },
};

import { route } from '@md/routing';

const View = () => import('./View');

export const STAFF_CHATS = 'staff:chats';

export function createRoutes() {
  return [
    route('', View, STAFF_CHATS),
  ];
}

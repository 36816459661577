import { route } from '@md/routing';
// import { accessPermission } from '@md/users/permissions';
// import {
//   TRANSACTIONS_VIEW_LIST,
// } from '@md/transaction/accessTokens';

const List = () => import('./views/List');

const BALANCE_PREFIX = 'agent:balance:';
const p = name => BALANCE_PREFIX + name;
export const AGENT_BALANCE_LIST = p('list');

// export function createRoutes(options) {
export function createRoutes() {
  return [
    route('', List, AGENT_BALANCE_LIST, {
      // meta: accessPermission(BALANCE_VIEW_LIST, options),
    }),

    { path: '', redirect: { name: AGENT_BALANCE_LIST } },
  ];
}

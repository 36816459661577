<template lang="pug">
include /mixins
.relative
  ui-loading(v-if="loading")
  +b.ds-panel--space_lg.negative(
    v-if="isRefuseComment"
  )
    +b.SPAN.ds-caption--bold
      | {{ refuseCommentTitle }}
    | {{ refuseCommentText }}
  +b.ds-panel--space_lg(
    v-cloak
    v-if="showHead"
  )
    +e.element--offset_bottom(
      v-if="'video' == keyType"
    )
      +e.element--offset_horizontal
        +b.g-row--appearance_spaced.--align_center
          +b.g-cell.g-cols--12.--narrow-md
            +b.H3.ds-caption--color_2.--bold.--size_md.--appearance_uppercase
              | {{ _("Comments") }} ({{ pagination && pagination.total }})
      +e.element--offset_bottom
        slot(
          name="default"
          :add-comment="addComment"
        )
    +e.element--offset_bottom(
      v-else
    )
      row(appearance="spaced", space="md", align="center")
        cell(cols="12 auto-sm")
          ds-caption(
            size="md",
            tag="p"
          ) {{ helpText }}

  row(
    appearance="spaced",
    space="lg"
  )
    cell(
      cols="12"
      v-for="(item, index) in list",
      :key="item.id",
    ): +b.comment.pt-0.pb-0(
      :class="[ commentCardGap ? 'ds-card--space_xs' : '' ]"
    )
      comment(
        :key-type="keyType",
        :item="item",
        :need-complaint="needComplaint",
        :collapse-subcomment="collapseSubcomment",
        @finish="updateComment",
      )
  ds-panel-element(
    offset="top",
    v-if="pagination && pagination.total > pagination.limit"
  )
    catalog-pagination(
      @input="changePagination",
      v-bind="pagination",
    )
  row(v-if="canAddComment && showHead", justify="end")
    cell(
      cols="narrow"
    )
      control-old-button(
        styling="ghost",
        size="md",
        type="button",
        @click="addComment",
      )
        ds-panel(:space="['lg', '3xl-xl']"): ds-panel-element(offset="horizontal")
          p {{ _("Add comment") }}

</template>
<script>
import LoadingStateMixin from '@aspectus/vue-loading-state-mixin';
import { clone } from 'ramda';
import { comments } from '@md/comments/api.js';
import { modalOpener } from '@utils/helpers.js';
import { composeTransformers } from '@utils/transformers';
import { simplifiers, filterAPIMiddleware } from '@md/filter/transformers';
import { COMMENTS_NATURAL_KEY } from '@md/comments/const.js';
import { CommentCreateMixin } from '@md/comments/mixins.js';

const Comment = () => import('./Item.vue');

const filtersToAPITransformer = composeTransformers(
  simplifiers.simple('id'),
  simplifiers.simple('type')
);

const filterMiddleware = filterAPIMiddleware(filtersToAPITransformer);

export default {
  name: 'comments-list',
  components: { Comment },
  mixins: [LoadingStateMixin, CommentCreateMixin],
  props: {
    showHead: {
      type: Boolean,
      default: true,
    },
    id: {
      type: [String, Number],
      required: true,
    },
    keyType: {
      type: String,
      required: true,
    },
    needComplaint: {
      type: Boolean,
    },
    collapseSubcomment: {
      type: Boolean,
      default: true,
    },
    contextUpdater: {
      default: null,
    },
    canAddComment: {
      type: Boolean,
      default: true,
    },
    commentCardGap: {
      type: Boolean,
      default: true,
    },
    helpText: {
      type: String,
      default: '',
    },
    isRefuseComment: {
      type: Boolean,
      default: false,
    },
    refuseCommentTitle: {
      type: String,
      default: '',
    },
    refuseCommentText: {
      type: String,
      default: '',
    },
    initialParams: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      list: [],
      pagination: {
        limit: 1,
        total: 0,
      },
      parameters: {
        filters: {
          type: COMMENTS_NATURAL_KEY[this.keyType] || COMMENTS_NATURAL_KEY.video,
          id: this.id,
        },
        ...this.initialParams,
      },
    };
  },
  async mounted() {
    await this.getList(this.parameters);
    if (this.contextUpdater) this.$once('hook:beforeDestroy', () => { this.contextUpdater(); });
  },
  methods: {
    modalOpener,
    addComment() {
      this.modalOpener(this.getModalConfigCommentCreate({ id: this.id, keyType: this.keyType, reaction: this.finishCreateComment }));
    },
    finishCreateComment() {
      this.getList(this.parameters);
      this.$emit('update-context');
    },
    updateComment(comment) {
      const sameId = el => el.id === comment.id;
      const index = this.list.findIndex(sameId);
      if (-1 !== index) {
        this.list[index].children = comment.children;
      }
      //  else {
      //   const sameIdForComplaint = el => el.id === comment.comment;
      //   const indexComment = this.list.findIndex(sameIdForComplaint);
      //   this.list[indexComment].children = comment.children;
      // }
    },
    getList(parameters = this.parameters) {
      const params = clone(parameters);
      this.$load(comments.list.middleware(filterMiddleware).execute(params))
        .then(({ data }) => {
          this.list = data.items;
          this.pagination = data.pagination;
        }).catch(err => {
          console.warn(err);
        });
    },
    changePagination(pagination) {
      if (!this.loading) {
        const parameters = {
          ...this.parameters,
          ...pagination,
        };
        this.getList(parameters);
      }
    },
  },
};
</script>

<template lang="pug">
include /mixins
validation-provider(
  rules="required",
  :name='`${category.label}_${category.category}`'
  v-slot="{ errors }"
  tag="div",
)
  +b.tr-rating(
    :class="[ variant ? `tr-rating--variant_${variant}` : '' ]"
  )
    .relative
      +b.error-old-label--top(v-if="errors && errors.length") {{ errors[0] }}
    ds-panel(:space="['none', 'xs-md']"): ds-panel-element(offset="right")
      row(
        appearance="spaced",
        justify="between",
      )
        cell(cols="auto")
          +b.tr-rating__category
            ds-inliner(
              v-if="category.icon",
              size="4xl",
            )
              +b.IMG.img(
                :src="category.icon",
              )
            | {{ category.title }}
        cell(cols="narrow")
          +b.tr-rating__rating(
            variant="strong",
            size="2md"
          ) {{ internal || 0 }}

    +e.lines
      +e.LABEL.label(
        v-for='rating in ratings'
        :data-rating-value="internal",
        :class="{\
          'is-active': (temp_value >= rating && temp_value != null),\
          'is-disabled': disabled,\
        }"
        @click='set(rating)'
        @mouseover='starOver(rating)'
        @mouseout='starOut'
      )
        +e.line

        input.tr-rating__checkbox(
          type='radio'
          :value='rating'
          :name='`${name}_${category.category}`'
          v-model='temp_value'
          :disabled='disabled'
        )

</template>
<script>

export default {
  name: 'rating-line',

  props: {
    variant: {
      type: String,
      default: null,
    },
    value: {
      type: [String, Number],
      required: false,
    },
    category: {
      type: Object,
      default: () => ({}),
    },
    name: {
      type: String,
      default: 'rating',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: Boolean,
  },
  data() {
    return {
      internal: this.value,
      temp_value: this.value,
      ratings: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    };
  },
  computed: {
    model: {
      get() {
        return this.internal;
      },
      set(value) {
        this.internal = +value;
      },
    },
  },
  watch: {
    internal(val) {
      this.$emit('input', val);
    },
  },
  methods: {
    starOver(index) {
      if (!this.disabled) {
        this.internal = this.temp_value;
        this.temp_value = index;
      }
    },
    starOut() {
      if (!this.disabled) {
        this.temp_value = this.internal;
      }
    },
    set(value) {
      if (!this.disabled) {
        this.temp_value = value;
        this.internal = value;
      }
    },
  },
};
</script>

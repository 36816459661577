import { route } from '@md/routing';

const View = () => import('./View');

export const HOTELIER_CHATS = 'hotelier:chats';

export function createRoutes() {
  return [
    route('', View, HOTELIER_CHATS),
  ];
}

import { renderSlim } from '@aspectus/vue-utils';
import { useHotelsStore } from '@md/hotels/store.js';
import { useAuthStore } from '@md/auth/store.js';
import { usePageHintsStore } from '@md/page_hints/store.js';
import { userTypeHintCabinetMap } from '@md/page_hints/const.js';

export default {
  name: 'auth-provider',
  props: {
    isHotelier: {
      type: Boolean,
    },
  },
  async setup(props) {
    const authStore = useAuthStore();
    await authStore.receive();
    const hotelsStore = useHotelsStore();
    if (props.isHotelier) {
      await hotelsStore.initSelectedHotel();
    }
    const pageHintsStore = usePageHintsStore();
    if (userTypeHintCabinetMap[authStore.user.info.userType]) {
      const filters = JSON.stringify({ chapter: userTypeHintCabinetMap[authStore?.user?.info?.userType] });
      await pageHintsStore.getHints(filters);
    }
    console.log();
  },
  render(h) {
    const { default: c } = this.$scopedSlots;
    return c ? renderSlim(c(), h, 'tag') : h(null);
  },
};

import {
  FILTERS_SHORTEN,
  FILTERS_EXTENDED,
  FILTERS_ALL,
  omitExtended,
} from '@md/filter/const';

const FilterController = () => import('@md/filter/controllers/Controller.vue');
const CatalogVideoController = () => import('@md/filter/controllers/CatalogVideoController.vue');

export default {
  props: [
    'filterController',
    'value',
    'allProps',
    'availableProps',
    'recommendedTags',
    'pagination',
    'needFilters',
    'needSorting',
    'needSearch',
    'sortlist',
    'label',
  ],

  components: {
    FilterController,
    CatalogVideoController,
  },

  data() {
    const allFilters = FILTERS_EXTENDED
      .some(key => (this.value.filters &&
        key in this.value.filters && this.value.filters[key]));
    return {
      allFilters,
      displayedFilters: this.getDisplayedFilters(allFilters),
    };
  },
  methods: {
    handleFilter(parameters) {
      this.$emit('input', this.clearExtendedFilters(parameters));
    },

    toggleAllFilters() {
      this.setFiltersAvailability(!this.allFilters);
    },

    getDisplayedFilters(all) {
      return all ? FILTERS_ALL : FILTERS_SHORTEN;
    },

    clearExtendedFilters(parameters) {
      if (this.allFilters) {
        return parameters;
      }

      const { filters = {}, ...rest } = parameters;
      return { filters: omitExtended(filters), ...rest };
    },

    setFiltersAvailability(all) {
      this.allFilters = all;
      this.displayedFilters = this.getDisplayedFilters(this.allFilters);
    },
  },
};

import { route } from '@md/routing.js';

const List = () => import('./views/List');

const Create = () => import('./views/Create.vue');

const Edit = () => import('./views/Edit.vue');

const ROOMS_PREFIX = 'admin:rooms:';
const p = name => ROOMS_PREFIX + name;

export const ADMIN_ROOMS_LIST = p('list');

export const ADMIN_ROOM_CREATE = p('create');

export const ADMIN_ROOM_EDIT = p('edit');

export function createRoutes() {
  return [
    route('list/', List, ADMIN_ROOMS_LIST),
    route('create/', Create, ADMIN_ROOM_CREATE),
    route('edit/', Edit, ADMIN_ROOM_EDIT),
  ];
}

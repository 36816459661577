import { isValue, getKey, toArray } from '@aspectus/selection-controller';

// Data structure consists of two required fields:
// field: str - Field name
// direction: enum(asc|desc) - Order direction

export const ASC = 'asc';
export const DESC = 'desc';
export const DIRECTIONS_PATH = {
  [null]: ASC,
  [ASC]: DESC,
  [DESC]: null,
};

export function defaultKeyGetter(value) {
  return value && value.field;
}

export function defaultDirectionGetter(value) {
  return value && value.direction || null;
}

export function getNextIntPath(
  current, path = DIRECTIONS_PATH,
  directionGetter = defaultDirectionGetter
) {
  const direction = directionGetter(current);

  return { ...current, direction: path[direction] || null };
}

export function updateValue(
  needle, current,
  multiple = false, path = DIRECTIONS_PATH,
  keyGetter = defaultKeyGetter, directionGetter = defaultDirectionGetter
) {
  let value = needle;
  const key = getKey(needle, keyGetter);

  if (!isValue(key) && !Array.isArray(needle)) return [];

  if (!Array.isArray(needle)) {
    if (!multiple || !needle) {
      value = toArray(current).find(x => getKey(x, keyGetter) === key) || needle;
      const next = getNextIntPath(value, path, directionGetter);

      value = [next];

      // if (directionGetter(next) === null) {
      //   // There's no next, so the path is finished
      //   value = [];
      // } else {
      //   // Or we're still traversing the path
      //   value = [next];
      // }
    } else {
      value = current.slice();
      const index = value.findIndex(x => getKey(x, keyGetter) === key);
      const includes = -1 !== index;
      const next = getNextIntPath(includes ? value[index] : needle, path, directionGetter);

      if (null === directionGetter(next)) {
        value.splice(index, 1);
      } else if (includes) {
        value[index] = next;
      } else {
        value.push(next);
      }

      value = value.filter(x => isValue(x) && isValue(getKey(x, keyGetter)));
    }
  }

  return value;
}

import { route } from '@md/routing';

const List = () => import('./views/List');

export const REPRESENTATIVE_NOTIFICATIONS_LIST = 'representative:notifications:list';

export function createRoutes() {
  return [
    route('list/', List, REPRESENTATIVE_NOTIFICATIONS_LIST),
    { path: '', redirect: { name: REPRESENTATIVE_NOTIFICATIONS_LIST } },
  ];
}

import { UserBase } from './base';

export class User extends UserBase {
  constructor(info, determined = true) {
    super();

    this.determined = determined;
    this.info = info || this.info;
  }
}

import {
  evolve, map, pipe, filter, not,
} from 'ramda';
import { isEmpty } from '@aspectus/vue-utils';
import {
  keyedTransformer as k,
  sameTransformer,
  composeTransformers,
} from '@utils/transformers.js';
import { parametersMiddleware } from '@aspectus/resource';

const checkEmpty = value => (isEmpty(value) ? undefined : value);
// eslint-disable-next-line no-nested-ternary
const checkUrl = value => {
  if (null === value) return value;
  if (value && 'string' === typeof value && value.startsWith('data')) return value;
  return undefined;
};
const ar = value => (Array.isArray(value) ? value : [value]);
// const selectTo = curry((filter, id) => filter.props.items.find(({ id }) => id === id));
const selectFrom = ({ id }) => id;
const multiselectFrom = pipe(ar, map(selectFrom), filter(pipe(isEmpty, not)));
const dateFrom = date => new Date(date).toISOString();
const dateOnlyFrom = date => {
  const dateTransform = new Date(date);
  return `${dateTransform.getFullYear()}-${dateTransform.getMonth() + 1}-${dateTransform.getDate()}`;
};
const dateTo = date => (date ? new Date(date) : null);
const dateRangeFrom = evolve({ min: dateFrom, max: dateFrom });
const dateOnlyRangeFrom = evolve({ min: dateOnlyFrom, max: dateOnlyFrom });
const combinedInputFrom = map(composeTransformers(
  k(selectFrom, 'element', 'id'),
  k(sameTransformer, 'search')
));

export const simplifiers = {
  same: key => k(sameTransformer, key),
  simple: key => k(pipe(sameTransformer, checkEmpty), key),
  photo: key => k(pipe(sameTransformer, checkUrl), key, key, false, null),
  search: key => k(pipe(sameTransformer, checkEmpty), key),
  select: key => k(pipe(selectFrom, checkEmpty), key),
  selectSimple: key => k(pipe(selectFrom), key),
  multiselect: key => k(pipe(multiselectFrom, checkEmpty), key),
  multiselectSimple: key => k(pipe(multiselectFrom), key),
  range: key => k(pipe(sameTransformer, checkEmpty), key),
  dateTo: key => k(pipe(dateTo), key),
  dateRange: key => k(pipe(dateRangeFrom, checkEmpty), key),
  dateOnlyRangeFrom: key => k(pipe(dateOnlyRangeFrom, checkEmpty), key),
  onlyDate: key => k(pipe(dateOnlyFrom, checkEmpty), key),
  autocompleteMultiselect: key => k(pipe(multiselectFrom, checkEmpty), key),
  combinedInput: key => k(pipe(combinedInputFrom, checkEmpty), key),
};

export const filterAPIMiddleware = transformer => parametersMiddleware(parameters => ({
  ...parameters,
  filters: JSON.stringify(transformer(parameters.filters)),
}));

export function friendlyUrlParser(splittedPart) {
  let path = decodeURIComponent(new URL(window.location.href));
  const zero = 0;
  const parsedUrl = {};
  if (path.includes(splittedPart)) {
    [path] = path.split('/page');
    [, path] = path.split(splittedPart);
    if (!path) {
      return parsedUrl;
    }
    const hashes = path.split('/')[0].split(';').filter(hash => !!hash);
    hashes.map(hash => {
      const [key] = hash.split('=');
      let [, val] = hash.split('=');

      parsedUrl[key] = [];
      if (val.includes(',')) {
        val = val.split(',');
        val.forEach(v => {
          if (v.includes('/')) {
            parsedUrl[key].push(decodeURIComponent(v.split('/')[zero]));
          } else {
            parsedUrl[key].push(decodeURIComponent(v));
          }
        });
      } else if (val.includes('/')) {
        parsedUrl[key].push(decodeURIComponent(val.split('/')[zero]));
      } else {
        parsedUrl[key].push(decodeURIComponent(val));
      }
      return false;
    });
  }
  return parsedUrl;
}

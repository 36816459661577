import { filesUploadResource } from '@md/files/api.js';
import { auth } from '@md/users/api.js';
import { locations } from '@md/locations/api.js';
import { video } from '@md/video/api.js';
import { agents } from '@md/agents/api.js';
import { hotels } from '@md/hotels/api.js';
import { touroperators } from '@md/touroperators/api.js';
import { appeals } from '@md/appeals/api.js';
import { faq } from '@md/faq/api.js';
import { comments } from '@md/comments/api.js';
import { likes } from '@md/likes/api.js';

const API = {
  files: {
    upload: filesUploadResource,
  },
  ...auth,
  locations,
  // global $api use for video view in hotels/room.pug
  video,
  agents,
  hotels,
  touroperators,
  appeals,
  faq,
  comments,
  likes,
  // chat: {
  //   rooms: {
  //     list: roomsListResource,
  //     hidden: roomsUpdateResource,
  //   },
  //   messages: {
  //     list: messagesListResource,
  //     unread: messagesUnreadResource,
  //     create: messagesCreateResource,
  //     actions: {
  //       read: messagesActionsReadResource,
  //     },
  //   },
  // },
};

function install(Vue) {
  Vue.prototype.$api = API;
}

export default { install };

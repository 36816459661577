<template lang="pug" functional>

ds-table(tag="table", v-bind="data.attrs", :class="[data.staticClass, data.class]")
  slot(name="head", :fields="props.fields")
    thead(class="ds-table__head", v-if="props.displayHeader")
      ds-table-row(:relief="true")
        ds-table-cell(
          tag="th", :head="true",
          v-for="field in props.fields",
          :key="field.key",
          :data-title="field.label",
          :appearance="[`field-${field.key}`, `head-${field.key}`]"
        )
          slot(:name="`head-cell-${field.key}`", :fields="props.fields", :field="field")
            slot(name="head-cell", :fields="props.fields", :field="field")
              | {{ field.label }}

        ds-table-cell(tag="th", :head="true", v-if="scopedSlots.action", appearance="action-head")
          slot(name="action-head")

  slot(
    name="item",
    v-for="(item, i) in props.items",
    :fields="props.fields", :item="item", :index="i"
  )
    vue-table-item(:key="i", :fields="props.fields", :item="item", :index="i", :row-class="item.cssClass")
      template(v-for="(slot, key) in scopedSlots", v-slot:[key]="scope")
        slot(:name="key", v-bind="scope")

  slot(
    name="after-body",
  )
    vue-table-item(
      :fields="props.aggregateFields",
      :item="props.aggregate",
      :index="1",
      v-if="props.aggregate"
      :relief="true"
    )

  slot(name="foot", :fields="props.fields")
    tfoot(class="ds-table__foot", v-if="props.displayFooter")
      ds-table-row(:relief="true")
        ds-table-cell(
          tag="th", :head="true",
          v-for="field in props.fields"
          :key="field.key"
          :data-title="field.label"
          :appearance="[`field-${field.key}`, `head-${field.key}`]"
        )
          slot(:name="`foot-cell-${field.key}`", :fields="props.fields", :field="field")
            slot(name="foot-cell", :fields="props.fields", :field="field")
              | {{ field.label }}

        ds-table-cell(tag="th", :head="true", v-if="scopedSlots.action", appearance="action-head")
          slot(name="action-head")

</template>

<script>

export default {
  name: 'vue-table',
  props: {
    fields: Array,
    aggregateFields: Array,
    items: Array,
    aggregate: {},
    displayHeader: Boolean,
    displayFooter: Boolean,
  },
};

</script>

<template lang="pug">
include /mixins.pug
label(
  v-bem:[blockName].label="{'float': true}"
  :class="[{'control-input__label--active': !$options.isEmpty(value) && value || value === 0}]"
  :for="vid || id",
) {{ labelText }}
  //- pre {{ value}}
  slot
  span(
    v-if="isRequired"
    v-bem.is_required=""
  ) &nbsp;*
  //- {{ !$options.isNil(value) && !$options.isEmpty(value) }}
</template>

<script>
import {
  isEmpty,
  // isNil,
} from 'ramda';

export default {
  isEmpty,
  // isNil,
  name: 'float-label',
  block: 'control-input',
  props: {
    blockName: {
      default: 'control-input',
    },
    labelText: {
      type: String,
    },
    id: {
      type: String,
      required: false,
    },
    vid: {
      type: String,
      default: '',
    },
    value: {},
    isRequired: {
      type: Boolean,
    },
  },
};
</script>

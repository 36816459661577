import { curry } from 'ramda';
import makeTemplateGetter from '@aspectus/resource-template-url-getter';

const loadGoogleMapsApi = require('load-google-maps-api');

export const API_BASE_URL = '/api/v1';
export const prefixAPI = url => API_BASE_URL + url;
export const modelPrefix = (url, model = '') => model + url;

export const LOGOUT_URL = '/auth/logout/';

export const maps = () => loadGoogleMapsApi({
  key: window.CONFIG.GOOGLE_API_KEY,
  libraries: ['places'],
  language: window.language,
});

const filtersGetterBase = makeTemplateGetter('{;filters*}');
const filtersGetter = parameters => filtersGetterBase(parameters).slice(1) || null;
// const partGetter = (name, prefix) => {
//   const base = makeTemplateGetter(`${prefix}{/${name}}`);
//   return parameters => {
//     const result = base(parameters);
//     return result === prefix ? null : result;
//   };
// };
// const pageGetter = partGetter('page', 'page');
const getParametersGetter = makeTemplateGetter('{?limit,offset}');

export const urlGetter = curry((prefix, p) => {
  const result = [
    // categoryGetter(p),
    filtersGetter(p),
    // pageGetter(p),
  ];
  return (`${prefix + result.filter(x => null !== x).join('/') }/`).replace(/\/\//img, '/') + getParametersGetter(p);
});

<template lang="pug">

include /mixins.pug

+b.cabinet-sidebar-menu-element(
  v-state="elementState",
  v-click-outside="outside",
  @click="condensed ? toggle() : null",
  @mouseover="condensed ? open() : null",
  @mouseleave="condensed ? close() : null",
)
  +e.CABINET-SIDEBAR-MENU-LINK.link(
    v-if="to"
    :href="href", :to="to", v-bind="$attrs",
    :disabled="!!(children && children.length)"
    @click='toggle'
    :class="{ 'is-current': currentPath == href }"
  )
    +e.icon(
      v-if="$scopedSlots.icon || icon"
      @click='toggle'
    )
      slot(
        name="icon",
        :icon="icon"
      )
        ds-inliner(size="lg")
          span(:class="`icon icon-${icon}`")
    +e.label(
      v-if="$scopedSlots.label || label"
      @click='toggle'
      @mouseenter="checkTitleTruncated"
    )
      slot(
        name="label",
        :label="label"
      ) {{ label }}
    +e.counter-wrapper(
      v-if="$scopedSlots.counter || counter"
      @click='toggle'
    )
      +e.counter
        slot(
          name="counter",
          :counter="counter"
        ) {{ counter }}

    +e.drop(
      @click='toggle'
      v-if='children && children.length && drop && !condensed'
    )
      slot(
        name='drop',
      ): ds-inliner(size="xs")
        +b.icon.icon-chevron_up(
          :class="{ 'is_rotate_180': !opened }"
        )

    ds-link.ml-7(
      v-if="($scopedSlots.rightIcon || rightIcon) && !condensed"
      tag="router-link",
      :to="rightIcon.to"
    )
      +e.icon.right
        slot(
          name="rightIcon",
          :icon="rightIcon.icon"
        )
          ds-inliner(size="2xl")
            span(:class="`icon icon-${rightIcon.icon}`")
  +e.link(v-else-if="click" v-bind="$attrs" @click="click")
    +e.icon(
      v-if="$scopedSlots.icon || icon"
      @click='toggle'
    )
      slot(
        name="icon",
        :icon="icon"
      )
        ds-inliner(size="lg")
          span(:class="`icon icon-${icon}`")
    +e.label(
      v-if="$scopedSlots.label || label"
      @click='toggle'
    )
      slot(
        name="label",
        :label="label"
      ) {{ label }}

    +e.drop(
      @click='toggle'
      v-if='children && children.length && drop && !condensed'
    )
      slot(
        name='drop',
      ): ds-inliner(size="xs")
        +b.icon.icon-chevron_up(
          :class="{ 'is_rotate_180': !opened }"
        )

    ds-link.ml-7(
      v-if="($scopedSlots.rightIcon || rightIcon) && !condensed"
      tag="router-link",
      :to="rightIcon.to"
    )
      +e.icon.right
        slot(
          name="rightIcon",
          :icon="rightIcon.icon"
        )
          ds-inliner(size="2xl")
            span(:class="`icon icon-${rightIcon.icon}`")
  +e.nested(v-if="children")
    slot(name="nested")
      +e.CABINET-SIDEBAR-MENU-NESTED(
        :items="children",
        :condensed="condensed",
        :level="1",
        nestedComponent="cabinet-sidebar-menu-nested",
      )

</template>

<script>

export default {
  inheritAttrs: false,
  props: [
    'condensed',
    'href',
    'to',
    'click',
    'icon',
    'rightIcon',
    'label',
    'counter',
    'children',
    'drop',
    'parent',
    'disabled',
    'initialState',
  ],
  data() {
    return {
      opened: this.initialState,
    };
  },
  computed: {
    currentPath() {
      return window.location.pathname;
    },
    elementState() {
      return {
        condensed: this.condensed,
        opened: this.opened,
        dropped: this.children && this.children.length && this.drop && !this.condensed,
      };
    },
  },
  watch: {
    condensed: {
      immediate: false,
      handler(val, old) {
        if (val !== old) {
          this.opened = !val;
        }
      },
    },

    $route(to) {
      const { name, meta: { parentRoute = null } } = to;
      this.opened = this.checkNestedActive(parentRoute || name);
    },
  },
  mounted() {
    this.opened = !this.condensed && this.checkNestedActive(this.$router?.currentRoute?.meta?.parentRoute || this.$router?.currentRoute?.name);
  },
  methods: {
    checkNestedActive(currentName) {
      if (this.children && this.children.length) {
        return currentName &&
          this.children.some((
            {
              to: {
                name = '',
                params: { hotel_id: hotelId = null } = {},
              } = {
                name: '',
                params: {},
              },
            }
          ) => name === currentName && hotelId && hotelId === Number(this.$route.params.hotel_id)) ||
          this.parent === this.$router?.currentRoute?.meta?.parentRoute;
      }
      return false;
    },
    outside() {
      if (this.condensed) {
        this.close();
      }
    },
    open() {
      if (true === this.opened) {
        return;
      }

      this.opened = true;
    },
    close() {
      if (false === this.opened) {
        return;
      }

      this.opened = false;
    },
    toggle(event) {
      if (this.children && this.children.length) {
        event.preventDefault();
        event.stopPropagation();
      }
      if (this.opened) {
        this.close();
      } else {
        this.open();
      }
    },
    checkTitleTruncated(event) {
      const el = event.target;
      if (el) {
        const computedStyle = window.getComputedStyle(el);
        const isEllipsis = 'ellipsis' === computedStyle.textOverflow;
        const isOverflowing = el.scrollWidth > el.clientWidth;
        el.title = isEllipsis && isOverflowing ? this.label : '';
      }
    },
  },
};

</script>

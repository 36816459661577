import { route } from '@md/routing';

const View = () => import('./views/View.vue');
const BaseConditions = () => import('./views/BasicConditions/View.vue');
const BonusGroups = () => import('./views/BonusGroups/View.vue');
const ReceivingRules = () => import('./views/ReceivingRules/View.vue');
const ParticipantsHotels = () => import('./views/ParticipantsHotels/View.vue');

const PROFILE_PREFIX = 'dmc:bonus_program:';
const p = name => PROFILE_PREFIX + name;
export const DMC_BONUS_PROGRAMS = p('view');
export const DMC_BASIC_CONDITIONS = p('base_conditions');
export const DMC_BONUS_GROUPS = p('bonus_groups');
export const DMC_RECEIVING_RULES = p('receiving_rules');
export const DMC_PARTICIPANTS_HOTELS = p('participants_hotels');

export function createRoutes() {
  return [
    route('', View, DMC_BONUS_PROGRAMS),
    route('basic-conditions/', BaseConditions, DMC_BASIC_CONDITIONS, {
      meta: {
        parentRoute: DMC_BONUS_PROGRAMS,
      },
    }),
    route('bonus-groups/', BonusGroups, DMC_BONUS_GROUPS, {
      meta: {
        parentRoute: DMC_BONUS_PROGRAMS,
      },
    }),
    route('receiving-rules/', ReceivingRules, DMC_RECEIVING_RULES, {
      meta: {
        parentRoute: DMC_BONUS_PROGRAMS,
      },
    }),
    route('participants-hotels/', ParticipantsHotels, DMC_PARTICIPANTS_HOTELS, {
      meta: {
        parentRoute: DMC_BONUS_PROGRAMS,
      },
    }),
  ];
}

<template lang='pug'>
  component(
    :is="filterController"
    :receive="allProps.receive"
    :receive-available="availableProps && availableProps.receive || null"
    :all="allProps.result && allProps.result.items"
    :available="availableProps && availableProps.result || null"
    :displayed="displayedFilters"
    :pagination='pagination'
    :loading="allProps.loading"
    :need-filters='needFilters'
    :need-sorting='needSorting'
    :need-search='needSearch'
    :value="value"
    :recommendedTags='recommendedTags',
    :label="label"
    @input="handleFilter"
    :sortlist='sortlist'
  )
    template(#content="props")
      slot(name="content" v-bind="props")

</template>

<script>

import ViewMixin from '@md/filter/mixins/ViewMixin';

export default {
  mixins: [ViewMixin],
};

</script>

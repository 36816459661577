import General from './general';
import Controls from './controls';
import Catalog from './catalog';
import FiltersView, { LoadableFiltersView } from './filter';
import Icons from './icons';

function install(Vue) {
  Vue.use(Icons);
  Vue.use(General);
  // Vue.use(Info);
  Vue.use(Controls);
  Vue.use(Catalog);

  Vue.component('ui-filters-view', FiltersView);
  Vue.component('ui-loadable-filters-view', LoadableFiltersView);
}

export default { install };

import { route } from '@md/routing';

const List = () => import('./views/List.vue');

export const TOUROPERATOR_NOTIFICATIONS = 'touroperator:notifications';

export function createRoutes() {
  return [
    route('', List, TOUROPERATOR_NOTIFICATIONS),
    { path: '', redirect: { name: TOUROPERATOR_NOTIFICATIONS } },
  ];
}

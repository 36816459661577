import { route } from '@md/routing';

const View = () => import('./views/View.vue');
const BaseConditions = () => import('./views/BasicConditions/View.vue');
const BonusGroups = () => import('./views/BonusGroups/View.vue');
const ReceivingRules = () => import('./views/ReceivingRules/View.vue');
const ParticipantsHotels = () => import('./views/ParticipantsHotels/View.vue');

const PROFILE_PREFIX = 'touroperator:bonus_program:';
const p = name => PROFILE_PREFIX + name;
export const TOUROPERATOR_BONUS_PROGRAMS = p('view');
export const TOUROPERATOR_BASIC_CONDITIONS = p('base_conditions');
export const TOUROPERATOR_BONUS_GROUPS = p('bonus_groups');
export const TOUROPERATOR_RECEIVING_RULES = p('receiving_rules');
export const TOUROPERATOR_PARTICIPANTS_HOTELS = p('participants_hotels');

export function createRoutes() {
  return [
    route('', View, TOUROPERATOR_BONUS_PROGRAMS),
    route('basic-conditions/', BaseConditions, TOUROPERATOR_BASIC_CONDITIONS, {
      meta: {
        parentRoute: TOUROPERATOR_BONUS_PROGRAMS,
      },
    }),
    route('bonus-groups/', BonusGroups, TOUROPERATOR_BONUS_GROUPS, {
      meta: {
        parentRoute: TOUROPERATOR_BONUS_PROGRAMS,
      },
    }),
    route('receiving-rules/', ReceivingRules, TOUROPERATOR_RECEIVING_RULES, {
      meta: {
        parentRoute: TOUROPERATOR_BONUS_PROGRAMS,
      },
    }),
    route('participants-hotels/', ParticipantsHotels, TOUROPERATOR_PARTICIPANTS_HOTELS),
  ];
}

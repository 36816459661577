import { route } from '@md/routing';

const List = () => import('./views/List.vue');

export const HOTELIER_CHAIN_HOTEL_FAQ_LIST = ':hotelier_chain:hotels:faq:list';

export function createRoutes() {
  return [
    route('list/', List, HOTELIER_CHAIN_HOTEL_FAQ_LIST),
  ];
}

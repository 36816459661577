import {
  route,
  // prefixRoutes,
} from '@md/routing';
// import { createRoutes as createTransactionsRoutes } from './modules/transactions/router.js';
// import { createRoutes as createLoyaltyRoutes } from './modules/loyalty/router.js';
// import { createRoutes as createVideoRoutes } from './modules/video/router.js';
// import { createRoutes as createApplicationsRoutes } from './modules/applications/router.js';

const Create = () => import('./views/Create.vue');
const Edit = () => import('./views/Edit.vue');

const TOUROPERATOR_PREFIX = 'touroperator:';
const p = name => TOUROPERATOR_PREFIX + name;

export const TOUROPERATOR_CREATE = p('create');
export const TOUROPERATOR_EDIT = p('edit');

const prefixTouroperatorId = ':touroperator_id/';

export function createRoutes() {
  return [
    route('create/', Create, TOUROPERATOR_CREATE, {

    }),
    route(`${prefixTouroperatorId}edit/`, Edit, TOUROPERATOR_EDIT, {
      // meta: accessHotelPermission(MANAGER_HOTEL_EDIT, options),

    }),
    // prefixRoutes(`${prefixTouroperatorId}video/`, createVideoRoutes(options)),
    // prefixRoutes(`${prefixTouroperatorId}transactions/`, createTransactionsRoutes(options)),
    // prefixRoutes(`${prefixTouroperatorId}bonus/`, createLoyaltyRoutes(options)),
    // prefixRoutes(`${prefixTouroperatorId}applications/`, createApplicationsRoutes(options)),
    { path: '/edit/', redirect: { name: TOUROPERATOR_CREATE } },
  ];
}

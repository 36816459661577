<template lang="pug">
include /mixins.pug

+b.HEADER.header--with_menu(
  :class="{ 'header--variant_fixed': isFixedHeader }"
)
  //- slot(v-if="!isMounted")
  +e.desktop
    +b.g-container
      ds-panel(:space="['xs', 'md-md']"): ds-panel-element(offset="horizontal")
        +b.g-row--align_center.--justify_between.--appearance_nowrap.--space_lg-lg
          +b.g-cell.g-cols--narrow
            drawer-receiver(
              v-if="!staticHeader"
              v-slot="{ toggle, condensed, condensable, opened }"
            )
              div: +b.BUTTON.burger-wrapper(
                v-if="!condensable",
                type="button",
                :aria-label="_('Toggle sidebar menu')"
                @click.prevent='toggle',
              )
                +b.burger(
                  aria-hidden="true",
                  :class='{"is-active": opened }'
                )
                  +e.item(
                    v-for='i in 3'
                  )
            ui-toggler(
              v-else
              v-slot:default='{ active, toggleActive, collapse }',
              :required-emitting="true"
              @toggle="changeSidebarState"
            )
              click-outside(:do="collapse")
                div
                  +b.BUTTON.burger-wrapper(
                    type="button"
                    :aria-label="_('Toggle sidebar menu')"
                    @click.prevent='toggleActive'
                  )
                    +b.burger(
                      aria-hidden="true",
                      :class='{"is-active": active }'
                    )
                      +e.item(
                        v-for='i in 3'
                      )
                  div(:class='{"blured-content": active}', @click.prevent='toggleActive')
                  transition(name='slidedown')
                    +b.sidebar-menu--rounded_bottom-right-fhd.is-fixed(:class='{"is-active": active}')
                      component(
                        :is="cabinetSidebar"
                        :condensed="false",
                        :condensable="false",
                      )
          +b.g-cell.g-cols--narrow
            +b.ds-panel--space_none.--space_4xl-md.--space_md-xl: +e.element--offset_right
              +b.A.ds-link--appearance_block(
                :href="indexPageUrl",
                :class="{ 'is-blocked': isIndexPage }"
              )
                +b.header-logo-wrapper
                  +e.IMG.logo(
                    v-if="logo && logo.length"
                    width="160",
                    height="25",
                    :src="logo",
                    :alt="_('tooroom')",
                    :title="_('tooroom')"
                  )
                  +e.IMG.logo(
                    v-else
                    width="160",
                    height="25",
                    src="/static/img/logo.svg",
                    :alt="_('tooroom')",
                    :title="_('tooroom')"
                  )
          +b.g-cell--appearance_grow-index_hight.g-cols--auto.u-display--hidden.--block-xl
            +b.NAV.header__destop-navigation
              +b.UL.header-navigation(
                v-if="headerMenu && headerMenu.length"
              )
                +e.LI.item(
                  v-for="item in headerMenu"
                )
                  +e.A.link.ds-link(
                    :href="item.url"
                    :class="{ 'is-current': currentPath == item.url }"
                    :target="item.target"
                  ) {{ item.title }}
          +b.g-cell.g-cols--narrow.u-display--hidden.--block-xl
            ui-toggler(
              v-slot='{ active, toggleActive, collapse }'
            )
              +b.tr-dropdown(:class="{'is-active': active}")
                click-outside(:do='collapse')
                  div
                    +e.BUTTON.toggler.ds-link--styling_usual(@click.prevent="toggleActive" type="button")
                      template(
                        v-for="(value, key) in languages"
                      )
                        template(
                          v-if="key === language"
                        )
                          +b.SPAN.g-row--space_xs.--align_center
                            +b.g-cell.g-cols--narrow: +b.SPAN.ds-caption--size_xs.--color-2.--variant_strong {{ value }}
                            +b.g-cell.g-cols--narrow: +b.tr-dropdown__icon.ds-inliner--size_md
                              +b.ds-inliner__body
                                +b.icon.icon-chevron_up

                    transition(name='slidedown' v-cloak)
                      +e.options(v-show="active")
                        template(
                          v-for="(value, key) in languages"
                        )
                          template(
                            v-if="key !== language"
                          )
                            +e.BUTTON.option.ds-link--styling_usual(
                              @click.prevent="changeLanguage(key)"
                              :title="key"
                            )
                              +b.ds-caption--size_xs.--variant_strong.ds-link {{ value }}
          //- +b.g-cell.g-cols--narrow.u-display--hidden.--block-fhd
          //- +b.g-cell.g-cols--narrow.u-display--hidden.--block-fhd
          +b.g-cell.g-cols--narrow.u-display--hidden.--block-xl
            //- +b.ds-panel--space_2xl: +e.element--offset_right(
            //-   v-if="socials && socials.length"
            //- )
              +b.g-row--space_sm
                +b.g-cell.g-cols(
                  v-for="social in socials"
                )
                  +b.A.round-button(
                    :href="social.url",
                    :title="social.title",
                    target="_blank",
                    rel="noopener noreferrer",
                  )
                    +e.icon.IMG(
                      width="15",
                      height="15",
                      :alt="social.title",
                      :src="social.header_icon"
                    )
          +b.g-cell.g-cols--narrow
            cabinet-user-bar(v-cloak)

</template>

<script>
import { changeLanguage } from '@utils/helpers.js';

import {
  CURRENT_LANGUAGE,
  prefixLanguage,
} from '@md/urls';
// import { LOGOUT_URL } from '@md/api.js';
import {
  AGENT_TYPE, HOTELIER_TYPE, HOTELIER_CHAIN_TYPE, MODERATOR_TYPE, SUPERUSER_TYPE, TOUROPERATOR_MANAGER_TYPE, REPRESENTATIVE_TYPE, DMC_TYPE,
} from '@md/users/accessTokens.js';

const CabinetUserBar = () => import('../UserBar');

export default {
  AGENT_TYPE,
  HOTELIER_TYPE,
  HOTELIER_CHAIN_TYPE,
  DMC_TYPE,
  MODERATOR_TYPE,
  SUPERUSER_TYPE,
  TOUROPERATOR_MANAGER_TYPE,
  components: {
    CabinetUserBar,
  },
  props: {
    staticHeader: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      pagesWithFixedHeader: [prefixLanguage('/')],
      currentPath: window.location.pathname,
      windowWidth: window.innerWidth,
      isMounted: false,
      indexPageUrl: window.INDEX_PAGE_URL,
      logo: window.LOGO || '',
      mobileMenu: window.MOBILE_MENU,
      headerMenu: window.HEADER_MENU,
      socials: window.SOCIALS,
      language: CURRENT_LANGUAGE,
      languages: window.activeLanguages || [],
      userType: window.USER_TYPE,
    };
  },
  computed: {
    isFixedHeader() {
      return this.pagesWithFixedHeader.includes(window.location.pathname);
    },
    isStaff() {
      return MODERATOR_TYPE === this.userType || SUPERUSER_TYPE === this.userType;
    },
    isIndexPage() {
      return this.indexPageUrl === prefixLanguage(window.location.href, { language: CURRENT_LANGUAGE });
    },
    cabinetSidebar() {
      const SIDEBAR_BY_USER_TYPE_MAP = {
        [HOTELIER_TYPE]: () => import('../layouts/HotelierSidebar'),
        [HOTELIER_CHAIN_TYPE]: () => import('../layouts/HotelierChainSidebar'),
        [DMC_TYPE]: () => import('../layouts/DMCSidebar.vue'),
        [AGENT_TYPE]: () => import('../layouts/AgentSidebar'),
        [REPRESENTATIVE_TYPE]: () => import('../layouts/RepresentativeSidebar'),
        [TOUROPERATOR_MANAGER_TYPE]: () => import('../layouts/TouroperatorSidebar.vue'),
        [MODERATOR_TYPE]: () => import('../layouts/StaffSidebar'),
        [SUPERUSER_TYPE]: () => import('../layouts/AdminSidebar'),
      };
      return SIDEBAR_BY_USER_TYPE_MAP[this.userType] || SIDEBAR_BY_USER_TYPE_MAP[SUPERUSER_TYPE];
    },
  },
  mounted() {
    this.$emit('header-mounted');
    this.$nextTick(() => {
      setTimeout(() => {
        this.isMounted = true;
      }, 0);
    });
    this.$body.classList.remove('is-blocked');
  },

  methods: {
    changeLanguage,
    changeSidebarState(isOpen) {
      if (isOpen) {
        this.$body.classList.add('is-blocked');
      } else {
        this.$body.classList.remove('is-blocked');
      }
    },
  },
};

</script>

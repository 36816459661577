<template lang="pug">
</template>
<script>
import { blog } from '@md/blog/api.js';
import { modalOpener } from '@utils/helpers.js';

const Detail = () => import('@md/blog/components/Detail.vue');

export default {
  name: 'modal-listener-open-detail-view',
  props: {
    baseUrl: String,
  },
  data() {
    return {

    };
  },

  mounted() {
    const path = window.location.pathname.split('/');
    const pathArray = path.filter(el => el);
    const postLabel = pathArray.find((el, index, arr) => 'blog' === arr[index - 2] && 'post' === arr[index - 1]);
    if (postLabel) {
      this.getPost(postLabel);
    }
  },
  methods: {
    modalOpener,
    getPost(label) {
      return blog.retrieve.execute({ label })
        .then(({ data: { item } }) => {
          this.modalOpener({
            props: {
              isComponent: Detail,
              initial: item,
              baseUrl: this.baseUrl,
            },
            config: {
              width: 880,
              focusTrap: false,
            },
          });
        }).catch(() => {});
    },
  },
};
</script>

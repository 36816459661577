import { route } from '@md/routing';

const FaqDisplay = () => import('@md/faq/components/Display.vue');

export const TOUROPERATOR_MANAGER_FAQ = 'touroperator-manager:faq';

export function createRoutes() {
  return [
    route('', FaqDisplay, TOUROPERATOR_MANAGER_FAQ),
  ];
}

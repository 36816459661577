<template lang="pug">
  include /mixins
  +b.ds-section__element(v-if="isAuthenticated || featureList.length > 0")
    +b.ds-card--styling_default
      +b.ds-panel
        +b.anchor-target-dummy#features
        +b.H2.ds-caption--size_lg.--appearance_uppercase.--variant_strong {{ _("Features") }}
        div(class="t-content")
          ul
            li(v-for="feature in featureList" v-bind:key="feature.id")
              ds-caption {{ feature.description }}

        +b.g-row--justify_end.--align_center.--space_md
          +b.A.ds-caption--bold.ds-link--styling_brand-2(
            type="button",
            @click="openModal",
            v-if="isAuthenticated",
          )
            +b.SPAN.g-row--appearance_nowrap.--justify_end.--space_sm.--align_center
              +b.SPAN.g-cell.g-cols
                +b.SPAN.ds-caption--size_xs {{ _("+Add interesting information about the hotel") }}

</template>
<script>
import { modalOpener } from '@utils/helpers.js';
import LoadingStateMixin from '@aspectus/vue-loading-state-mixin';
import { features } from '@md/features/api';

const CreateFeatureModal = () => import('./CreateFeatureModal.vue');

export default {
  mixins: [LoadingStateMixin],
  props: ['hotelId'],
  data() {
    return {
      featureList: [],
      isAuthenticated: window.IS_AUTHENT,
    };
  },
  methods: {
    modalOpener,
    openModal() {
      this.modalOpener({
        props: {
          isComponent: CreateFeatureModal,
          hotelId: this.hotelId,
        },
        reaction: this.onAdded,
      });
    },
    onAdded() {
      this.getFeatures();
    },
    async getFeatures() {
      const { data: { items = [] } } = (await features.list.execute({ id: this.hotelId }));
      this.featureList = items || [];
    },
  },
  async mounted() {
    await this.getFeatures();
  },
};
</script>

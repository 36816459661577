import { prefixRoutes } from '@md/routing.js';

import { createRoutes as createAgenciesRoutes } from './modules/agencies/router.js';
import { createRoutes as createChatsRoutes } from './modules/chats/router.js';
import { createRoutes as createTransactionsRoutes } from './modules/transactions/router.js';
import { createRoutes as createNotificationsRoutes } from './modules/notifications/router.js';
import { createRoutes as createHoteliersRoutes } from './modules/hoteliers/router.js';
import { createRoutes as createRoomsRoutes } from './modules/rooms/router.js';
import { createRoutes as createHotelsRoutes } from './modules/hotels/router.js';
import { createRoutes as createQuestionsAnswersRoutes } from './modules/questions-answers/router.js';

import { ADMIN_CHATS } from './routes.js';

const Route404 = () => import('@sections/404.vue');

export function createRoutes(options) {
  return [
    prefixRoutes('agencies/', createAgenciesRoutes(options)),
    prefixRoutes('chats/', createChatsRoutes(options)),
    prefixRoutes('transactions/', createTransactionsRoutes(options)),
    prefixRoutes('notifications/', createNotificationsRoutes(options)),
    prefixRoutes('hoteliers/', createHoteliersRoutes(options)),
    prefixRoutes('rooms/', createRoomsRoutes(options)),
    prefixRoutes('hotels/', createHotelsRoutes(options)),
    prefixRoutes('questions-answers/', createQuestionsAnswersRoutes(options)),
    { path: '', redirect: { name: ADMIN_CHATS } },

    { path: '*', component: Route404 },
  ];
}

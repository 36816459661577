import { withDefaultProps } from '@aspectus/vue-utils';

import BaseView from '@md/filtersList/View.vue';

import { video } from '@md/video/api.js';
import { hotels } from '@md/hotels/api.js';
import { touroperatorsList } from '@md/touroperators/api.js';
import { agents } from '@md/agents/api.js';
import { blog, ajaxBlogList } from '@md/blog/api.js';
// const CatalogView = {
//   name: 'CatalogViewTest',
//   productsResource: productsListUrl,
//

const composer = (
  listUrl,
  filtersUrl,
  availableFiltersUrl = null,
  needFilters = true,
  needSorting = false,
  needSearch = false,
  hasRecommendedTags = false,
  scrollPaginate = false,
  filterController
) => withDefaultProps({
  productsResource: () => listUrl,
  attributesResource: () => filtersUrl,
  availableAttributesResource: () => availableFiltersUrl,
  needFilters,
  needSorting,
  needSearch,
  hasRecommendedTags,
  scrollPaginate,
  filterController, // use component name
})(BaseView);

const HotelsCatalog = composer(
  hotels.hotelsAjax,
  hotels.catalogFilters.list,
  hotels.catalogFilters.available,
  true,
  true,
  true,
  false,
  false,
  'filter-controller'
);
const TouroperatorsCatalog = composer(
  touroperatorsList,
  null,
  null,
  false,
  false,
  false,
  false,
  false,
  'filter-controller'
);
const VideoCatalog = composer(
  video.ajax,
  video.catalogFilters.list,
  video.catalogFilters.available,
  true,
  true,
  false,
  true,
  true,
  'catalog-video-controller'
);
const AgentsCatalog = composer(
  agents.ajax,
  null,
  null,
  false,
  false,
  false,
  false,
  false
);
const BlogCatalog = composer(
  ajaxBlogList,
  blog.filters.public.list,
  blog.filters.public.available,
  true,
  false,
  false,
  false,
  false,
  'filter-controller'
);

export default function plugin(Vue, { postfix = '-catalog' } = { }) {
  // Vue.component(CatalogHOC.name, CatalogHOC)
  Vue.component(`hotels${postfix}`, HotelsCatalog);
  Vue.component(`touroperators${postfix}`, TouroperatorsCatalog);
  Vue.component(`video${postfix}`, VideoCatalog);
  Vue.component(`agents${postfix}`, AgentsCatalog);
  Vue.component(`blog${postfix}`, BlogCatalog);
}

export const MANAGER_HOTEL_EDIT = '141011';
export const MANAGER_LOYALTY_LOYALTY_PROGRAM_EDIT = '131011';
export const MANAGER_SALES_SALE_ADD = '151010';
export const MANAGER_BIDS_APPLICATIONS_ACCESS = '101012';
export const MANAGER_BIDS_BOOKING_ACCESS = '101112';
export const MANAGER_BIDS_REVIEWS_ACCEPT = '161012';
export const MANAGER_BLOG_POST_CREATE = '111010';

export default {
  MANAGER_HOTEL_EDIT,
  MANAGER_LOYALTY_LOYALTY_PROGRAM_EDIT,
  MANAGER_SALES_SALE_ADD,
  MANAGER_BIDS_APPLICATIONS_ACCESS,
  MANAGER_BIDS_BOOKING_ACCESS,
  MANAGER_BIDS_REVIEWS_ACCEPT,
  MANAGER_BLOG_POST_CREATE,
};

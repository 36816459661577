import { defaultDataTransformer } from '@aspectus/resource-commons';
import { createReceiver } from '@md/resources';
import { prefixAPI } from '@md/api.js';

const r = (tpl, f = createReceiver) => f(prefixAPI(tpl)).transform(defaultDataTransformer);

export const locations = {
  countries: r('/locations/countries/list/{?s,available_by_hotels,available_by_regions,filter_reason,touroperator,with_currency}'),
  regions: r('/locations/regions/list/{?s,country,filter_reason,available_by_hotels,limit}'),
  cities: r('/locations/cities/list/{?s,country,filter_reason,available_by_hotels,limit}'),
};

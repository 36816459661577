import { GeneratorMixin } from '@aspectus/vue-filters-generator';

// import TagCloudWidget from '@md/filter/Widgets/TagCloudWidget';
// import RangeWidget from '@md/filter/Widgets/RangeWidget';
// import CheckboxWidget from '@md/filter/Widgets/CheckboxWidget';

const MultiselectWidget = () => import('@md/ui/filter/widgets/MultiselectWidget');
const DatePickerWidget = () => import('@md/ui/filter/widgets/DatePickerWidget');
const SelectWidget = () => import('@md/ui/filter/widgets/SelectWidget');
const CheckboxWidget = () => import('./widgets/CheckboxWidget');
const SearchWidget = () => import('./widgets/SearchWidget');

const WIDGETS_MAP = {
  search: SearchWidget,
  // range: RangeWidget,
  'date-range': DatePickerWidget,
  select: SelectWidget,
  checkbox: CheckboxWidget,
  multiselect: MultiselectWidget,
  // tag_cloud: TagCloudWidget,
};

export default {
  extends: GeneratorMixin,
  widgetsMap: WIDGETS_MAP,
};

<script>
import { modalOpener } from '@utils/helpers.js';

export default {
  name: 'ModalTrigger',
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
    name: {
      type: String,
    },
    classes: {
      // type: Array,
      default: () => [],
    },
    title: String,
    subtitle: String,
    // path to component
    path: {
      type: String,
      default: () => null,
    },
  },
  methods: {
    modalOpener,
    openPopup() {
      this.modalOpener({
        props: {
          isComponent: () => import(`@md/${this.path}`),
          title: this.title,
          subtitle: this.subtitle,
          ...this.$attrs,
        },
        config: {
          name: this.name,
          ...this.config,
        },
        classes: this.classes,
      });
    },
  },
  computed: {
    componentLoader() {
      return () => import(`@md/${this.path}`);
    },
  },
  render() {
    return this.$scopedSlots.default({
      open: this.openPopup,
    });
  },
};
</script>

<template lang="pug">
include /mixins
div
  controller.relative(
    :localsearch="search"
    :prefix-api-search="prefixApiSearch"
  )
    template(
      #inputview="{ showResuls, hideResults, keydownEvent, keyupEvent, goToSearch, clearSearch }"
    )
      row.search-stack(
        :space="['none']",
        align="center"
      )
        +b.CELL.u-display--hidden.--block-sm(cols="narrow")
          +b.icon.icon-search
        cell(cols="auto")
          +e.D-OLD-INPUT(
            :size="['xl']"
            :placeholder="placeholder"
            vid="search",
            @keyup="keyupEvent",
            v-model="search"
            @focus="showResuls",
          )
        +b.CELL.icon-clear(
          cols="narrow",
          v-if="search.length",
          @click.prevent="clearSearch",
        )
          +b.ds-inliner--size_xs.--size_lg-sm
            +e.body.icon.icon-close
        cell(cols="narrow")
          +e.CONTROL-OLD-BUTTON(
            :size="['lg']"
            styling="brand-2",
            appearance="round"
            variant="round"
            type="submit"
            @click.prevent="goToSearch(search)"
          )
            +b.ds-inliner--size_xs.u-display--hidden-sm
              +e.body.icon.icon-search
            +b.DS-PANEL.u-display--hidden.--block-sm(
              :space="['xs', 'md-sm', '4xl-lg']"
            ): ds-panel-element(offset="horizontal")
              | {{ _("Поиск") }}

</template>
<script>
import { mapState } from 'pinia';
import { useSearchStore } from '@md/Search/store.js';

const Controller = () => import('./Controller.vue');

export default {
  name: 'search-view',
  props: {
    prefixApiSearch: String,
    initPlaceholder: String,
  },
  components: {
    Controller,
  },

  data() {
    return {
      searchStore: useSearchStore(),
      search: '',
      placeholder: this.initPlaceholder || this._('Search by hotel name'),
    };
  },
  computed: {
    ...mapState(useSearchStore, [
      'global_search',
    ]),
  },
  watch: {
    global_search(nval) {
      this.search = nval;
    },
  },

  mounted() {
    this.searchStore.$onAction(({ name, store, after }) => {
      after(() => {
        if ('setSearch' === name) {
          this.search = store.global_search;
        }
      });
    });
  },
};
</script>

import { renderSlim } from '@aspectus/vue-utils';
import { PaginationControllerMixin } from '@md/ui/catalog/mixins.js';

export default {
  name: 'pagination-controller',
  mixins: [PaginationControllerMixin],

  props: {
    receiver: {
      type: Function,
      required: true,
    },
    resultPagination: {},
  },

  watch: {
    resultPagination: { immediate: true, handler: 'handlePaginationResult' },
  },

  methods: {
    parametersUpdate(parameters) {
      const shift = 1;
      const page = Math
        .floor(parameters.offset / parameters.limit) + shift;
      parameters.page = {
        page: page || shift,
      };
      this.$emit('update:parameters', parameters);
      this.receiver(parameters);
    },
  },

  render(h) {
    return renderSlim(this.$scopedSlots.default({
      filters: this.filters,
      changeFilters: this.changeFilters,
      pagination: this.pagination,
      changePagination: this.changePagination,
    }), h, 'tag');
  },
};

import { mergeContext } from '@aspectus/vue-utils';
import { generateModifierClassNames } from '@aspectus/bem';

const b = {
  default: 'control-old-select',
};

export const normalizeSelectValue = value => (
  Array.isArray(value) || 'undefined' === typeof value || null === value
    ? (value || [])
    : [value]
);

export function getLabel(option, key, customGetter) {
  if (!option) return '';

  if (Object(option) === option) {
    if (customGetter) {
      return customGetter(option);
    }

    return option[key];
  }

  return option;
}

export function multipleLabel(h, context, props) {
  return h(
    'span',
    {
      attrs: {
        class: 'multiselect__single',
      },
    },
    [
      props.values && props.values.length && props.values.map(
        option => getLabel(
          option, context.props.label, context.data.attrs.customLabel
        )
      ).join(', ') || '',
    ]
  );
}

const emptyPlaceholder = {};

export default {
  name: 'control-select',
  functional: true,

  props: {
    blockName: b,
    size: { default: 'md' },
    styling: { default: 'default' },
    variant: { default: 'select' },
    'show-selected': { type: Boolean, default: false },
    multiple: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    preselectFirst: { type: Boolean, default: false },
    label: {},
    'float-label': {},
    value: {},
    required: {},
    placeholder: { default: '' },
    noOptions: { default: () => emptyPlaceholder },
    noResult: { default: () => emptyPlaceholder },
    maxElements: { default: () => emptyPlaceholder },
    selectLabel: { default: '' },
    selectedLabel: { default: '' },
    deselectLabel: { default: '' },
    tagPlaceholder: { default: '' },
    closeOnSelect: { },
    counter: { type: Boolean, default: false },
  },

  render(h, context) {
    const {
      blockName,
      size,
      styling: style,
      variant, showSelected,
      multiple, disabled, readonly, preselectFirst,
      label, floatLabel, value, required,
      noOptions,
      noResult,
      tagPlaceholder,
      maxElements,
      selectLabel, selectedLabel, deselectLabel,
      closeOnSelect, counter,
    } = context.props;
    const { class: cls, staticClass, ...data } = context.data;

    return h(
      'tag',
      {
        class: [cls, blockName, generateModifierClassNames(blockName, { show_selected: showSelected, multiple, style, size, variant }, '--', '_'), { 'is-readonly': readonly, 'is-disabled': disabled }],
        staticClass,
      },
      [
        h('tag', { class: [`${blockName}__body`] }, [

          context.data.scopedSlots && context.data.scopedSlots.prepend ?
            h(
              'div',
              {
                class: [`${blockName}__prepend`],
              },
              [context.data.scopedSlots.prepend({ value })]
            )
            : null,
          counter && multiple && value && value.length && 1 < value.length
            ? h('tag', { class: [`${blockName}__counter`] }, [value.length])
            : null,

          h(
            'vue-multiselect',
            mergeContext(data, {
              class: [`${blockName}__element`],
              attrs: {
                label,
                value,
                selectLabel,
                selectedLabel,
                deselectLabel,
                tagPlaceholder,
                placeholder: context.parent._('Start typing for search'),
                multiple,
                preselectFirst,
                disabled: readonly || disabled,
                'close-on-select': undefined === closeOnSelect ? !multiple : closeOnSelect,
              },
              on: {
                open: e => data.on && data.on.focus && data.on.focus(e),
                close: e => data.on && data.on.blur && data.on.blur(e),
              },
              scopedSlots: {
              // selection: props => (
              //   props.values && props.values.length
              //   // && !props.isOpen
              //     ? multipleLabel(h, context, props)
              //     : null
              // ),
                ...context.data.scopedSlots,
                noOptions: context.data.scopedSlots && context.data.scopedSlots.noOptions ||
                  (() => (noOptions === emptyPlaceholder ? context.parent._('List is empty.') : noOptions)),
                noResult: context.data.scopedSlots && context.data.scopedSlots.noResult || (() => (noResult === emptyPlaceholder ? context.parent._('Список пуст') : noResult)),
                maxElements: context.data.scopedSlots && context.data.scopedSlots.maxElements || (() => (maxElements === emptyPlaceholder ? context.parent._('Список пуст') : maxElements)),
              },
            })
          ),
          floatLabel ?
            h(
              'float-label',
              {
                class: [`${blockName}__label`],
                attrs: {
                  'label-text': floatLabel,
                  'is-required': required,
                  value,
                },
              }
            )
            : null,
        ]),

      ]
    );
  },
};

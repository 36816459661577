<template functional lang="pug">

include /mixins.pug

+b.TAG(
  v-bem:[props.blockName]="{ styling: props.styling, size: props.size, kind: props.kind, variant: props.variant }"
  v-state="{ disabled: props.disabled, readonly: props.readonly, invalid: props.invalid, required: props.required }"
  :class="[data.staticClass, data.class]"
  :tag="props.tag"
)
  div(v-bem:[props.blockName].body)
    slot(name="prepend")
    +e.TAG(
      v-bem:[props.blockName].element="{ kind: 'input', primary: true }"
      :enable-vue-tag-props-fix="true",
      :readonly="props.readonly",
      :disabled="props.disabled",
      :tag="props.innerElement",
      :id="data.attrs.vid || data.attrs.id"
      v-bind="data.attrs",
      v-on="data.on",
      v-if="!props.mask"
    )
    +e.THE-MASK(
      v-else
      v-bem:[props.blockName].element="{ kind: 'input', primary: true }"
      :enable-vue-tag-props-fix="true",
      :readonly="props.readonly",
      :disabled="props.disabled",
      :tag="props.innerElement",
      :mask="props.mask",
      :masked="props.masked",
      :id="data.attrs.vid || data.attrs.id"
      v-bind="data.attrs",
      v-on="data.on",
      @focus.native="data.on.focus"
      @blur.native="data.on.blur"
    )
    slot(name="append")
    div(
      v-bem:[props.blockName].element="{ kind: 'icon', append: true }",
      v-if="props.icon || scopedSlots.icon"
    )
      slot(name="icon")
        icon(:name="props.icon")
  //- pre {{ data.attrs }}
  float-label(
    v-if="props.floatLabel"
    :label-text="props.floatLabel",
    :is-required="props.required"
    v-bind="data.attrs",
    :vid="data.attrs.vid"
    v-on="data.on",
  )

</template>

<script>

export default {
  name: 'control-input-element',
  props: {
    blockName: String,
    innerElement: {},

    kind: {},
    variant: {},
    size: { default: 'md' },
    styling: { default: 'default' },
    disabled: Boolean,
    readonly: Boolean,
    invalid: Boolean,
    required: Boolean,
    mask: [String, Array],
    masked: { default: true },
    tag: { default: 'div' },
    floatLabel: undefined,
    icon: {},
  },
};

</script>

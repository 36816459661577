import { route } from '@md/routing';

const View = () => import('./View');

export const AGENT_APPEALS = 'agent:appeals';

export function createRoutes() {
  return [
    route('', View, AGENT_APPEALS, {
    }),
    { path: '', redirect: { name: AGENT_APPEALS } },
  ];
}

import storage from '@aspectus/json-local-storage';

const key = (prefix, name) => `${prefix}:${name}`;

export const LocalStorageControllerMixin = {
  props: {
    localStorageKey: { default: 'local-storage-drawer-controller' },
    condenseChecker: { default: false },
    condensable: { type: Boolean },
  },

  data() {
    return {
      willCondense: false,
      isOpened: false,
      isCondensed: storage.get(key(this.localStorageKey, 'condensed')) || false,
    };
  },

  created() {
    this.setCurrentCondenseAbility(
      this.getCurrentCondenseAbility(window.innerWidth)
    );
  },

  mounted() {
    window.addEventListener('resize', this.eventHandler);
  },

  methods: {
    eventHandler(e) {
      this.sizeChanged(e.target.innerWidth);
    },

    getCurrentCondenseAbility(size) {
      if (
        this.condensable &&
        this.condenseChecker
      ) {
        return this.condenseChecker(size);
      }

      return this.willCondense || false;
    },

    setCurrentCondenseAbility(value) {
      this.isOpened = value;
      this.willCondense = value;
    },

    sizeChanged(size) {
      const willCondense = this.getCurrentCondenseAbility(size);

      if (willCondense !== this.willCondense) {
        this.setCurrentCondenseAbility(willCondense);
      }
    },

    setCondensed(value, store = false) {
      this.isCondensed = value;

      if (store) {
        storage.set(key(this.localStorageKey, 'condensed'), value);
      }
    },

    updateOpened(value) {
      this.$emit('update:opened', value);
      this.isOpened = value;
    },

    updateCondensed(value) {
      this.setCondensed(value, true);
    },
  },
};

export const withLocalStorageController = Component => ({
  mixins: [LocalStorageControllerMixin],
  inheritAttrs: false,
  render(h) {
    return h(Component, {
      attrs: {
        ...this.$attrs,
        opened: this.isOpened,
        condensed: this.isCondensed,
        condensable: this.willCondense,
      },
      on: {
        ...this.$listeners,
        'update:opened': this.updateOpened,
        'update:condensed': this.updateCondensed,
      },
      scopedSlots: this.$scopedSlots,
    });
  },
});

import { defaultDataTransformer } from '@aspectus/resource-commons';
import { createReceiver, createSender } from '@md/resources';
import { prefixAPI } from '@md/api.js';

const r = (tpl, f = createReceiver) => f(prefixAPI(tpl)).transform(defaultDataTransformer);

export const faq = {
  portal: {
    create: r('/questions/create/', createSender),
    list: r('/faq/retrieve/{?search}'),
    howToRegister: r('/faq/how-register-application/retrieve/'),
  },
  api: {
    list: r('/faq/list/{?filters,order_by,limit,offset}'),
    create: r('/faq/create/', createSender),
    update: r('/faq{/id}/update/', createSender).config('method', 'PATCH'),
    delete: r('/faq{/id}/delete/', createSender).config('method', 'DELETE'),
    selectOptions: r('/faq/select-options'),
  },
  filters: {
    list: r('/faq/filters/list/'),
    available: r('/faq/filters/list/{?filters}'),
  },
  list: r('/hotel-faq/list/{?filters}'),
  create: r('/hotel-faq/create/', createSender),
  update: r('/hotel-faq{/id}/update/', createSender).config('method', 'PATCH'),
  retrieve: r('/hotel-faq{/id}/retrieve/'),
  delete: r('/hotel-faq{/id}/delete/', createSender).config('method', 'DELETE'),
  orderChange: r('/hotel-faq/order/change/', createSender),
};

import { route } from '@md/routing.js';

const List = () => import('./views/List');

export const HOTELIER_SERVICES_LIST = 'hotelier:hotels:services:list';

export function createRoutes() {
  return [
    route('list/', List, HOTELIER_SERVICES_LIST, {}),
  ];
}

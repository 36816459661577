import { route } from '@md/routing';

const View = () => import('./View');

export const AGENT_CHATS = 'agent:chats';

export function createRoutes() {
  return [
    route('', View, AGENT_CHATS),
  ];
}

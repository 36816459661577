import MessagingInjectorMixin from './MessagingInjectorMixin';

export default {
  mixins: [MessagingInjectorMixin],

  data() {
    return {
      selected: null,
    };
  },

  watch: {
    'service.chats': function (val) {
      if (0 < Object.keys(val).length) {
        this.selected = this.service.chats[Object.keys(val)[0]];
      }
    },
  },

  computed: {
    chat() {
      return this.selected && this.service.chats[this.selected.id];
    },
  },
};

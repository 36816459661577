import { prefixRoutes, route } from '@md/routing';

import { createRoutes as createTransactionsRoutes } from '@sections/cabinet/modules/touroperator_manager/modules/transactions/router';
import { createRoutes as createBookingRoutes } from '@sections/cabinet/modules/touroperator_manager/modules/booking/router';
import { createRoutes as createHotelsRoutes } from '@sections/cabinet/modules/touroperator_manager/modules/hotels/router';
import { createRoutes as createBonusProgramRoutes } from '@sections/cabinet/modules/touroperator_manager/modules/bonus-programs/router';
import { createRoutes as createNotificationsRoutes } from './modules/notifications/router.js';
import { createRoutes as createProfileRoutes } from './modules/profile/router.js';
import { createRoutes as createFaqRoutes } from './modules/faq/router.js';
import { createRoutes as createAppealsRoutes } from './modules/appeals/router.js';
import { createRoutes as createChatsRoutes } from './modules/chats/router.js';
import { createRoutes as createTouroperatorRoutes } from './modules/touroperator/router.js';
import { createRoutes as createReviewsRoutes } from './modules/reviews/router.js';
import { createRoutes as createApplicationsRoutes } from './modules/applications/router.js';

export const TOUROPERATOR_HOME = 'touroperator:home';

const Home = () => import('./modules/home/views/View.vue');
const Route404 = () => import('@sections/404.vue');

export function createRoutes(options) {
  return [
    route('home/', Home, TOUROPERATOR_HOME),
    prefixRoutes('profile/', createProfileRoutes(options)),

    ...createTouroperatorRoutes(options),
    prefixRoutes('bonus-program/', createBonusProgramRoutes(options)),
    prefixRoutes('hotels/', createHotelsRoutes(options)),
    prefixRoutes('notifications/', createNotificationsRoutes(options), {}),
    prefixRoutes('reviews/', createReviewsRoutes(options), {}),
    prefixRoutes('chats/', createChatsRoutes(options)),
    prefixRoutes('faq/', createFaqRoutes(options)),
    prefixRoutes('appeals/', createAppealsRoutes(options)),
    prefixRoutes('applications/', createApplicationsRoutes(options)),
    prefixRoutes('transactions/', createTransactionsRoutes(options)),
    prefixRoutes('booking/', createBookingRoutes(options), {}),
    { path: '', redirect: { name: TOUROPERATOR_HOME } },
    { path: '*', component: Route404 },
  ];
}

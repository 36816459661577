export const MANAGER_STATUSES = {
  pending: '0001000-pending',
  active: '0002000-accepted',
  rejected: '0003000-rejected',
};

export const HotelStatuses = {
  NOT_PUBLISHED: '0001000-not_published',
  PENDING: '0002000-pending',
  PUBLISHED: '0003000-published',
  DELETED: '0004000-deleted',
};

export const RoomStatus = {
  NOT_PUBLISHED: '0001000-not_published',
  PENDING: '0002000-pending',
  PUBLISHED: '0003000-published',
  DELETED: '0004000-deleted',
};

export const ServiceStatus = {
  NOT_PUBLISHED: '0001000-not_published',
  // PENDING: '0002000-pending',
  PUBLISHED: '0003000-published',
  DELETED: '0004000-deleted',
};

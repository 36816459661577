/* eslint-disable */
import Audio from './components/Audio/Audio';
import List from './components/List/List';
import Message from './components/Message/Message';
import MessagesGroup from './components/MessagesGroup/MessagesGroup';
import Preview from './components/Preview/Preview';
import Conversation from './components/Conversation/Conversation';
import Room from './components/Room/Room';
import Provider from './components/Provider/Provider';
import ProgressBar from './components/ProgressBar/ProgressBar';

import Set from './components/Dialog/Set/Set';
import Window from './components/Dialog/Window/Window';

import File from './components/Message/types/File/File';
import Text from './components/Message/types/Text/Text.vue';

import feedTypingHandler from './utils/hocs/feedTypingHandler';
import feedChatListHandler from './components/List/hocs/feedChatListHandler';
import fileUploaderWrapper from './components/Room/hocs/fileUploaderWrapper';
import emojiPickerWrapper from './components/Room/hocs/emojiPickerWrapper';

import EmojiPickerWrapper from './components/Room/wrappers/EmojiPickerWrapper';
import FileUploaderWrapper from './components/Room/wrappers/FileUploaderWrapper';

import { DataFeedInterface } from './DataFeed/Interface';
import MessagingService from './services/Messaging';

import FeedEventsHandlerMixin from './utils/mixins/FeedEventsHandlerMixin';
import FeedTypingHandler from './utils/mixins/FeedTypingHandler';
import LauncherMixin from './utils/mixins/LauncherMixin';
import MessagesReceiverMixin from './utils/mixins/MessagesReceiverMixin';
import MessagingInjectorMixin from './utils/mixins/MessagingInjectorMixin';
import TyperEventsHandlerMixin from './utils/mixins/TyperEventsHandlerMixin';

const DEFAULT_COMPONENT_PREFIX = 'Wds';

// Exporting Components
export {
  Provider, Room, Conversation, Preview, MessagesGroup, Message, List, Audio,
};

// Exporting internal Components
export {
  File, Text,
};

// Exporting HOCs
export {
  feedTypingHandler, feedChatListHandler, fileUploaderWrapper, emojiPickerWrapper,
};

// Exporting Wrappers
export {
  FileUploaderWrapper, EmojiPickerWrapper,
};

// Exporting utility classes/services
export {
  DataFeedInterface, MessagingService,
};

// Exporting mixins
export {
  FeedEventsHandlerMixin, FeedTypingHandler, LauncherMixin,
  MessagesReceiverMixin, MessagingInjectorMixin, TyperEventsHandlerMixin,
};

export default function install(Vue, {
  componentsPrefix = DEFAULT_COMPONENT_PREFIX,
} = {}) {
  const replacer = new RegExp(`^${DEFAULT_COMPONENT_PREFIX}`);
  const registerComponent = Component => {
    Vue.component(Component.name.replace(replacer, componentsPrefix), Component);
  };

  [
    Provider,
    Room,
    Conversation,
    Preview,
    MessagesGroup,
    Message,
    List,
    Audio,
    Set,
    Window,
    ProgressBar,
  ].forEach(registerComponent);
}

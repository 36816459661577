import { route } from '@md/routing';

const FaqDisplay = () => import('@md/faq/components/Display.vue');

export const REPRESENTATIVE_FAQ = 'representative:faq';

export function createRoutes() {
  return [
    route('', FaqDisplay, REPRESENTATIVE_FAQ),
  ];
}

/* eslint-disable */
import { DataFeedInterface } from '@webcase/vue-chat/src/DataFeed/Interface';
import { now } from '@webcase/vue-chat/src/utils/date';

import {
  chat,
} from '@md/chat/api.js';
import instant from './centrifugo';
// const EventEmitter = require('events');
const genId = (counter => () => counter++)(1000000000)

function chatAuthors(chats, id, callback) {
  const chat = chats.find(x => x.id === id);
  Object.values(chat.authors).forEach((author, i) => {
    callback.call(this, author, i);
  });
}

function getTitleByLanguage(titles) {
  const languages = Object.keys(window.languages);
  const language = window.language;
  return titles[language] || titles[languages[0]];

}
function migrateRoomToChat(room) {
  const { id, preview, unread_messages_count, created_at, updated_at, members, title, meta, last_message } = room
  const chat = {}

  chat.createdAt = created_at
  chat.updatedAt = updated_at
  chat.id = id
  chat.unread = unread_messages_count
  chat.title = title
  chat.preview = preview
  chat.members = members
  chat.meta = {}
  chat.meta.hotel = meta.related_hotel
  chat.meta.touroperator = meta.related_touroperator
  chat.meta.related_object = meta.related_object
  if (meta.related_hotel) {
    chat.meta.hotel.title = meta.related_hotel.title
  }
  if (meta.related_touroperator) {
    chat.meta.touroperator.title = meta.related_touroperator.title
  }
  if (meta.related_object) {
    if (meta.related_object.status && meta.related_object.status.title) {
      chat.meta.related_object.status.title = getTitleByLanguage(meta.related_object.status.title)
    }
  }
  if (last_message) {
    chat.latest_message = last_message
    chat.latest_message.authorId = members.find(({ author: { id } }) => id === last_message.author)?.author?.remote_user?.id
    if (!chat.latest_message.authorId)
      chat.latest_message = null
  }

  chat.authors = members.reduce((acc, member, idx) => {
    const { author, author: { remote_user: { avatar, first_name, last_name, id, user_type, role } } } = member

    acc[id] = {
      id,
      userChatId: author.id,
      avatar: {
        source: {
          url: avatar && avatar.avatar || null,
          webp: avatar && avatar.avatar_webp || null,
        }
      },
      firstName: first_name,
      lastName: last_name,
      displayName: `${first_name} ${last_name}`,
      userType: user_type,
      role,
      status: {
        type: author.status.status,
        updatedAt: author.status.updated_at
      },
    }
    return acc
  }, {})

  return chat;
}

function migrateMessage(message) {
  const result = Object.assign({}, message);
  result.chatId = message.room;
  if (message.author) {
    result.author = message.author.remote_user
    result.authorId = message.author?.remote_user?.id;
    result.author.status = message.author.status;
    result.author.avatar = { source: { 
      url: result.author.avatar && result.author.avatar.avatar,
      webp: result.author.avatar && result.author.avatar.avatar_webp,
    } };
    result.author.displayName = `${message.author.remote_user.first_name} ${message.author.remote_user.last_name}`;
    result.author.authorMessageId = message.author.id;
  }
  result.statuses = message.statuses;
  result.updatedAt = message.updated_at;
  result.createdAt = message.created_at;
  result.type = message.type
  if (message.meta.related_object) {
    result.meta.related_object.title = getTitleByLanguage(message.meta.related_object.title)

  }

  return result;
}

function migrateEventData(eventData) {
  eventData.room = eventData.chatId;
  // eventData.text = eventData.body.content
  // eventData.file = eventData.body.source && eventData.body.source.file
  eventData.id = eventData.body.file && eventData.body.file.id
  eventData.meta = {}
  delete eventData.chatId
  return eventData;
}

function migratePagination(info) {
  return {
    endCursor: info.endCursor || info.end_cursor,
    startCursor: info.startCursor || info.start_cursor,
    hasNext: info.hasNext || info.has_next,
    hasPrevious: info.hasPrevious || info.has_previous,
    limit: info.limit,
  }
}

import {
  MESSAGE_NEW,
  MESSAGE_RECEIVED,
  MESSAGE_UPDATED,
  TYPING_STARTED,
  TYPING_STOPPED,
  ROOM_NEW,
  ROOM_UPDATED,
  ROOM_REMOVED,
  AUTHOR_UPDATED,
  GLOBAL_UNREAD,
} from './const.js'

const EVENT_HANDLER = {
  [MESSAGE_NEW]: migrateMessage,
  [ROOM_UPDATED]: migrateRoomToChat,
  [ROOM_NEW]: migrateRoomToChat,
}

export default class ChatFeed extends DataFeedInterface {
  constructor() {
    super();
    // this.server.emitter.on('event', this.handleEvent.bind(this));
    if (window.IS_AUTHENT) {
      (async () => {
        await instant.getToken()
        await instant.connect();
      })();
      instant.onMessage = this.handleEvent.bind(this);
    }
  }
  encoder(end) {
    const date = new Date(end.replace(/ /g, "T")).toISOString()
    const result = `p=${date}`
    return btoa(result)
  }

  handleEvent(event) {
    const { eventClass, data } = event;
    let body = data;
    let type = eventClass;

    if (EVENT_HANDLER[eventClass]) {
      body = EVENT_HANDLER[eventClass](body)
    }
    if (eventClass === MESSAGE_UPDATED) {
      body = body.map(migrateMessage);
    }

    this.emitter.emit(type, { type, body });
  }

  receiveHistory({ endCursor, chatId: id, limit, offset } = {}) {
    const cursor = this.encoder(endCursor)
    return chat.messages.list.execute({ limit, offset, cursor, id }).then(({ data: { items, pagination } }) => {

      return {
        data: items.map(migrateMessage),
        meta: migratePagination(pagination),
      };
    });
  }

  receiveChats({ endCursor, limit, offset, filters } = {}) {
    const cursor = this.encoder(endCursor)
    return chat.rooms.list.execute({ limit, offset, cursor, filters }).then(({ data: { items, pagination } }) => {
      return {
        data: items.map(migrateRoomToChat),
        meta: migratePagination(pagination),
      };
    });
  }

  setConnection(status) {
    this.isOnline = status !== 'online';
    this.emitter.emit(`connection:${status}`, {
      type: `connection:${status}`,
      createdAt: now().toISOString(),
      body: {}
    });
  }

  sendMessage(message) {

    return chat.messages.create.execute({}, migrateEventData(message))
      .catch(() => {
        console.warn('failed');
        this.setConnection('offline');
        message.createdAt = now().toISOString();
        message.updatedAt = message.createdAt;
        message.status = { type: 'failed', updatedAt: message.createdAt };
        this.emitter.emit('chat:message:failed', {
          type: 'chat:message:failed',
          body: message,
        });

      });
  }

  sendEvent(type, body) {
    if (type === 'chat:message:read') {
      chat.messages.read.execute({}, { messages: body.messagesIds });
    }
    // this.server.emitter.emit(type, { type, body });
  }

  // sendFile(file) {
  //   const emitter = new EventEmitter();
  //   let timeout = null;
  //   emitter.on('cancel', x => console.warn(clearTimeout(timeout)));
  //   let url = null;

  //   for (let index = 0; 301 > index; index++) {
  //     (i => setTimeout(
  //       () => emitter.emit('progress', { loaded: i / 3, total: 100 }),
  //       i * 10
  //     ))(index);
  //   }

  //   const reader = new FileReader();
  //   reader.onload = e => {
  //     url = e.target.result;
  //   };
  //   reader.readAsDataURL(file);

  //   timeout = setTimeout(() => emitter.emit('success', {
  //     id: 1,
  //     file: url,
  //     name: file.name,
  //     size: file.size,
  //     source: {
  //       url,
  //     },
  //   }), 3000);

  //   return emitter;
  // }
}

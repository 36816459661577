import { defaultDataTransformer } from '@aspectus/resource-commons';
import { createFileSender, r, createSender } from '@md/resources';
import { prefixAPI, modelPrefix } from '@md/api.js';

const GALLERY_MODEL = '/gallery';
const GALLERY_GENERAL_MODULE = url => modelPrefix(url, `${GALLERY_MODEL}/general`);
const GALLERY_HOTELS_MODULE = url => modelPrefix(url, `${GALLERY_MODEL}/hotels`);

export const gallery = {
  addons: {
    categories: r('/gallery/addons/categories/list/{?s,limit,offset}'),
    hashtags: r('/gallery/addons/hashtags/list/{?filters,s,category,limit,offset}'),
  },
  hotels: {
    create: r(GALLERY_HOTELS_MODULE('/create/'), createSender).config('method', 'POST'),
    list: r(GALLERY_HOTELS_MODULE('/list/{?filters,hotel,category,room_type,tags,limit,offset}')),
    publicList: r(GALLERY_HOTELS_MODULE('/public/list/{?filters,hotel,category,room_type,tags,limit,offset}')),
    filters: {
      list: r(GALLERY_HOTELS_MODULE('/filters/list/')),
      available: r(GALLERY_HOTELS_MODULE('/filters/list/{?filters,hotel,category,room_type,tags,limit,offset}')),
    },
    retrieve: r(GALLERY_HOTELS_MODULE('{/id}/retrieve/')),
    delete: r(GALLERY_HOTELS_MODULE('{/id}/delete/'), createSender).config('method', 'DELETE'),
    update: r(GALLERY_HOTELS_MODULE('{/id}/update/'), createSender).config('method', 'PATCH'),
    updateStatus: r(GALLERY_HOTELS_MODULE('{/id}/update-status/'), createSender).config('method', 'PATCH'),
  },
  general: {
    contentObjectUpdate: r(GALLERY_GENERAL_MODULE('/content-object-update/'), createSender).config('method', 'POST'),
    list: r(GALLERY_GENERAL_MODULE('/list/{?limit,offset}')),
    upload: createFileSender(prefixAPI(GALLERY_GENERAL_MODULE('/upload/')))
      .transform(defaultDataTransformer),
    delete: r(GALLERY_GENERAL_MODULE('{/id}/delete/'), createSender).config('method', 'DELETE'),
  },

};

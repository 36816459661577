const Account = () => import('./Account.vue');
const ArrowLeft = () => import('./ArrowLeft.vue');
const ArrowRight = () => import('./ArrowRight.vue');
const Attachment = () => import('./Attachment.vue');
const LinkVariant = () => import('./LinkVariant.vue');
const ContentSaveOutline = () => import('./ContentSaveOutline.vue');
const StarOutline = () => import('./StarOutline.vue');
const Eye = () => import('./Eye.vue');
const EyeOff = () => import('./EyeOff.vue');
const EmailOutline = () => import('./EmailOutline.vue');
const ExitToApp = () => import('./ExitToApp.vue');
const Check = () => import('./Check.vue');
const CheckBold = () => import('./CheckBold.vue');
const CheckAll = () => import('./CheckAll.vue');
const CheckboxMarkedCircle = () => import('./CheckboxMarkedCircle.vue');
const ChevronDown = () => import('./ChevronDown.vue');
const ChevronLeft = () => import('./ChevronLeft.vue');
const ChevronUp = () => import('./ChevronUp.vue');
const CloudDownload = () => import('./CloudDownload.vue');
const CloudUpload = () => import('./CloudUpload.vue');
const Close = () => import('./Close.vue');
const CloseThick = () => import('./CloseThick.vue');
const CommentProcessingOutline = () => import('./CommentProcessingOutline.vue');
const Download = () => import('./Download.vue');
const DragVariant = () => import('./DragVariant.vue');
const FileOutline = () => import('./FileOutline.vue');
const Menu = () => import('./Menu.vue');
const Minus = () => import('./Minus.vue');
const PaperClip = () => import('./PaperClip.vue');
const Pencil = () => import('./Pencil.vue');
const Plus = () => import('./Plus.vue');
const Redo = () => import('./Redo.vue');
const Send = () => import('./Send.vue');
const SquareEditOutline = () => import('./SquareEditOutline.vue');
const TextShadow = () => import('./TextShort.vue');
const TrashCanOutline = () => import('./TrashCanOutline.vue');
const HelpCircleOutline = () => import('./HelpCircleOutline.vue');
const ContentCopy = () => import('./ContentCopy.vue');
const ReferralOutline = () => import('./ReferralOutline.vue');
const ReferralOutlineThin = () => import('./ReferralOutlineThin.vue');
const ReferralOutlineThick = () => import('./ReferralOutlineThick.vue');
const Refresh = () => import('./Refresh.vue');
const PlusCircleOutline = () => import('./PlusCircleOutline.vue');
const MinusCircleOutline = () => import('./MinusCircleOutline.vue');
const History = () => import('./History.vue');
const VirtualTour = () => import('./VirtualTour.vue');

// Project icons
const ProjectMarker = () => import('./ProjectMarker.vue');
const CoinsStack = () => import('./CoinsStack.vue');

const DEFAULT_COMPONENT_PREFIX = 'Icon';
export default function install(
  Vue,
  { p = DEFAULT_COMPONENT_PREFIX } = {}
) {
  // Project icons
  Vue.component(`${p}ProjectMarker`, ProjectMarker);

  Vue.component(`${p}Account`, Account);
  Vue.component(`${p}ArrowLeft`, ArrowLeft);
  Vue.component(`${p}ArrowRight`, ArrowRight);
  Vue.component(`${p}Attachment`, Attachment);
  Vue.component(`${p}LinkVariant`, LinkVariant);
  Vue.component(`${p}ContentSaveOutline`, ContentSaveOutline);
  Vue.component(`${p}StarOutline`, StarOutline);
  Vue.component(`${p}Eye`, Eye);
  Vue.component(`${p}EyeOff`, EyeOff);
  Vue.component(`${p}EmailOutline`, EmailOutline);
  Vue.component(`${p}ExitToApp`, ExitToApp);
  Vue.component(`${p}Check`, Check);
  Vue.component(`${p}CheckBold`, CheckBold);
  Vue.component(`${p}CheckAll`, CheckAll);
  Vue.component(`${p}CheckboxMarkedCircle`, CheckboxMarkedCircle);
  Vue.component(`${p}ChevronDown`, ChevronDown);
  Vue.component(`${p}ChevronLeft`, ChevronLeft);
  Vue.component(`${p}ChevronUp`, ChevronUp);
  Vue.component(`${p}CoinsStack`, CoinsStack);
  Vue.component(`${p}CloudDownload`, CloudDownload);
  Vue.component(`${p}CloudUpload`, CloudUpload);
  Vue.component(`${p}Close`, Close);
  Vue.component(`${p}CloseThick`, CloseThick);
  Vue.component(`${p}CommentProcessingOutline`, CommentProcessingOutline);
  Vue.component(`${p}Download`, Download);
  Vue.component(`${p}DragVariant`, DragVariant);
  Vue.component(`${p}FileOutline`, FileOutline);
  Vue.component(`${p}Menu`, Menu);
  Vue.component(`${p}Minus`, Minus);
  Vue.component(`${p}PaperClip`, PaperClip);
  Vue.component(`${p}Pencil`, Pencil);
  Vue.component(`${p}Plus`, Plus);
  Vue.component(`${p}Redo`, Redo);
  Vue.component(`${p}Send`, Send);
  Vue.component(`${p}SquareEditOutline`, SquareEditOutline);
  Vue.component(`${p}TextShadow`, TextShadow);
  Vue.component(`${p}TrashCanOutline`, TrashCanOutline);
  Vue.component(`${p}HelpCircleOutline`, HelpCircleOutline);
  Vue.component(`${p}ContentCopy`, ContentCopy);
  Vue.component(`${p}ReferralOutline`, ReferralOutline);
  Vue.component(`${p}ReferralOutlineThin`, ReferralOutlineThin);
  Vue.component(`${p}ReferralOutlineThick`, ReferralOutlineThick);
  Vue.component(`${p}Refresh`, Refresh);
  Vue.component(`${p}PlusCircleOutline`, PlusCircleOutline);
  Vue.component(`${p}MinusCircleOutline`, MinusCircleOutline);
  Vue.component(`${p}History`, History);
  Vue.component(`${p}VirtualTour`, VirtualTour);
}

import ModalListenerOpenDetailView from './ModalListenerOpenDetailView.vue';
import Item from './Item.vue';
import CommentsSection from './CommentsSection.vue';
import BlogLightbox from './BlogLightbox.vue';

export default function install(Vue) {
  Vue.component(ModalListenerOpenDetailView.name, ModalListenerOpenDetailView);
  Vue.component(Item.name, Item);
  Vue.component(CommentsSection.name, CommentsSection);
  Vue.component('blog-lightbox', BlogLightbox);
}

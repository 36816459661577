<template lang="pug">
  include /mixins
  Slick(ref="slick"
    :options="options"
  )
    //- @init='emmiter'
    //- @reInit="handleReInit"
    slot(name='slide')
</template>
<script>
export default {
  name: 'UiSlider',
  props: {
    options: {},
    // reiniter: {},
  },
  watch: {
    // reiniter() {
    //   this.reInit();
    // },
  },
  // mounted() {
  //   if (this.reiniter) {
  //     const time = 1250;
  //     this.reInit();
  //     setTimeout(() => {
  //       this.reInit();
  //     }, time);
  //   }
  // },
  methods: {
    // emmiter(event, slick) {
    //   this.$emit('init', slick);
    // },
    // reInit() {
    //   // Helpful if you have to deal with v-for to update dynamic lists
    //   this.$refs.slick.destroy();

    //   this.$nextTick(() => {
    //     this.$refs.slick.create();
    //   });
    // },
    // handleReInit(event, slick) {
    // },
  },
};
</script>

import { r, createSender } from '@md/resources';

export const reviews = {
  addons: {
    categories: r('/reviews/addons/categories/list/{?filters}'),
  },
  create: r('/reviews/create/', createSender),
  delegatedCreate: r('/reviews/delegate/create/', createSender),
  list: r('/reviews/list/{?filters,order_by,limit,offset}'),
  filters: {
    list: r('/reviews/filters/list/'),
    available: r('/reviews/filters/list/{?filters}'),
  },
  aggregate: r('/reviews/aggregate/{?filters,order_by,limit,offset}'),
  hotelStatistics: r('/reviews/hotel{/hotel}/statistic/'),
  hotelPublic: r('/reviews/hotel{/id}/public/list/{?limit,offset}'),
  touroperatorPublic: r('/reviews/touroperator{/id}/public/list/{?limit,offset}'),
  update: r('/reviews{/id}/update/', createSender).config('method', 'PATCH'),
  retrieve: r('/reviews{/id}/retrieve/'),
};

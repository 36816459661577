import {
  defaultDataTransformer,
  jsonTransformer,
} from '@aspectus/resource-commons';
import { prefixAPI, urlGetter } from '@md/api.js';
import { prefixLanguage } from '@md/urls';
import {
  baseResource,
  createReceiver,
  createSender,
} from '@md/resources';

const r = (tpl, f = createReceiver) => f(prefixAPI(tpl)).transform(defaultDataTransformer);

const VIDEO_ITEMS_PREFIX = prefixLanguage('/video/ajax/filters/');

export const videoListUrlGetter = urlGetter(VIDEO_ITEMS_PREFIX);
export const ajax = baseResource.url(videoListUrlGetter)
  .transform(jsonTransformer)
  .transform(defaultDataTransformer);

export const video = {
  ajax,
  // ajax: createReceiver(prefixLanguage('/video/ajax/filters/{filters*}/{?limit,offset}'))
  //   .transform(defaultDataTransformer),
  catalogFilters: {
    list: r('/video/catalog/filters/list/'),
    available: r('/video/catalog/filters/list/{?filters}'),
  },
  create: r('/video/create/', createSender),
  list: r('/video/list/{?filters,order_by,limit,offset}'),
  filters: {
    list: r('/video/filters/list/'),
    available: r('/video/filters/list/{?filters}'),
  },
  aggregate: r('/video/aggregate/{?filters}'),
  retrieve: r('/video{/id}/sources/retrieve/'),
  delete: r('/video{/id}/delete/', createSender).config('method', 'DELETE'),
  share: r('/video{/id}/share/', createSender),
  view: r('/video{/id}/view/', createSender),
  search: r('/video/search/{?s}'),
};

/* eslint-disable */

import BemComponentMixin from '../../../../utils/bem/BemComponentMixin';
import { DropZone } from '../../dropzone';

/**
 * File upload trigger component.
 *
 * @version 0.1.0
 * @author [Alex Tkachenko](https://github.com/preusx)
 * @example ./Readme.md
 */
export default {
  name: 'FileUploadTrigger',
  mixins: [BemComponentMixin],

  bem: {
    block: 'c-file-upload-trigger'
  },

  data() {
    return {
      isDragging: false
    }
  },

  mounted() {
    this.dropZone = new DropZone();
    this.dropZone.bind([this.$refs.dropzone], [this.$refs.input]);

    this.dropZone.emitter.on('drag-start', this.dragStart);
    this.dropZone.emitter.on('drag-stop', this.dragStop);
    this.dropZone.emitter.on('drop', this.drop);
  },

  destroyed() {
    this.dropZone.unbind();
  },

  methods: {
    /**
     * Opens file upload dialog.
     * @public
     */
    open() {
      this.$refs.input.click();
    },

    dragStart() {
      this.isDragging = true;
    },

    dragStop() {
      this.isDragging = false;
    },

    drop(event) {
      /**
      * Event, that fires on files being added by user.
      *
      * Properties:
      *
      *  - files - Added file objects.
      *
      * @event drop
      * @type {object}
      */
      this.$emit('drop', event);
    }
  }
};

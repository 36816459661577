export function keyedTransformer(
  transformer, from, to = from, transformOnUndefined = false, dflt
) {
  return function fn(source, result) {
    const data = (source && 'undefined' !== typeof source[from] && null !== source[from])
      ? source[from]
      : dflt;

    if (!transformOnUndefined && 'undefined' === typeof data) {
      return result;
    }

    return {
      ...result,
      [to]: transformer(data),
    };
  };
}

export function symmetricalKeyTransformers(
  fromHandler, toHandler, fromKey, toKey = fromKey
) {
  return [
    keyedTransformer(fromHandler, fromKey, toKey),
    keyedTransformer(toHandler, toKey, fromKey),
  ];
}

export function sameTransformer(data) {
  return data;
}

export function composeTransformers(...transformers) {
  const prepared = transformers.reverse();

  return function fn(source) {
    return prepared.reduce((acc, transform) => transform(source, acc), {});
  };
}

export const normalizeSelectValue = value => (
  Array.isArray(value) || 'undefined' === typeof value || null === value
    ? (value || [])
    : [value]
);

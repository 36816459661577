const atob = str => Buffer.from(str, 'base64').toString('binary');
const btoa = str => Buffer.from(str, 'binary').toString('base64');

export function b64EncodeUnicode(str) {
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  return btoa(
    encodeURIComponent(str)
      .replace(/%([0-9A-F]{2})/g,
        (match, p1) => String.fromCharCode(`0x${ p1}`))
  );
}

export function b64DecodeUnicode(str) {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(atob(str).split('').map(c => `%${ (`00${ c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));
}

export function parametersTo({ filters, offset, ...rest }) {
  if (!offset) {
    offset = undefined;
  }

  return { ...rest, offset, filters: b64EncodeUnicode(JSON.stringify(filters || {})) };
}

export function parametersFrom({ filters, ...rest }) {
  let data = {};

  try {
    data = JSON.parse(b64DecodeUnicode(filters));
  } catch (e) {
    console.warn(e);
  }
  // parse route path for initial filters from route params

  const DROP_KEYS = ['limit', 'offset', '__internal__', 'order_by'];

  const initial = Object.keys(rest).filter(el => !DROP_KEYS.includes(el));
  initial.forEach(key => { data[key] = { id: rest[key] }; });

  return { ...rest, filters: data };
}

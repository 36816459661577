import { route } from '@md/routing.js';
import { receiveRoute } from '@sections/cabinet/components/views/utils.js';
import { rooms } from '@md/hotels/api.js';
import { HOTELIER_HOTEL_EDIT } from '@sections/cabinet/modules/hotelier/routes.js';

const Edit = () => import('./views/Edit.vue');
const Create = () => import('./views/Create.vue');
const List = () => import('./views/List.vue');

const ROOMS_PREFIX = 'hotelier:hotels:profile:rooms:';
const p = name => ROOMS_PREFIX + name;
export const HOTELIER_ROOMS_EDIT = p('edit');
export const HOTELIER_ROOMS_CREATE = p('create');
export const HOTELIER_ROOMS_LIST = p('list');

export function createRoutes() {
  return [
    route('list/', List, HOTELIER_ROOMS_LIST, {
      meta: {
        parentRoute: HOTELIER_HOTEL_EDIT,
      },
    }),
    route('create/', Create, HOTELIER_ROOMS_CREATE, {
      meta: {
        parentRoute: HOTELIER_HOTEL_EDIT,
      },
    }),
    receiveRoute(rooms.retrieve, [
      route('edit/', Edit, HOTELIER_ROOMS_EDIT, {
        meta: {
          parentRoute: HOTELIER_HOTEL_EDIT,
        },
      }),
    ]),
    { path: '', redirect: { name: HOTELIER_ROOMS_LIST } },

  ];
}

<template lang="pug">
include /mixins.pug
+b.preloader--withOpacity.--size_3xl(v-show="show")
  +b.loader
    //- +e.img.IMG(src='/static/img/favicon.png')
</template>

<script>
export default {
  name: 'ui-loading',
  data() {
    return {
      show: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.show = true;
    }, 200);
  },
};
</script>

import InfiniteTemplateResultsCollector from './InfiniteTemplateResultsCollector';
import InfiniteResultsCollector from './InfiniteResultsCollector';
import PaginatedResourceController from './PaginatedResourceController.vue';
import ResourceLoaderController from './ResourceLoaderController';
// import DelayedExecutorController from './DelayedExecutorController';
import PaginationController from './PaginationController';
import UrlParametersController from './UrlParametersController';

function install(Vue) {
  Vue.component(InfiniteTemplateResultsCollector.name, InfiniteTemplateResultsCollector);
  Vue.component(InfiniteResultsCollector.name, InfiniteResultsCollector);
  Vue.component(PaginatedResourceController.name, PaginatedResourceController);
  Vue.component(ResourceLoaderController.name, ResourceLoaderController);
  // Vue.component(DelayedExecutorController.name, DelayedExecutorController);
  Vue.component(PaginationController.name, PaginationController);
  Vue.component(UrlParametersController.name, UrlParametersController);
}

export default {
  install,
};

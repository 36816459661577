<template lang="pug">
generator(
  :filters="filters",
  :available="available",
  :value="value",
  v-slot="{ nodes, renderFilter }",
  v-on="$listeners"
)
  row(:appearance="['spaced']", space="sm")
    slot(name="prepend", cols="12")
    template(v-for="filter in filters")
      cell.cabinet-fillter-width(
        cols="12 6-sm spread-sm auto-md",
        v-if="nodes[filter.id]",
        :class="{'ml-20': filter.id === 'search' && !isMobile}"
        :key="filter.id"
      )
        render-slot(:slot="nodes[filter.id]", :props="parameters")
    slot(
      name="append",
      cols="12",
      :render-filter="renderFilter",
      :available="available",
      :value="value"
      :filters="filters"
    )
</template>
<script>
import { RenderSlot } from '@aspectus/vue-render-slot';
import helperMixin from '@md/filter/mixins/HelperMixin';
import Generator from './Generator';

export default {
  components: {
    Generator,
    RenderSlot,
  },
  mixins: [helperMixin],
  props: ['disabled', 'value', 'filters', 'available'],

  computed: {
    parameters() {
      return {
        disabled: this.disabled,
        values: this.value,
        size: 'md',
      };
    },
  },
};
</script>

import ToggleFavourite from './ToggleFavourite';
import PublicRoomsList from './rooms/PublicRoomsList.vue';
import RoomDailyPrice from './rooms/RoomDailyPrice.vue';
import ToggleButton from './services/ToggleButton.vue';
import AmenitiesServices from './services/AmenitiesServices.vue';
// import PublicServicesListWrapper from './services/PublicServicesListWrapper.vue';
import HotelGalleryLightbox from './HotelGalleryLightbox.vue';

const AgentHotels = () => import('./AgentHotels.vue');

export function install(Vue) {
  Vue.component('agent-hotels', AgentHotels);
  Vue.component(ToggleFavourite.name, ToggleFavourite);
  Vue.component('public-rooms-list', PublicRoomsList);
  // Vue.component(PublicServicesListWrapper.name, PublicServicesListWrapper);
  Vue.component('HotelGalleryLightbox', HotelGalleryLightbox);
  Vue.component('room-daily-price', RoomDailyPrice);
  Vue.component('toggle-button', ToggleButton);
  Vue.component('amenities-services', AmenitiesServices);
}

export default { install };

<template lang="pug">
agent-public-nights(
  v-bind="$attrs"
)
</template>
<script>
const AgentPublicNights = () => import('./AgentPublicNights.vue');
export default {
  name: 'agent-public-nights-wrapper',
  components: { AgentPublicNights },
};
</script>

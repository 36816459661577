import VueRouter from 'vue-router';
import VueI18n from 'vue-i18n';
import VModal from 'vue-js-modal';
import VueSocialSharing from 'vue-social-sharing';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { Tabs, Tab } from 'vue-slim-tabs';
import * as VueGoogleMaps from 'vue2-google-maps';
import { ObserveVisibility } from 'vue-observe-visibility';
import VueToast from 'vue-toast-notification';
import VueDatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import VueMultiselect from 'vue-multiselect';
import VueMask from 'vue-the-mask';
import VueParallaxJs from 'vue-parallax-js';
import { KinesisContainer, KinesisElement } from 'vue-kinesis';

import VueTag from '@aspectus/vue-tag';
import VueGrid from '@aspectus/vue-grid';
import VueBem from '@aspectus/vue-bem';
import VueDermis from '@aspectus/vue-dermis';
import VueDrawer from '@aspectus/vue-drawer';
import VueTable from '@aspectus/vue-table';
import VueOrderingController from '@aspectus/vue-ordering-controller';
import VueSelectionController from '@aspectus/vue-selection-controller';
import VueAllSelectionController from '@aspectus/vue-all-selection-controller';
import VuePermissions from '@aspectus/vue-permissions';
import VueDropzoneUploader from '@aspectus/vue-dropzone-uploader';
import VueRouterPermissions from '@aspectus/vue-router-permissions';
import VueRouterParametersController from '@aspectus/vue-router-parameters-controller';
import VTooltip from 'v-tooltip';

import Resources from '@md/resources';
import UI from '@md/ui';
import Users from '@md/users';

import Appeals from '@md/appeals/components';
import Comments from '@md/comments/components';
import Likes from '@md/likes/components';
import Hotels from '@md/hotels/components';
import Auth from '@md/auth/components';
import Promotions from '@md/promotions/components';
import Filter from '@md/filter';
import Video from '@md/video/components';
import Nights from '@md/nights/components';
import Reviews from '@md/reviews/components';
import Blog from '@md/blog/components';
import Presentation from '@md/presentations/components';
import filtersList from '@md/filtersList';
import Search from '@md/Search/components';

import CabinetHeader from '@sections/cabinet/components/sections/Header';
import Sidebar from '@sections/cabinet/components/Sidebar';

import Filters from '@md/filters.js';

import ChatProvider from '@md/chat/ChatProvider';
import ChatComponents from '@webcase/vue-chat/src';
import FileUploadTrigger from '@webcase/vue-chat/src/components/FileUpload/components/Trigger/Trigger.vue';
import StartConversation from '@md/chat/StartConversation.vue';
import PageHins from '@md/page_hints/components/index.js';
import VueLightbox from 'vue-lightbox-lite';
import FeaturesList from '@md/features/FeaturesList';
import Api from './api.js';
import 'vue-lightbox-lite/dist/index.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'quill/dist/quill.core.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'quill/dist/quill.snow.css';

// vue2-datepicker/locale also used in LoyaltyCalendar/Calendar.vue
require(`vue2-datepicker/locale/${window.language}`);

// add vue-parallax-js to vue
export function install(Vue) {
  Vue.directive('observe-visibility', ObserveVisibility);

  Vue.use(VueRouter);
  Vue.use(VueI18n);
  Vue.use(VueParallaxJs);

  Vue.use(VModal, {
    dialog: true,
    dynamicDefaults: {
      height: 'auto',
      focusTrap: true,
      adaptive: true,
      scrollable: true,
    },

  });
  Vue.use(VueSocialSharing);
  Vue.use(VueMask);
  Vue.component('vue-multiselect', VueMultiselect);
  Vue.component('vue-date-picker', VueDatePicker);
  Vue.component('validation-provider', ValidationProvider);
  Vue.component('validation-observer', ValidationObserver);
  Vue.component('Tabs', Tabs);
  Vue.component('Tab', Tab);
  Vue.use(VueToast);
  Vue.use(VueGoogleMaps, {
    load: {
      key: window.CONFIG.GOOGLE_API_KEY,
      libraries: 'places',
    },
  });

  Vue.component('cabinet-header', CabinetHeader);
  Vue.use(Sidebar);
  Vue.use(VueTag);

  Vue.use(VueLightbox);
  Vue.use(VueBem);
  Vue.use(VueGrid);
  Vue.use(VueDermis);
  Vue.use(VueTable);
  Vue.use(VueSelectionController);
  Vue.use(VueAllSelectionController);
  Vue.use(VueOrderingController);
  Vue.use(VueDrawer);
  Vue.use(VueDropzoneUploader);
  Vue.use(VuePermissions);
  Vue.use(VueRouterPermissions);
  Vue.use(VueRouterParametersController);
  Vue.use(VTooltip);

  Vue.use(Resources);
  Vue.use(UI);
  Vue.use(Auth);
  Vue.use(Appeals);
  Vue.use(Comments);
  Vue.use(Likes);
  Vue.use(Hotels);
  Vue.use(Filter);
  Vue.use(Video);
  Vue.use(Nights);
  Vue.use(Reviews);
  Vue.use(Search);
  Vue.use(Blog);
  Vue.use(filtersList);
  Vue.use(Users);
  Vue.use(Promotions);
  Vue.use(Presentation);
  Vue.use(PageHins);

  Vue.use(Filters);
  Vue.use(Api);
  Vue.component('FileUploadTrigger', FileUploadTrigger);
  Vue.use(ChatComponents);

  Vue.component('chat-provider', ChatProvider);
  Vue.component('feature-list', FeaturesList);
  Vue.component(StartConversation.name, StartConversation);
  Vue.component('kinesis-container', KinesisContainer);
  Vue.component('kinesis-element', KinesisElement);

  Vue.prototype.$confirm = function confirm(value, reaction, title, cancelHandler = () => {}) {
    this.$modal.show('dialog', {
      title,
      // text,
      buttons: [
        { title: this._('No'), default: true, handler: () => { this.$modal.hide('dialog'); cancelHandler(value); } },
        { title: this._('Yes'), handler: () => { this.$modal.hide('dialog'); reaction(value); } },
      ],
    });
  };
}

export default { install };

<template>
  <filter-controller
    :receive="allProps.receive"
    :receiveAvailable="availableProps.receive || null"
    :all="allProps.result && allProps.result.items"
    :available="availableProps.result && availableProps.result.items || null"
    :permanent="permanent"
    v-bind="$attrs"
    :hide-search-button="hideSearchButton"
    :hide-clear-button="hideClearButton"
    :hide-filters-controls="hideFiltersControls"

    :loading="allProps.loading || (availableProps.loading || false)"
    :value="value"
    @input="handleFilter"
  >
    <template #append="scope">
      <slot name="append" v-bind="scope"/>
    </template>
  </filter-controller>
</template>

<script>

import FilterController from './Controller.vue';

export default {
  props: ['value', 'allProps', 'availableProps', 'permanent', 'hideSearchButton', 'hideFiltersControls', 'hideClearButton'],

  components: {
    FilterController,
  },

  methods: {
    handleFilter(parameters) {
      this.$emit('input', parameters);
    },
  },
};

</script>
